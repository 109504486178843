import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

// import { DeleteDialogComponent } from "./delete-dialog/dialog.component";
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { listChanges } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {

  role : any;
  permissions : Array<any>;


  constructor(
    public dialog       : MatDialog,
    private rest        : RestService,
    private route       : ActivatedRoute,
    private formBuilder : FormBuilder,
    private alert       : UialertsService,
    private router      : Router
    ) { }

  roleForm   : FormGroup;



  loading : boolean = true;

  async ngOnInit() {
    this.loadPermissions();
    this.createroleForm();
    this.createBreadCrumb();

    this.loading = false;
  }

  private async loadPermissions() { 
    try {
      this.permissions = await this.rest.get(`${environment.API.url}/Permission`);     
    } catch (error) { 
      console.error(error);
    }
  }

  private createroleForm() : void {
    this.roleForm = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      permissions: new FormControl("", Validators.required),
    });
  }

  public async createItem() { 

    try {
      this.loading = true;
      if(this.roleForm.valid){
      await this.rest.post(`${environment.API.url}/Role/Create`,{
        "name"        : this.roleForm.get("name").value,
        "permissionIds"   : this.roleForm.get("permissions").value,
      })

      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: "Role created", mode: "success" });
      
      this.router.navigate(['manage/roles']);
      }
      this.loading=false;

    } catch (error) {
      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, { 
       classes: ['routable-crumb'],
        title: 'Roles',
        link:['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
    ],
      heading: "CREATE ROLE"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
