import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

@Component({
  selector: 'app-reports-approvals',
  templateUrl: './reports-approvals.component.html',
  styleUrls: ['./reports-approvals.component.scss']
})
export class ReportsApprovalsComponent implements OnInit {

  constructor() { }

  inst = [
    {
      level    : 1,
      name     : "Level 1",
      modified : "2010-06-15T13:45:30"
    },
    {
      level    : 2,
      name     : "Level 2",
      modified : "2010-06-15T13:45:30"
    },
    {
      level    : 3,
      name     : "Level 3",
      modified : "2015-06-15T13:45:30"
    }
  ]

  reports = [
    {
      name : "Course Training Records",
      id   : 1,
      icon : "snippet_folder",
    },
    {
      name : "Learners Training Records",
      id   : 2,
      icon : "school"
    },
    {
      name : "Bookings",
      id   : 3,
      icon : "date_range"
    },
    {
      name : "Certificates",
      id   : 4,
      icon : "verified"
    },
  ]


  ngOnInit(): void {
    this.createBreadCrumb();
  }
  

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['active-crumb'],
        title: 'Maintain'
      }
    ],
      heading: `MAINTAIN`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
