import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumb } from '../../../library/interfaces/components/bread-crumb/bread-crumb';

@Component({
  selector: 'bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  @Input() breadCrumb: BreadCrumb;
  constructor() { }

  ngOnInit() {
  }

}
