import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

import { USERS } from "src/test/static-data/users";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  displayedColumns: string[] = ['uid', 'name', 'email', 'modified'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource;

  constructor(private router : Router) { }

  dashItems  : Array<any> =  [
    {
      name : "Requests",
      icon : "dashboard",
      link : "admin/dashboard",
      count: 450
    },
    {
      name : "Courses",
      icon : "bookings",
      link : "admin/bookings",
      count: 650
    },
    {
      name : "Facilitators",
      icon : "courses",
      link : "admin/courses",
      count: 11
    },
    {
      name : "Approvals",
      icon : "checklist",
      link : "admin/courses",
      count: 0
    }
  ]


  ngOnInit(): void {
    this.createBreadCrumb();
    this.createUpdateTable(USERS);
  }

  public openUser(user : any) {
    console.log(user);
    this.router.navigate(['manage/users/edit/14'])
  }

  private createUpdateTable(source : Array<any>) : void {

    const mappedSource = source.map((element) =>{
      return {
        uid  : element.uid,
        name       : element.name,
        email : element.email,
        modified  : element.modified,
      }
    })

    this.dataSource = new MatTableDataSource(mappedSource);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [

    ],
      heading: `DASHBOARD`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
