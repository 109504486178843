export const GLOBAL_CONSTS = {

    Status : [
        {
            name : "Pending",
            id   : 1
        },
        {
            name : "Active",
            id   : 2
        },
        {
            name : "Deleted",
            id   : 3
        },
        {
            name : "Complete",
            id   : 4
        }
    ],
    Levels : [
        1,
        2,
        3
    ],

    races : [
        {
            name : "Black",
            id : 1
        },
        {
            name : "White",
            id : 2
        },
        {
            name : "Coloured",
            id : 3
        },
        {
            name : "Indian",
            id : 4
        }
    ],
    verified : [
        true,
        false
    ],
    CompanyGroup : [   
        {
            name : "MTO Group",
            id : 1
        }
    ],
    Company : [
        {
            name : " MTO",
            id : 1
        }
    ],
    Disabilities : 	[
        {
            name : "None",
            id : 0
        },
        {
            name : "Impaired Vision",
            id : 1
        },
        {
            name : "Hip Dislocation",
            id : 2
        },
        {
            name : "Heart Disease",
            id : 3
        },
        {
            name : "Impaired Gearing",
            id : 4
        },
        {
            name : "Epilepsy",
            id : 5
        },
        {
            name : "MentallyChallenged",
            id : 6
        },
        {
            name : "Diabetes",
            id : 7
        }
    ],

    CourseType : [
        {
            name    : "Advanced",
            id      : 1
        },
        {
            name    : "Assessment",
            id      : 2
        },        {
            name    : "Certificate",
            id      : 3
        },        {
            name    : "Competency",
            id      : 4
        },        {
            name    : "Degree",
            id      : 5
        },        {
            name    : "Diploma",
            id      : 6
        },        {
            name    : "Drivers Licence",
            id      : 7
        },        {
            name    : "Entry",
            id      : 8
        },        {
            name    : "Formal Education",
            id      : 9
        },        {
            name    : "Intermediate",
            id      : 10
        },        {
            name    : "Learnership",
            id      : 11
        },        {
            name    : "On The JobTraining",
            id      : 12
        },        {
            name    : "Post Degree",
            id      : 13
        },        {
            name    : "Post Diploma",
            id      : 14
        },        {
            name    : "Post Trade",
            id      : 15
        },        {
            name    : "Short Course",
            id      : 16
        },       
        {
            name    : "Statutory Prescribed Certification",
            id      : 17
        },{
            name    : "Trade",
            id      : 18
        }
    ],
    CourseReferenceNo : [
        {
            name    : "A",
            id      : 1
        },
        {
            name    : "B",
            id      : 2
        },
        {
            name    : "C",
            id      : 3
        },
        {
            name    : "D",
            id      : 4
        },
        {
            name    : "E",
            id      : 5
        }
    ],
	 CoursePassSymbol : [
        {
             name : "A",
             id   : 1
        },
        {
            name : "P",
            id   : 2
        },
        {
            name : "X",
            id   : 3
        }
     ],
     CategoryType :[
        {
            name : "Agri",
            id   : 1
        },
        {
            name : "Driver",
            id   : 2
        },
        {
            name : "Environmental",
            id   : 3
        },
        {
            name : "Fire",
            id   : 4
        },
        {
            name : "Forestry",
            id   : 5
        },
        {
            name : "IT",
            id   : 6
        },
        {
            name : "Safety",
            id   : 7
        },
        {
            name : "Sawmill",
            id   : 8
        },
        {
            name : "SoftSkills",
            id   : 9
        },
        {
            name : "Unknown",
            id   : 10
        },

     ],
     CategoryModeOfTraining : [
        {
            name : "Consultants",
            id   : 1
        },
        {
            name : "Gandk Mouldings",
            id   : 2
        },
        {
            name : "Other",
            id   : 3
        },
        {
            name : "Coaching",
            id   : 4
        },
        {
            name : "Facilitator LeadExternal",
            id   : 5
        },
        {
            name : "Facilitator LeadInternal",
            id   : 6
        },
        {
            name : "Mentorship",
            id   : 7
        },
        {
            name : "OnTheJob",
            id   : 8
        },
        {
            name : "Reading",
            id   : 9
        },
        {
            name : "Self Study",
            id   : 10
        }
    ],
    Locations : [
        {
            name : "MTO Cape",
            id   : 1
        },
        {
            name : "MTO Lowveld",
            id   : 2
        },
        {
            name : "Eastern Cape",
            id   : 3
        },
        {
            name : "Free State",
            id   : 4
        },
        {
            name : "Gauteng",
            id   : 5
        },
        {
            name : "Limpop",
            id   : 6
        },
        {
            name : "Mpumalanga",
            id   : 7
        },
        {
            name : "North West",
            id   : 8
        },
        {
            name : "Northern Cape",
            id   : 9
        },
        {
            name : "Kwazulu Natal",
            id   : 10
        }
    ]
}