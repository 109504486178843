import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

// import { DeleteDialogComponent } from "./delete-dialog/dialog.component";
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { listChanges } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';

import * as moment from 'moment';
import { debounce } from 'rxjs/operators';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';

@Component({
  selector: 'app-update-session',
  templateUrl: './update-session.component.html',
  styleUrls: ['./update-session.component.scss']
})
export class UpdateSessionComponent implements OnInit {

  loading: boolean = false;
  allCourseResourceAllocation;

  //Session
  sessionId: any;

  //Forms
  sessionForm: FormGroup;

  //Data
  facilitators;
  courses;
  courseResource: any;

  searchText = new FormControl();
  searchFacilitator = new FormControl();
  public facilitatorLoading = false;


  //BreadCrumb
  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);

  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alert: UialertsService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.params.id;


    this.loading = true;

    await this.loadCourseResource();
    this.loadAllCourses();
    //this.loadAllResourceAllocations();
    this.createBreadCrumb();
    this.listenForSearchChange();

    this.createForm();

    this.loading = false;
  }


  public async updateCourseResource(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let courseResource = await this.rest.post(`${environment.API.url}/CourseResource/Update`, {
          "id": this.courseResource.id,
          "startDate": moment(this.sessionForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "endDate": moment(this.sessionForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "costRecoveryNumber": this.sessionForm.get("costRecoveryNumber").value,
          "maxCapacity": this.courseResource.maxCapacity,
          "status": this.courseResource.status,
          "courseId": this.courseResource.courseId,
          "duration": this.sessionForm.get("sessionDuration").value,
        });

        resolve(courseResource);


      } catch (error) {
        console.log(error);
        this.loading = false;
        this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
        console.log(error);
        reject(error);
      }
    });
  }


  private async loadCourseResource(): Promise<void> {
    try {
      this.courseResource = await this.rest.get(`${environment.API.url}/courseResource/GetBySessionId?sessionId=${this.sessionId}`);

      Promise.resolve()
    } catch (error) {
      console.error(error);
    }
  }


  private listenForSearchChange(): void {
    this.searchText.valueChanges.pipe(
      debounce(() => interval(200)),
    ).subscribe(async searchText => {
      if (searchText != '') {
        this.courses = (await this.rest.get(`${environment.API.url}/Course`, { searchTerm: searchText, pageSize: 5 } as SearchFilter)).data;
      }
    });

    this.searchFacilitator.valueChanges.pipe(
      debounce(() => interval(200)),
    ).subscribe(async searchText => {
      if (searchText != '') {
        this.facilitatorLoading = true;
        this.facilitators = [];
        this.facilitators = (await this.rest.get(`${environment.API.url}/facilitator`, { searchTerm: searchText, pageSize: 5 } as SearchFilter)
        .finally(() => {
          this.facilitatorLoading = false;
        })).data;
      }
    });
  }

  public selectFacilitator(event: any) {
    this.sessionForm.controls['facilitatorId'].setValue((event.option.value).id);
  }

  public displayFnFacilitator(item: any): string {
    if (item) {
      return `${item.title ?? ""} ${item.initials ?? ""} ${item.lastName ?? ""}`;
    }
  }

  public facilitatorBlur(){
    if(!this.searchFacilitator.value?.id){
        this.searchFacilitator.setValue(this.courseResource.courseResourceSessions[0].facilitator)
    }
  }


  private async loadAllCourses() {
    try {
      this.courses = await this.rest.get(`${environment.API.url}/Course`);
      Promise.resolve();
    } catch (error) {
      console.error(error);
    }
  }

  public async updateSession() {

    try {
      this.loading = true;
      if (this.sessionForm.valid) {

        await this.updateCourseResource();

        await this.rest.post(`${environment.API.url}/CourseResourceSession/Update`, {
          "id": this.sessionId,
          "startDate": moment(this.sessionForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "endDate": moment(this.sessionForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "internal": this.sessionForm.get("internal").value,
          "courseResourceId": this.sessionForm.get('courseResourceId').value,
          "facilitatorId": this.sessionForm.get("facilitatorId").value,
          "sessionNumber": this.sessionForm.get("sessionNumber").value,
        })

        this.loading = false;
        this.alert.openSnackBar({ duration: 5, message: "Session Updated Succesfully", mode: "success" });
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  // private async loadAllResourceAllocations() {
  //   try {
  //     this.allCourseResourceAllocation = await this.rest.get(`${environment.API.url}/CourseResource`);
  //     console.log(this.allCourseResourceAllocation);

  //     this.allCourseResourceAllocation = this.allCourseResourceAllocation.map(resource => {
  //       resource['title'] = resource.courseName
  //       return resource;
  //     });

  //     Promise.resolve();

  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  private createForm(): void {
    let session = this.courseResource.courseResourceSessions.find(e => e.id == this.sessionId);
    if (session) {
      this.sessionForm = this.formBuilder.group({
        startDate: new FormControl(session.startDate, [Validators.required]),
        endDate: new FormControl(session.endDate, Validators.required),
        internal: new FormControl(session.internal == 'true' ? true : false, Validators.required),
        facilitatorId: new FormControl(parseInt(session.facilitator.id), Validators.required),
        courseResourceId: new FormControl(parseInt(session.courseResourceId), Validators.required),
        costRecoveryNumber: new FormControl(this.courseResource.costRecoveryNumber, Validators.required),
        sessionDuration: new FormControl({value: moment(session.endDate).diff(moment(session.startDate), 'days') + 1, disabled: true}),
        sessionNumber: new FormControl(session.sessionNumber),
      });

      this.searchFacilitator.setValue(session.facilitator);

      this.sessionForm.controls.startDate.valueChanges.subscribe(data => {
        let temp = moment(this.sessionForm.controls.endDate.value).diff(moment(data), 'days');
        if(temp < 0 || isNaN(temp)){
          this.sessionForm.controls.sessionDuration.setValue(0);
        }else{
          this.sessionForm.controls.sessionDuration.setValue(temp + 1);
        }
      });
  
      this.sessionForm.controls.endDate.valueChanges.subscribe(data => {
        let temp = moment(data).diff(moment(this.sessionForm.controls.startDate.value), 'days');
        if(temp < 0 || isNaN(temp)){
          this.sessionForm.controls.sessionDuration.setValue(0);
        }else{
          this.sessionForm.controls.sessionDuration.setValue(temp + 1);
        }
      });

    }
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['routable-crumb'],
        title: 'Sessions',
        link: ['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
      ],
      heading: `SESSION - ${this.courseResource?.name}`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
