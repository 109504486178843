import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

// import { DeleteDialogComponent } from "./delete-dialog/dialog.component";
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';


@Component({
  selector: 'app-create-facilitator',
  templateUrl: './create-facilitator.component.html',
  styleUrls: ['./create-facilitator.component.scss']
})
export class CreateFacilitatorComponent implements OnInit {
  facilitator : any;
  roles : Array<any>;
  mainForm   : FormGroup;
  loading : boolean = true;

  constructor(
    public dialog       : MatDialog,
    private rest        : RestService,
    private route       : ActivatedRoute,
    private router      : Router,
    private formBuilder : FormBuilder,
    private alert       : UialertsService
  ) {  }

  async ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');

    this.createUserForm();
    this.createBreadCrumb();

    this.loading = false;
  }

  private createUserForm() : void {
    this.mainForm = this.formBuilder.group({
      title: new FormControl(""),
      firstName: new FormControl("",Validators.required),
      employeeCode: new FormControl(""),
      code: new FormControl(""),
      lastName: new FormControl("",Validators.required),
      external: new FormControl("", Validators.required)
    });
  }

  public async createItem() { 

    try {
      this.loading = true;
      if(this.mainForm.valid){
      await this.rest.post(`${environment.API.url}/Facilitator/Create`,{
        "title"       : this.mainForm.get("title").value,
        "firstName"   : this.mainForm.get("firstName").value,
        "lastName"    : this.mainForm.get("lastName").value,
        "employeeCode": this.mainForm.get("employeeCode").value,
        "code"     : this.mainForm.get("code").value,
        "external"     : this.mainForm.get("external").value,
      })

      this.alert.openSnackBar({ duration: 5, message: "Facilitator created", mode: "success" });
      this.router.navigate(['people/facilitators'])
    }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  public deleteUser() : void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
    });
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'People'
      }, {
        classes: ['routable-crumb'],
        title: 'Facilitators',
        link:['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
    ],
      heading: "CREATE - FACILITATOR"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
