import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';

import * as moment from 'moment';
import { debounce, debounceTime } from 'rxjs/operators';
import { PagerService } from 'src/app/library/services/paginator.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss']
})
export class CreateBookingComponent implements OnInit {


  locations: Array<any> = GLOBAL_CONSTS.Locations;

  student: any;
  roles: Array<any>;
  mainForm: FormGroup;
  loading: boolean = true;
  submitted: boolean = false;

  races: any = GLOBAL_CONSTS.races;
  companyGroups: any = GLOBAL_CONSTS.CompanyGroup;
  companies: any = GLOBAL_CONSTS.Company;
  disabilities: any = GLOBAL_CONSTS.Disabilities;

  learners: Array<any>;
  allCourseResourceAllocation: any;
  courseResources: any;

  resourceAllocations: Array<any> = [];
  selectedResourceCourse: any;
  filteredResources: Array<any>;
  courses: Array<any>;

  selectedLearners: Array<any> = [];
  selectedLearnerOption: any;

  filteredLeaners: Array<any>;

  public loadingCourses: boolean = false;
  public loadingLearners: boolean = false;
  searchText = new FormControl();
  courseSearchText = new FormControl();
  searchTextLearner = new FormControl();

  createResource: boolean = false;
  resourceSearch: any;
  private _learnerAllocations = new BehaviorSubject(null);
  private _learnerAllocationsLoading = new BehaviorSubject<boolean>(true);

  courseDates = [];
  course;
  selectedDate;
  public display: boolean = false;
  dateNumber: boolean = false;
  courseWithSession = [];
  dateSet = false;
  selectedSessions = [];
  selectedSessionShow = false;
  selectedSession;
  costrecoveryNumber;
  facilitatorName;
  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private restService: RestService,
    private pagerService: PagerService,
    private alert: UialertsService
  ) { }

  async ngOnInit() {
    await this.listenForSearchChange();
    this.createUserForm();
    this.createBreadCrumb();
    this.loading = false;
    this._learnerAllocationsLoading.next(false);
  }

  //datepicker 
  myFilter = (d: Date | null): boolean => {
    let enableFlag = false;
    if (this.courseDates.some(e => moment(d).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD'))) {
      console.log(moment(d).format('yyyy-mm-dd'));
      enableFlag = true;
      return true;
    }
    return enableFlag;
  };

  public addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    let dateMatchArray = [];
    this.selectedDate = moment(event.value).format('YYYY-MM-DD');
    this.courseWithSession = [];
    this.selectedSessions = [];
    this.selectedSessionShow = false;
    this.selectedSession = null;

    this.dateNumber = true;
    console.log(this.resourceAllocations);
    this.resourceAllocations.forEach(course => {
      if (moment(course.startDate).format('YYYY-MM-DD') == this.selectedDate) {
        dateMatchArray.push(course);
      }
    });
    this.courseWithSession = dateMatchArray;
    dateMatchArray.forEach(dateCourse => {
      dateCourse.courseResourceSessions.forEach(element => {
        this.selectedSessions.push(element);
      });
      this.dateSet = true;
    });

  }
  //End datepicker

  //Session Select
  public async selectedSessionMethod(session) {
    this.facilitatorName = session.facilitator;
    this.mainForm.controls["course"].setValue(session.courseResourceId);
    this.selectedSessionShow = true;
    this.selectedSession = session;
    this.courseWithSession.find(course => course.id == session.courseId)
    console.log("costrecoveryNumber");
    this.costrecoveryNumber = this.courseWithSession.find(course => course.id == session.courseResourceId)["costRecoveryNumber"];
    console.log(this.costrecoveryNumber);
  }

  public removeLearner(learner): void {
    const removeIndex = this.selectedLearners.findIndex(x => x.id == learner.id);
    this.selectedLearners.splice(removeIndex, 1);
    this._learnerAllocations.next(this.selectedLearners);
  }

  public addLeaners(learner: any): void {
    this._learnerAllocationsLoading.next(true);
    this.searchTextLearner.setValue(null);
    if (!this.selectedLearners.find(selected => selected.id == learner.option.value.id)) {
      this.selectedLearners.push(learner.option.value);
      this.mainForm.get("learners").setValue(this.selectedLearners);
      let he = this.mainForm.get("learners").value;
      this._learnerAllocations.next(this.selectedLearners);
      this._learnerAllocationsLoading.next(false);
    }
  }

  public filterLearners(event: string): void {
    if (event && typeof event !== 'object') {
      this.filteredLeaners = this.learners.filter(x => (((x.lastName + x.firstName + x.identificationNumber) as string).toUpperCase()).indexOf(event.toUpperCase()) >= 0);
    } else {
      this.filteredLeaners = this.learners;
    }
  }

  public displayFn(item: any): string {
    if (item) {
      return item.name;
    }
  }

  public async setCourse($event) {
    this.selectedSessions = [];
    this.courseDates = null
    this.mainForm.controls["course"].setValue($event.option.value);
    let usedDate = [];
    let courseResources = await this.restService.get(`${environment.API.url}/CourseResource/GetByCourseId?courseId=${$event.option.value.id}`);
    courseResources.forEach(e => {
      usedDate.push(e.startDate);
    });
    console.log(courseResources);
    this.resourceAllocations = courseResources;
    this.courseDates = usedDate;
    console.log(this.courseDates);
    this.display = true;
  }

  clearData() {
    this.mainForm.controls["course"].setValue(null);
    this.display = false;
    this.dateSet = false;
    this.selectedSessionShow = false;
  }

  public setBookingName(bookingname) {
    this.mainForm.controls["bookingName"].setValue(bookingname);
  }

  public addResourceAllocation(resource): void {
    this.resourceAllocations = [resource.option.value];
  }

  public removeCourseResource(resource): void {
    const removeIndex = this.resourceAllocations.findIndex(x => x.id == resource.id);
    this.resourceAllocations.splice(removeIndex, 1);
  }

  private createUserForm(): void {
    this.mainForm = this.formBuilder.group({
      requestor: new FormControl(""),
      requestorEmail: new FormControl(""),
      contactName: new FormControl(""),
      contactNumber: new FormControl(""),
      contactEmail: new FormControl(""),
      bookingName: new FormControl("", Validators.required),
      learners: new FormControl("", Validators.required),
      course: new FormControl("", Validators.required)
    });
  }

  private listenForSearchChange(): void {
    this.courseSearchText.valueChanges.pipe(
      debounceTime(200)).subscribe(async searchText => {
        this.loadingCourses = true;
        this.courses = [];
        this.courses = (await this.restService.get(`${environment.API.url}/Course`, { pageSize: 5, searchTerm: searchText }).finally(() => {
          this.loadingCourses = false;
        })).data;
      });

    this.searchTextLearner.valueChanges.pipe(
      debounce(() => interval(200)),
    ).subscribe(async searchText => {
      this.filteredLeaners = [];
      this.loadingLearners = true;

      this.filteredLeaners = (await this.rest.get(`${environment.API.url}/Learner`, {
        searchTerm: searchText,
        pageNumber: 1,
        pageSize: 5
      } as SearchFilter)
        .finally(() => {
          this.loadingLearners = false;
        })).data;
    });
  }

  public courseBlur() {
    let course = this.mainForm.controls["course"].value;
    if (!course?.id) {
      this.courseSearchText.setValue("", { emitEvent: false });
    } else {
      this.courseSearchText.setValue(course, { emitEvent: false });
    }
  }

  public learnerBlur() {
    this.searchTextLearner.setValue("", { emitEvent: false });
  }

  public async makeBooking() {
    try {
      console.log(this.selectedSession);
      this.submitted = true;
      if (this.mainForm.valid && this.selectedDate != null) {

        const _bookingLearners = this.selectedLearners.map(learner => {
          return {
            learnerId: learner.id,
            courseResourceAllocations: [{
              courseResourceId: this.selectedSession.courseResourceId
            }]
          };
        })

        this.loading = true;
        const bookingid = await this.rest.post(`${environment.API.url}/Booking/Create`, {
          "requestor": this.mainForm.get("requestor").value,
          "requestorEmail": this.mainForm.get("requestorEmail").value,
          "contactName": this.mainForm.get("contactName").value,
          "contactNumber": this.mainForm.get("contactNumber").value,
          "contactEmail": this.mainForm.get("contactEmail").value,
          "bookingName": this.mainForm.get("bookingName").value,
          "bookingLearners": _bookingLearners
        });

        this.router.navigate(['admin/bookings/edit/' + bookingid]);
        this.alert.openSnackBar({ duration: 5, message: "Booking created successfully", mode: "success" });
      }
    } catch (error) {
      this.loading = false;;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }

  public createNewResource(): void {
    this.createResource = !this.createResource;

    if (this.createResource) {
      this.resourceSearch = null;
    }
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['routable-crumb'],
        title: 'Booking',
        link: ['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
      ],
      heading: "BOOKING"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

  get data$() {
    return this._learnerAllocations.asObservable();
  }

  get learnerAllocationsLoading$() {
    return this._learnerAllocationsLoading.asObservable();
  }
}
