import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { listChanges } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  user : any;
  roles : Array<any>;


  constructor(
    public dialog       : MatDialog,
    private rest        : RestService,
    private route       : ActivatedRoute,
    private router      : Router,
    private formBuilder : FormBuilder,
    private alert       : UialertsService
    ) { 


    }


    
  userForm   : FormGroup;

  private createUserForm() : void {

  console.log(this.user);
  
    this.userForm = this.formBuilder.group({
      emailAddress: new FormControl(this.user.emailAddress, [Validators.required]),
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      roles: new FormControl((this.user.userRoles as Array<any>).reduce((p,c) => { 
        p.push(c.role.id);
        return p;
      },[]), Validators.required),
    });


    console.log(this.user.userRoles);
    
    (this.user.userRoles as Array<any>).reduce((p,c) => { 
      p.push(c.role.id);
      return p;
    },[])
    
    
    console.log(this.userForm);
    
  }

  loading : boolean = true;

  async ngOnInit() {

    let uid = this.route.snapshot.paramMap.get('id');
    await this.loadUser(uid);
    
    this.loadRoles();
    this.createUserForm();
    this.createBreadCrumb()

    
    this.loading = false;

  }

  private async loadRoles() { 
    try {
      this.roles = await this.rest.get(`${environment.API.url}/Role`);     
    } catch (error) { 
      console.error(error);
    }
  }

  public async updateUser() { 

    try {
      this.userForm.markAllAsTouched();
      this.loading = true;
      if(this.userForm.valid){
      await this.rest.post(`${environment.API.url}/User/Update`,{
        "id"          : this.user.id,
        "uid"         : this.user.uid,
        "firstName"   : this.userForm.get("firstName").value,
        "lastName"    : this.userForm.get("lastName").value,
        "emailAddress": this.userForm.get("emailAddress").value,
        "roleIds"     : this.userForm.get("roles").value
      })

      this.alert.openSnackBar({ duration: 5, message: "User updated", mode: "success" });
      this.router.navigate(['manage/users'])
      }
      this.loading= false;
    } catch (error) {
      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  private async loadUser(uid : string) {
    return new Promise<any>(async (resolve,reject)=>{
      this.user = await this.rest.get(`${environment.API.url}/user/GetByUid?uid=${uid}`);
      resolve(this.user);
    })
  }

  public deleteUser() : void {
    this.openDialog();
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
    });
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['routable-crumb'],
        title: 'Users',
        link:['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
    ],
      heading: this.userForm.get("firstName").value + " " + this.userForm.get("lastName").value
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
