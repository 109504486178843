import { Injectable, NgZone } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { Router, Route, ActivatedRoute } from '@angular/router';
import { ParseFireUser } from '../utillities/firebase/fireUserParse';

import { UialertsService } from './uialerts.service';
import { FireUserService } from './FireUser.service';
import { GlobalStateService } from "./global-state.service";
@Injectable({
    providedIn: 'root'
})

export class AccountService {

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private alertService: UialertsService,
        private route: ActivatedRoute,
        private stateService: GlobalStateService,
        private ngZone: NgZone,
        private fireUserService: FireUserService
    ) { }


    public async login(credentials: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            try {
                const user = await this.auth.login(credentials);
                resolve(user)
            }
            catch (error) {
                reject(error);
            }
        });
    }


    public async register(credentials: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            try {
                let user = await this.auth.register(credentials);
                user.user.sendEmailVerification();
                await this.fireUserService.writeUser(user.user.uid, ParseFireUser(user.user));
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    public async googleLogin() {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            try {
                await this.auth.googleLogin();
                // resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    public async facebookLogin() {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            try {
                await this.auth.facebookLogin();
                // resolve();
            } catch (error) {
                reject(error);
            }
        });
    }


    public async loginListener() {
        this.stateService.authenticated$.subscribe(auth => {
            if (auth) {
                let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
                this.ngZone.run(() => (this.router.navigate([returnUrl])
                    .then(async (successNavigated) => {
                        let externalLogin = await this.auth.getRedirectResult().then(async resp => {
                            if (resp.user) {
                                let googleProvider = resp.user.providerData.find(e => e.providerId === 'google.com');
                                let facebookProvider = resp.user.providerData.find(e => e.providerId === "facebook.com");
                                if (googleProvider || facebookProvider) {

                                    if (googleProvider) {
                                        this.alertService.openSnackBar({ duration: 5, message: "Successfully logged in using google ", mode: "success" })
                                        return true;
                                    }
                                    if (facebookProvider) {
                                        this.alertService.openSnackBar({ duration: 5, message: "Successfully logged in using facebook", mode: "success" })
                                        return true;
                                    }
                                }
                            }
                            return false;
                        });
                    })));
            }
        });
    }

    public async logout(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                this.auth.logout();
                this.router.navigate(['/account/login'])
                    .then(() => {
                        this.alertService.openSnackBar({ duration: 5, message: "Successfully logged out ", mode: "warning" })
                        resolve(true);
                    });
            }
            catch (error) {
                this.alertService.openSnackBar({ duration: 10, message: error, mode: "danger" })
                reject(Error(error));
            }
        })
    }
}