import { Route } from "@angular/router"
import { LoginComponent } from "../../../components/pages/login/login.component";
import { DashboardComponent } from "../../../components/pages/dashboard/dashboard.component";
import { BaseLayoutComponent } from "../../../components/layouts/base-layout/base-layout.component";
import { NoAccessComponent } from "../../../../app/components/pages/no-access/no-access.component";
import { ToolbarComponent } from "../../../components/general/toolbar/toolbar.component";
import { LoaderComponent } from "../../../components/general/loader/loader.component";
import { BreadCrumbComponent } from "../../../components/shared/bread-crumb/bread-crumb.component";

import { AuthGuard } from "../../guards/authGuard";
import { BookingsComponent } from 'src/app/components/pages/admin/bookings/bookings.component';
import { CoursesComponent } from 'src/app/components/pages/admin/courses/courses.component';
import { RequestsComponent } from 'src/app/components/pages/admin/requests/requests.component';
import { ReportsApprovalsComponent } from 'src/app/components/pages/admin/reports-approvals/reports-approvals.component';
import { StudentsComponent } from 'src/app/components/pages/people/students/students.component';
import { FacilitatorsComponent } from 'src/app/components/pages/people/facilitators/facilitators.component';

import { UsersComponent } from 'src/app/components/pages/manage/users/users.component';
import { CreateUserComponent } from 'src/app/components/pages/manage/users/create-user/create-user.component';
import { UpdateUserComponent } from 'src/app/components/pages/manage/users/update-user/update-user.component';

import { DeleteDialogComponent } from 'src/app/components/shared/delete-dialog/dialog.component';

import { CreateFacilitatorComponent } from "src/app/components/pages/people/facilitators/create-facilitator/create-facilitator.component";
import { UpdateFacilicatorComponent } from "src/app/components/pages/people/facilitators/update-facilicator/update-facilicator.component";

import { RolesComponent } from "src/app/components/pages/manage/roles/roles.component";
import { CreateRoleComponent } from "src/app/components/pages/manage/roles/create-role/create-role.component";
import { UpdateRoleComponent } from "src/app/components/pages/manage/roles/update-role/update-role.component";

import { TrackerComponent } from "src/app/components/pages/manage/tracker/tracker.component";


import { ClientsComponent } from "src/app/components/pages/admin/clients/clients.component";
import { CreateClientComponent } from "src/app/components/pages/admin/clients/create-client/create-client.component";
import { UpdateClientComponent } from "src/app/components/pages/admin/clients/update-client/update-client.component";
import { UpdateStudentComponent } from "src/app/components/pages/people/students/update-student/update-student.component";
import { CreateStudentComponent } from "src/app/components/pages/people/students/create-student/create-student.component";
import { CreateCourseComponent } from "src/app/components/pages/admin/courses/create-course/create-course.component";
import { UpdateCourseComponent } from "src/app/components/pages/admin/courses/update-course/update-course.component";

import { SessionsComponent } from "src/app/components/pages/admin/sessions/sessions.component";
import { CreateSessionComponent } from "src/app/components/pages/admin/sessions/create-session/create-session.component";
import { UpdateSessionComponent } from "src/app/components/pages/admin/sessions/update-session/update-session.component";

import { CreateBookingComponent } from "src/app/components/pages/admin/bookings/create-booking/create-booking.component";
import { LearnersTableComponent } from "../../../components/pages/admin/bookings/create-booking/learners-table/learners-table.component";
import { UpdateBookingComponent } from "src/app/components/pages/admin/bookings/update-booking/update-booking.component";
import { LearnerTableComponent } from "../../../components/pages/admin/bookings/update-booking/learner-table/learner-table.component";

import { ReportComponent } from "src/app/components/pages/admin/reports-approvals/report/report.component";
import { CertificatesComponent } from "../../../components/pages/admin/certificates/certificates.component";
import { CertificatesTableComponent } from "../../../components/pages/admin/certificates/certificates-table/certificates-table.component";


export const ROUTES: Route[] = [
    {
        path: "",
        component: BaseLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "", redirectTo: "admin/bookings", pathMatch: 'full' },
            { path: "admin/dashboard", component: DashboardComponent },

            { path: "admin/bookings", component: BookingsComponent },
            { path: "admin/bookings/create", component: CreateBookingComponent },
            { path: "admin/bookings/edit/:id", component: UpdateBookingComponent },



            { path: "admin/sessions", component: SessionsComponent },
            { path: "admin/sessions/create", component: CreateSessionComponent },
            { path: "admin/sessions/edit/:id", component: UpdateSessionComponent },


            { path: "admin/courses", component: CoursesComponent },
            { path: "admin/courses/edit/:id", component: UpdateCourseComponent },
            { path: "admin/courses/create", component: CreateCourseComponent },


            { path: "admin/requests", component: RequestsComponent },
            { path: "admin/reports-approvals", component: ReportsApprovalsComponent },

            { path: "admin/reports-approvals/clients/create", component: CreateClientComponent },
            { path: "admin/reports-approvals/clients/:level", component: ClientsComponent },
            { path: "admin/reports-approvals/clients/edit/:id", component: UpdateClientComponent },

            { path: "admin/certificates", component: CertificatesComponent },



            { path: "people/students", component: StudentsComponent },
            { path: "people/students/edit/:id", component: UpdateStudentComponent },
            { path: "people/students/create", component: CreateStudentComponent },

            { path: "people/facilitators", component: FacilitatorsComponent },
            { path: "people/facilitators/create", component: CreateFacilitatorComponent },
            { path: "people/facilitators/edit/:id", component: UpdateFacilicatorComponent },



            { path: "manage/users", component: UsersComponent },
            { path: "manage/users/edit/:id", component: UpdateUserComponent },
            { path: "manage/users/create", component: CreateUserComponent },

            { path: "manage/roles", component: RolesComponent },
            { path: "manage/roles/edit/:id", component: UpdateRoleComponent },
            { path: "manage/roles/create", component: CreateRoleComponent },

            { path: "manage/approvals", component: TrackerComponent },

            { path: "admin/reports-approvals/report/:name/:type", component: ReportComponent }
        ]
    },
    { path: 'account/no-access', component: NoAccessComponent, canActivate: [AuthGuard] },
    { path: "account/login", component: LoginComponent },
]

export const Components = [

    ToolbarComponent,
    BaseLayoutComponent,
    LoaderComponent,
    BreadCrumbComponent,

    DashboardComponent,
    LoginComponent,
    NoAccessComponent,
    BookingsComponent,
    CoursesComponent,
    RequestsComponent,
    ReportsApprovalsComponent,
    StudentsComponent,
    FacilitatorsComponent,
    UsersComponent,
    CreateUserComponent,
    UpdateUserComponent,
    DeleteDialogComponent,

    RolesComponent,
    UpdateRoleComponent,
    CreateRoleComponent,
    TrackerComponent,

    UpdateFacilicatorComponent,
    CreateFacilitatorComponent,

    ClientsComponent,
    CreateClientComponent,
    UpdateClientComponent,

    UpdateStudentComponent,
    CreateStudentComponent,

    UpdateCourseComponent,
    CreateCourseComponent,

    SessionsComponent,
    CreateSessionComponent,
    UpdateSessionComponent,

    UpdateBookingComponent,
    LearnersTableComponent,
    CreateBookingComponent,
    LearnerTableComponent,

    ReportComponent,

    CertificatesComponent,
    CertificatesTableComponent
]
