import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, pipe } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';
import { UnitStandard } from 'src/app/library/interfaces/course/unit-standard';
import { debounce, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.scss']
})
export class CreateCourseComponent implements OnInit {

  @ViewChild('unitStandardInput') unitStandardInput: ElementRef<HTMLInputElement>;

  course: any;
  roles: Array<any>;
  mainForm: FormGroup;
  loading: boolean = true;

  private _unitStandards = new BehaviorSubject<UnitStandard[]>(null);
  public unitStandardsLoading = false;

  races: any = GLOBAL_CONSTS.races;

  courseType: any = GLOBAL_CONSTS.CourseType;
  courseReferenceNo: any = GLOBAL_CONSTS.CourseReferenceNo;
  coursePassSymbol: any = GLOBAL_CONSTS.CoursePassSymbol;
  categoryType: any = GLOBAL_CONSTS.CategoryType;
  categoryModeOfTraining: any = GLOBAL_CONSTS.CategoryModeOfTraining;

  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: UialertsService
  ) { }

  async ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');
    this.createUserForm();
    this.createBreadCrumb();
    await this.loadUnitStandards();
    await this.listeners();

    this.loading = false;
  }

  private createUserForm(): void {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      type: new FormControl(null),
      code: new FormControl(null),
      referenceNo: new FormControl(null),
      passMark: new FormControl(null),
      passSymbol: new FormControl(null),
      validFor: new FormControl(null),
      modeOfTraining: new FormControl(null),
      category: new FormControl(null),
      tetaAccredited: new FormControl(false),
      capacity: new FormControl(null),
      restrictions: new FormControl(null),
      unitStandards: new FormControl([]),
      unitStandardInput: new FormControl(null),
    });
  }

  public unitStandardBlur() {
    this.mainForm.controls.unitStandardInput.setValue(null, { emitEvent: false });
    this.unitStandardInput.nativeElement.value = "";
  }
  public async createItem() {
    try {
      this.loading = true;
      if (this.mainForm.valid) {

        await this.rest.post(`${environment.API.url}/Course/Create`, {
          "name": this.mainForm.get('name').value,
          "description": this.mainForm.get('description').value,
          "unitStandardIds": this.mainForm.get('unitStandards').value.map(e => e.id),
          "courseDetail": {
            "type": this.mainForm.get('type').value,
            "code": this.mainForm.get('code').value,
            "referenceNo": this.mainForm.get('referenceNo').value,
            "passMark": this.mainForm.get('passMark').value,
            "passSymbol": this.mainForm.get('passSymbol').value,
            "validFor": this.mainForm.get('validFor').value,
            "tetaAccredited": this.mainForm.get('tetaAccredited').value,
            "restrictions": this.mainForm.get('restrictions').value,
            "capacity": this.mainForm.get('capacity').value,
          },
          "courseCategoryDetail": {
            "modeOfTraining": this.mainForm.get('modeOfTraining').value,
            "category": this.mainForm.get('category').value
          }
        });

        this.alert.openSnackBar({ duration: 5, message: "Course created", mode: "success" });
        this.router.navigate(['admin/courses']);
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
    }
  }

  public addUnitStandard(event) {

    this.mainForm.get('unitStandards').value.push(event.option.value);
    this.unitStandardInput.nativeElement.value = '';
  }
  public removeUnitStandard(event) {
    this.mainForm.get('unitStandards').value.pop(event);
    this.unitStandardInput.nativeElement.value = '';
  }

  private async listeners() {
    this.mainForm.get('unitStandardInput').valueChanges.pipe(debounceTime(300)).subscribe(async data => {
      this._unitStandards.next(null);
      await this.loadUnitStandards(data);
    });

    this.mainForm.get('tetaAccredited').valueChanges.subscribe(async bool => {
      if (!bool) {
        this.mainForm.get('restrictions').setValue("");
        this.mainForm.get('capacity').setValue("");
      }
    });
  }




  get unitStandards$() {
    return this._unitStandards.asObservable();
  }
  get fc() {
    return this.mainForm.controls;
  }

  private async loadUnitStandards(keyword?: string) {
    this.unitStandardsLoading = true;
    let resp = await this.rest.get(`${environment.API.url}/UnitStandards`, { pageNumber: 1, pageSize: 5, searchTerm: keyword });
    let currentSet = this.mainForm.get('unitStandards').value;
    let filteredSet = resp.data.filter(e => currentSet.map(e => e.id).indexOf(e.id) < 0);
    this._unitStandards.next(filteredSet);
    this.unitStandardsLoading = false;
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['routable-crumb'],
        title: 'Courses',
        link: ['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
      ],
      heading: "CREATE COURSE"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
