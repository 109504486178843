import { Injectable } from "@angular/core";

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DatabaseService {

    constructor(private database: AngularFirestore) {}


    private async getCollection(path : string) : Promise<any> { 
        return new Promise<any>(async (resolve,reject)=>{

            try {
                let collection = await this.database.collection('users');
                resolve(collection)
            } catch (error) {
                resolve(reject);
            }

        })
    }

    public async getCollection$(path : string, orderBy? : string) : Promise<any> { 
        return new Promise<any>(async (resolve,reject)=>{
            if(orderBy){
                let collection = await this.database.collection(path,ref => ref.orderBy(orderBy || null)).valueChanges();
                resolve(collection);
            }
            else {
                let collection = await this.database.collection(path).valueChanges();
                resolve(collection);
            }
        })
    }

    public getDocument$( path : string, _doc : string) : Promise<Observable<unknown>> { 
        return new Promise<Observable<unknown>>(async (resolve,reject)=>{
            try {
                let reference = this.database.collection(path).doc(_doc);
                resolve(reference.valueChanges());
            } catch (error) {
                reject(error);
            }
        })
    }

    public getDocument( path : string, _doc : string) : Promise<firebase.firestore.DocumentData | any> { 
        return new Promise<firebase.firestore.DocumentData>(async (resolve,reject)=>{
            try {
                this.database.collection(path).doc(_doc).ref.get().then((doc)=>{
                    if(doc.exists) { 
                        resolve(doc.data());
                    } else { 
                        reject(new Error("Document does not exists"));
                    }
                })
                
            } catch (error) {
                reject(error);
            }
        })
    }

    public deleteDocument(docPath : string) : Promise<boolean> { 
        return new Promise<boolean>(async (resolve,reject)=>{
            try {
                await this.database.doc(docPath).delete();
                resolve(true)
            } catch (error) {
                reject(error);
            }
        })
    }


    public addDocument(docPath : string, collection : string,  doc : any) : Promise<boolean> { 
        return new Promise<boolean>(async (resolve,reject)=>{
            try {
                await this.database.doc(docPath).collection(collection).add(doc)
                resolve(true)
            } catch (error) {
                reject(error);
            }
        })
    }

    public updateDocument(docPath : string, partial : any) : Promise<boolean> { 
        return new Promise<boolean>(async (resolve,reject)=>{
            try {
                await this.database.doc(docPath).update(partial);
                resolve(true)
            } catch (error) {
                reject(error);
            }
        })
    }
}


