import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Library } from '../enums/global-enum';
import { RestService } from "./rest.service";

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(private restService: RestService) { }


  public async getCertificates(url: string, pageNumber: number, expired: boolean, searchTerm?: string, startDate?: any, endDate?: any): Promise<Array<any>> {
    return new Promise<Array<any>>(async (resolve, reject) => {
      var results = await this.restService.get(`${url}?PageSize=5&PageNumber=${pageNumber}&Pages=2${searchTerm ? '&SearchTerm=' + searchTerm : ''}&Expired=${expired}
      ${startDate ? '&FromDate=' + startDate : ''}${endDate ? '&ToDate=' + endDate : ''}
      `);
      resolve(results)
    })
  }


  public async getCertificatesSummary(startDate: any, endDate: any, expired: boolean, reportType: Library.ReportType): Promise<any> {
    window.open(`${environment.API.url}/report/learners/certificateReport?FromDate=${startDate}&ToDate=${endDate}&Expired=${expired}&ExportType=${reportType}`);
  }
}
