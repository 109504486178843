import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

import { USERS } from "src/test/static-data/users";

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['uid', 'name', 'email'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly pageSizeOptions: number[] = [10];
  public dataResult: PagedResult<any[]>;
  public loadingPageResults = true;
  loading: boolean = true;
  searchText = new FormControl();
  dataSource;

  constructor(
    private router: Router,
    private restService: RestService
  ) {

  }

  async ngOnInit() {
    this.createBreadCrumb();
    await this.loadTable();
    this.listeners();
    //this.createUpdateTable(USERS);
  }



  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['base-crumb'],
        title: 'Users'
      }
      ],
      heading: `USERS`
    }
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openUser(user: any) {
    this.router.navigate([`manage/users/edit/${user.uid}`])
  }

  private createUpdateTable(source: Array<any>): void {

    const mappedSource = source.map((element) => {
      return {
        uid: element.uid,
        name: element.firstName + " " + element.lastName,
        email: element.emailAddress,
      }
    })

    this.dataSource = new MatTableDataSource(mappedSource);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }



  ngAfterViewInit() {
    this.loadPaginator();
  }


  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/User`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0]
    } as SearchFilter)) as PagedResult<any[]>;
    if (response.data) {
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }
}
