<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="spinner-loader" *ngIf="loading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <div *ngIf="!loading">
        <form [formGroup]="mainForm" class="row full-width">
            <div class="dates">
                <div class="row full-width">

                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Booking Name</mat-label>
                            <input matInput class="form-control" formControlName="bookingName">
                            <mat-error *ngIf="submitted && mainForm.controls.bookingName.errors">
                                <span class="fw-bold small my-2"
                                    *ngIf="mainForm.controls.bookingName.errors.required">Booking name is
                                    required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Course</mat-label>
                            <mat-spinner *ngIf="loadingCourses" class="loader-change" diameter="30"></mat-spinner>
                            <input type="text" (blur)="courseBlur()" matInput [formControl]='courseSearchText'
                                [matAutocomplete]="course" (change)="clearData()">
                            <mat-autocomplete [displayWith]="displayFn" (optionSelected)="setCourse($event)" #course="matAutocomplete">
                                <mat-option *ngFor="let course of courses" [value]="course">
                                    {{course.name}}
                                </mat-option>
                                <mat-option [disabled]="true" *ngIf="courses?.length == 0 && !loadingCourses">
                                    No Results Found
                                </mat-option>
                            </mat-autocomplete>
                            <mat-hint *ngIf="submitted && mainForm.controls.course.errors">
                                <span class="mat-error fw-bold small my-2"
                                    *ngIf="mainForm.controls.course.errors.required">Course is required</span>
                            </mat-hint>

                        </mat-form-field>

                        <mat-form-field class="CourseDateDropdown" appearance="outline" *ngIf="display">
                            <mat-label>Course Date</mat-label>
                            <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker"
                                (dateInput)="addEvent('input', $event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint *ngIf="submitted">
                                <span class="fw-bold small my-2 mat-error" *ngIf="this.selectedDate == null">Date is
                                    required</span>
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline" *ngIf="dateSet">
                            <mat-label>Sessions starting on date</mat-label>
                            <mat-select placeholder="Select a session"
                                (selectionChange)="selectedSessionMethod($event.value)">
                                <mat-option *ngFor="let session of selectedSessions" [value]="session">
                                    {{session.sessionNumber}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="row-inner" *ngIf="selectedSessionShow">
                        <mat-form-field appearance="outline">
                            <mat-label>Facilitator</mat-label>
                            <input [disabled]="true" matInput class="form-control"
                                [(ngModel)]="facilitatorName.firstName" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Cost Recovery number</mat-label>
                            <input [disabled]="true" matInput class="form-control" [(ngModel)]="costrecoveryNumber"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="row-inner" *ngIf="selectedSessionShow">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input [disabled]="true" matInput class="form-control"
                                [(ngModel)]="selectedSession.startDate" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>End Date</mat-label>
                            <input [disabled]="true" matInput class="form-control" [(ngModel)]="selectedSession.endDate"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Learner</mat-label>
                            <input matInput (blur)="learnerBlur()" [formControl]='searchTextLearner'
                                [matAutocomplete]="auto">
                            <mat-spinner *ngIf="loadingLearners" class="loader-change" diameter="30"></mat-spinner>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addLeaners($event)">
                                <mat-option *ngFor="let learner of filteredLeaners" [value]="learner">
                                   {{learner.initials}} {{learner.lastName}}
                                    - {{learner.identificationNumber}}
                                </mat-option>
                                <mat-option [disabled]="true" *ngIf="filteredLeaners?.length == 0 && !loadingLearners">
                                    No Results Found
                                </mat-option>
                            </mat-autocomplete>
                            <mat-hint *ngIf="submitted">
                                <span class="mat-error fw-bold small my-2"
                                    *ngIf="mainForm.controls.learners.value.length < 0 || mainForm.controls.learners.value == null || mainForm.controls.learners.value == ''">At
                                    least one learner is required</span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <button class="basic-button" (click)="makeBooking();" mat-button><small>Create booking</small></button>

            </div>
        </form>
    </div>
    <div *ngIf="data$">
        <learners-table [data$]="data$" [loading$]=learnerAllocationsLoading$
            (removeLearnerFromBookings)=removeLearner($event)>
        </learners-table>
    </div>

</div>