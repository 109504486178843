import { Injectable } from '@angular/core';
import { User } from '../interfaces/mto/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private readonly _user = new BehaviorSubject<User>(null as User);
  private readonly _authenticated = new BehaviorSubject<boolean>(false);
  private readonly _authToken = new BehaviorSubject<string>(null);

  get authToken$(): Observable<string>{
    return this._authToken.asObservable();
  }
  set authToken(token: string){
      this._authToken.next(token);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }
  set user(user: User) {
    this._user.next(user);
  }

  get authenticated$(): Observable<boolean> {
    return this._authenticated.asObservable();
  }
  get authenticated() : boolean {
    return this._authenticated.value;
  }
  set authenticated(authenticated: boolean) {
    this._authenticated.next(authenticated);
  }
}
