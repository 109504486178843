import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';

@Component({
  selector: 'learner-table',
  templateUrl: './learner-table.component.html',
  styleUrls: ['./learner-table.component.scss']
})
export class LearnerTableComponent implements OnInit {

  @Input() data$: Observable<any>;
  @Input() loading$: Observable<boolean>;
  //@Output() pageingEvent = new EventEmitter();
  @Output() removeLearnerFromBookings = new EventEmitter();
  @Output() setLearnerSymbol = new EventEmitter();
  @Output() setLearnerMark = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  coursePassSymbol        : any = GLOBAL_CONSTS.CoursePassSymbol;

  public displayedColumns: string[] = ["lastName", "identificationNumber", "outcomeMark","outcomeSymbol", "remove"];
  public dataSource: any;

  public searchText = new FormControl();
  constructor() { }

  ngOnInit() {
    this.load();
  }
  
  /*public pageChange(obj) {
    this.dataSource = new MatTableDataSource(null);
    this.pageingEvent.emit(obj)
  }*/
  removeLearnerFromBooking(test:any){
    this.removeLearnerFromBookings.emit(test)
  }

  changeMark(mark,learner){
    learner['courseResourceAllocations'][0]['outcomeMark'] = Number(mark);
    let learners;
    this.data$.subscribe(data => learners = data)
    let index = (learners.bookingLearners as Array<any>).findIndex( _learner => _learner.id == learner.id)
    learners.bookingLearners[index]['courseResourceAllocations'][0]['outcomeMark'] = learner['courseResourceAllocations'][0]['outcomeMark'];
    let updatedLearner =learners.bookingLearners[(learners.bookingLearners as Array<any>).findIndex( _learner => _learner.id == learner.id)];
    this.setLearnerMark.emit(updatedLearner);
  }
  changeSymbol(symbol,learner){
    learner['courseResourceAllocations'][0]['outcomeSymbol'] = symbol.value;
    let learners;
    this.data$.subscribe(data => learners = data)
    let index =(learners.bookingLearners as Array<any>).findIndex( _learner => _learner.id == learner.id);
    learners.bookingLearners[index]['courseResourceAllocations'][0]['outcomeSymbol'] = learner['courseResourceAllocations'][0]['outcomeSymbol'];
    let updatedLearner =learners.bookingLearners[(learners.bookingLearners as Array<any>).findIndex( _learner => _learner.id == learner.id)];
    this.setLearnerMark.emit(updatedLearner);
  }

  private async load() {
    let test;
    this.data$.subscribe(data => {
      if(data){
        this.dataSource = new MatTableDataSource(data.bookingLearners.map(e=> {
          return{  
            lastName: e.learnerLastName??"N/A",  
            identificationNumber: e.learnerIdentificationNumber??"N/A",
            outcomeMark: e.courseResourceAllocations[0].outcomeMark??0,
            outcomeSymbol: e.courseResourceAllocations[0].outcomeSymbol??0,
            remove: 0,

            //Not displayed
            bookingId: e.bookingId,
            courseResourceAllocations: e.courseResourceAllocations,
            id: e.id,
            learnerFirstName: e.learnerFirstName,
            learnerId:  e.learnerId,
            status: e.status
          }  
        }));
      }  
    });
  }
}
