<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>
  
    <div class="spinner-loader" *ngIf="loading">
      <mat-spinner class="center" ></mat-spinner>
    </div>
  
    <form *ngIf="!loading" [formGroup]="clientForm" class="row full-width">
        <div class="row-inner">
          <mat-form-field appearance="outline">
            <mat-label>Code</mat-label>
            <input matInput class="form-control" formControlName="code">
            <mat-error *ngIf="!this.loading && clientForm.controls.code.errors">
              <span class="fw-bold small my-2" *ngIf="clientForm.controls.code.errors.required">Code is required</span>
            </mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput class="form-control" formControlName="title">
            <mat-error *ngIf="!this.loading && clientForm.controls.title.errors">
              <span class="fw-bold small my-2" *ngIf="clientForm.controls.title.errors.required">Title is required</span>
            </mat-error>
          </mat-form-field>
  
        </div>
        <div class="row-inner">
          <mat-form-field  appearance="outline">
            <mat-label>Level</mat-label>
            <mat-select formControlName="level" (ngModelChange)="changeClient()" class="form-control">
              <mat-option [value]="level" *ngFor="let level of levels">
                <small>{{level}}</small>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!this.loading && clientForm.controls.level.errors">
              <span class="fw-bold small my-2" *ngIf="clientForm.controls.level.errors.required">Level is required</span>
            </mat-error>
        </mat-form-field>
            <mat-form-field *ngIf="parentRequired" appearance="outline">
                <mat-label>Client Link - Level {{client.level - 1}}</mat-label>
                <mat-select formControlName="parentId" class="form-control">
                  <mat-option [value]="client.id" *ngFor="let client of allClients">
                    <small>{{client.title}}</small>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!this.loading && clientForm.controls.parentId.errors">
                  <span class="fw-bold small my-2" *ngIf="clientForm.controls.parentId.errors.required">Client Link - Level {{client.level - 1}} is required</span>
                </mat-error>
            </mat-form-field>
        </div>
        <button class="basic-button" (click)="updateClient();" mat-button><small>Update Client</small></button>
  
    </form>    
  
  </div>
  