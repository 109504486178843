import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../library/services/loader/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  private loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingService, private _elmRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef, ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.loadingSubscription = this.loadingScreenService.loading$.pipe().subscribe(
      (status: boolean) => {
        this.loaderElements(status);
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  loaderElements(status: boolean) {
    const mainBody = (document.querySelector('.main-router-outlet') as HTMLElement);
    if (mainBody) mainBody.style.display = !status ? 'block' : 'none';
    this._elmRef.nativeElement.style.display = status ? 'block' : 'none';
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
