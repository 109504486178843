<div class="container">
    <div class="spinner-loader" *ngIf="loading">
      <mat-spinner class="center" ></mat-spinner>
    </div>
  
    <div *ngIf="!loading">
      <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
      </ng-container>
  
      <div class="row full-width">
        <div class="row-inner">
          <mat-form-field appearance="outline">
            <mat-label>Search roles</mat-label>
            <input (keyup)="applyFilter($event.target.value)"  matInput class="form-control">
          </mat-form-field>
          <button routerLink="/manage/roles/create" mat-button class="create-button standard-button full-width m-t-5">
            <mat-icon>add</mat-icon> 
            <span class="title"><strong><small>Create role</small></strong></span>
          </button>
        </div>
      </div>
  
      <h6 style="opacity: 0.6;">Please select a role to modify details. </h6>
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource" matSort class="full-width">
        <ng-template [ngIf]="dataSource.data.length === 0">
          <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
        </ng-template>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="permissions">
            <th mat-header-cell *matHeaderCellDef> Permissions </th>
            <td mat-cell *matCellDef="let element"> {{element.permissions}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openElement(row)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5]"></mat-paginator>
    </div>
  </div>