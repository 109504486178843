import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';
import { debounce, debounceTime } from 'rxjs/operators';
import { IdNumberValidator } from "src/app/library/utillities/validators/id-number-validator";
import { JobTitle } from 'src/app/library/interfaces/student/JobTitle';
import { IClientFilter } from 'src/app/library/interfaces/filters/client-filter';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { Library } from 'src/app/library/enums/global-enum';

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss']
})
export class CreateStudentComponent implements OnInit {
  @ViewChild('jobTitleInput') jobTitleInput: ElementRef<HTMLInputElement>;

  student: any;
  roles: Array<any>;
  mainForm: FormGroup;
  loading: boolean = true;
  idType = Library.IdType;

  races: any = GLOBAL_CONSTS.races;
  companyGroups: any = GLOBAL_CONSTS.CompanyGroup;
  companies: any = GLOBAL_CONSTS.Company;
  disabilities: any = GLOBAL_CONSTS.Disabilities;
  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  private _JobTitles = new BehaviorSubject<JobTitle[]>(null);
  public jobTitlesLoading = false;
  public clientsLoading = false;
  public learnerLoading = false;

  public learnerExists = false;


  allClients: Array<any>;


  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: UialertsService
  ) { }

  async ngOnInit() {
    this.createUserForm();
    await this.loadJobTitles();
    this.listeners();
    this.createBreadCrumb();
    this.loading = false;
  }


  public clientBlur() {
    if (!this.mainForm.controls.client.value?.id) {
       this.mainForm.controls.clientSearchString.setValue("", { emitEvent: false });
    } else {
       this.mainForm.controls.clientSearchString.setValue(this.mainForm.controls.client.value);
    }
  }

  private createUserForm(): void {
    this.mainForm = this.formBuilder.group({
      title: new FormControl(""),
      initials: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      employeeCode: new FormControl(""),
      race: new FormControl("", Validators.required),
      identificationNumber: new FormControl(""),
      disability: new FormControl(""),
      companyGroup: new FormControl(""),
      company: new FormControl(""),
      clientId: new FormControl(""),
      ofoCode: new FormControl({ value: "", disabled: true }),
      jobTitle: new FormControl(""),
      jobTitleId: new FormControl(""),
      jobTitleSearch: new FormControl(),
      client: new FormControl(),
      clientSearchString: new FormControl(),
      verified: new FormControl(false, Validators.required),
      idType: new FormControl(this.idType.SouthAfricanId, Validators.required),
    }, { validators: IdNumberValidator('identificationNumber', 'idType') });

    this.mainForm.controls['clientSearchString']
      .valueChanges
      .pipe(
        debounce(() => interval(300))
      )
      .subscribe((value) => {
        this.searchClient(value);
      });
  }

  public displayClient(item: any): string {
    if (item) {
      return item.title;
    }
  }

  public async searchClient(searchString: string): Promise<void> {
    try {
      if (typeof searchString == 'string') {
        this.clientsLoading = true;
        this.allClients = null;
        this.allClients = (await this.rest.get(`${environment.API.url}/client`, { level: 3, pageSize: 5, searchTerm: searchString } as IClientFilter)
          .finally(() => {
            this.clientsLoading = false;
          }) as PagedResult<any[]>).data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async createItem() {
    if (this.learnerExists) {
      return;
    }
    try {
      this.loading = true;
      if (this.mainForm.valid) {
        await this.rest.post(`${environment.API.url}/Learner/Create`, {
          "title": this.mainForm.get("title").value,
          "initials": this.mainForm.get("initials").value,
          "lastName": this.mainForm.get("lastName").value,
          "employeeCode": this.mainForm.get("employeeCode").value,
          "verified": this.mainForm.get("verified").value,
          "race": this.mainForm.get("race").value,
          "idType": this.mainForm.get("idType").value,
          "identificationNumber": this.mainForm.get("identificationNumber").value,
          "jobTitleId": this.mainForm.get("jobTitleId").value,
          "disability": this.mainForm.get("disability").value,
          "learnerCompanyStructure": {
            "clientId": (this.mainForm.get("client").value).id
          }
        })

        this.alert.openSnackBar({ duration: 5, message: "Student updated", mode: "success" });
        this.router.navigate(['people/students'])
      }
      this.loading = false;
    }

    catch (error) {
      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }

  public selectClient(event) {
    this.mainForm.controls['client'].setValue((event.option.value));
  }

  public async addJobTitle(event) {
    this.mainForm.patchValue(
      {
        jobTitle: event.option.value.title,
        jobTitleId: event.option.value.id,
        ofoCode: event.option.value.ofoCode.code
      });
  }


  private async listeners() {
    this.mainForm.get('jobTitleSearch')?.valueChanges.pipe(debounceTime(300)).subscribe(async data => {
      this._JobTitles.next(null);
      await this.loadJobTitles(data);
    });


    this.fc.idType.valueChanges.subscribe(data => {
      this.mainForm.updateValueAndValidity();
      this.fc.identificationNumber.updateValueAndValidity();
    });

    this.fc.identificationNumber.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
   
      if (!this.fc.identificationNumber.errors?.idNumberValid) {
        this.learnerLoading = true;
        let response = await this.rest.get(`${environment.API.url}/Learner/GetByIdentification?id=${this.fc.identificationNumber.value}`).finally(() => this.learnerLoading = false);
        if (response) {
          this.learnerExists = true;
          this.mainForm.patchValue({
            title: response.title,
            initials: response.initials,
            lastName: response.lastName,
            employeeCode: response.employeeCode,
            verified: response.verified,
            race: response.race,
            identificationNumber: response.identificationNumber,
            disability: response.disability,
            ofoCode: response.jobTitle?.ofoCode?.code,
            jobTitle: response.jobTitle?.title,
            jobTitleId: response.jobTitle?.id,
            companyGroup: response.learnerCompanyStructure?.companyGroup,
            company: response.learnerCompanyStructure?.company,
            client: response.learnerCompanyStructure?.client,
            clientSearchString: response.learnerCompanyStructure?.client,
          });
        } else {
          console.log(response);
          this.mainForm.patchValue({
            title: "",
            initials: "",
            lastName: "",
            employeeCode: "",
            verified: false,
            race: "",
            disability: "",
            ofoCode: "",
            jobTitle: "",
            jobTitleId: "",
            companyGroup: "",
            company: "",
            client: "",
            clientSearchString: "",
          })
          this.learnerExists = false;
        }
      } else {
        this.mainForm.patchValue({
          title: "",
          initials: "",
          lastName: "",
          employeeCode: "",
          verified: false,
          race: "",
          disability: "",
          ofoCode: "",
          jobTitle: "",
          jobTitleId: "",
          companyGroup: "",
          company: "",
          client: "",
          clientSearchString: "",
        })
        this.learnerExists = false;
      }
    });
  }

  //this is the filter and doees the filtering in the get request so no need to map/filter the data again
  private async loadJobTitles(keyword?: string) {
    if (keyword != '') {
      this.jobTitlesLoading = true;
      this._JobTitles.next(null);
      let resp = (await this.rest.get(`${environment.API.url}/JobTitle`, { pageNumber: 1, pageSize: 5, searchTerm: keyword })
        .finally(() => this.jobTitlesLoading = false)).data;
      this._JobTitles.next(resp);
    }
  }

  public jobTitleBlur() {
    if (!this.mainForm.controls.jobTitleId.value) {
      console.log("CLEAR");
      this.jobTitleInput.nativeElement.value = "";
      this.mainForm.controls.jobTitle.setValue("", { emitEvent: false });
    } else {
      this.mainForm.controls.jobTitleSearch.setValue(this.mainForm.controls.jobTitle.value, { emitEvent: false });
    }
  }

  displayTitleFn(item) {
    return item?.title ?? item;
  }

  get jobTitles$() {
    return this._JobTitles.asObservable();
  }


  //BreadCrumb

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'People'
      }, {
        classes: ['routable-crumb'],
        title: 'Students',
        link: ['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
      ],
      heading: "CREATE STUDENT"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

  get fc() {
    return this.mainForm.controls;
  }

}