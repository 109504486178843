import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { JobTitle } from 'src/app/library/interfaces/student/JobTitle';
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';
import { debounce, debounceTime } from 'rxjs/operators';
import { IdNumberValidator } from 'src/app/library/utillities/validators/id-number-validator';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';
import { IClientFilter } from 'src/app/library/interfaces/filters/client-filter';
import { Library } from 'src/app/library/enums/global-enum';

@Component({
  selector: 'app-update-student',
  templateUrl: './update-student.component.html',
  styleUrls: ['./update-student.component.scss']
})
export class UpdateStudentComponent implements OnInit {
  @ViewChild('jobTitleInput') jobTitleInput: ElementRef<HTMLInputElement>;

  public idType = Library.IdType;

  races: any = GLOBAL_CONSTS.races;
  companyGroups: any = GLOBAL_CONSTS.CompanyGroup;
  companies: any = GLOBAL_CONSTS.Company;
  disabilities: any = GLOBAL_CONSTS.Disabilities;

  student: any;
  roles: Array<any>;
  mainForm: FormGroup;
  loading: boolean = true;


  client: any;
  allClients: Array<any>;
  clientSearchString: FormControl = new FormControl();

  private _JobTitles = new BehaviorSubject<JobTitle[]>(null);
  public jobTitlesLoading = false;
  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  public clientsLoading = false;

  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: UialertsService
  ) { }

  async ngOnInit() {
    await this.initData()
    this.createForms();
    await this.loadJobTitles();
    this.listeners();
    this.createBreadCrumb();
    this.loading = false;
  }

  public async initData(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        //load learner
        this.student = await this.rest.get(`${environment.API.url}/learner/getbyid?id=${this.route.snapshot.paramMap.get('id')}`);

        //LoadDefaultClient
        this.client = this.student.learnerCompanyStructure.client;

        resolve();
      }
      catch (error) {
        console.error(error);
        reject(error);
      }
    })
  }

  public clientBlur() {
    if (!this.mainForm.controls.client.value?.id) {
      this.mainForm.controls.clientSearchString.setValue("", { emitEvent: false });
    } else {
      this.mainForm.controls.clientSearchString.setValue(this.mainForm.controls.client.value);
    }
  }

  public selectClient(event) {
    this.mainForm.controls['client'].setValue((event.option.value));
  }

  public jobTitleBlur() {
    if (!this.mainForm.controls.jobTitleId.value) {
      console.log("CLEAR");
      this.jobTitleInput.nativeElement.value = "";
      this.mainForm.controls.jobTitle.setValue("", { emitEvent: false });
    } else {
      this.mainForm.controls.jobTitleSearch.setValue(this.mainForm.controls.jobTitle.value, { emitEvent: false });
    }
  }

  displayTitleFn(item) {
    return item?.title ?? item;
  }


  public async searchClient(searchString: string): Promise<void> {
    try {
      if (typeof searchString == 'string') {
        //loadAllClients
        this.clientsLoading = true;
        this.allClients = (await this.rest.get(`${environment.API.url}/client`, { level: 3, pageSize: 5, searchTerm: searchString } as IClientFilter)
          .finally(() => {
            this.clientsLoading = false;
          }) as PagedResult<any[]>).data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public displayClient(item: any): string {
    if (item) {
      return item.title;
    }
  }

  private createForms(): void {
    this.mainForm = this.formBuilder.group({
      title: new FormControl(this.student.title),
      initials: new FormControl(this.student.initials, Validators.required),
      lastName: new FormControl(this.student.lastName, Validators.required),
      employeeCode: new FormControl(this.student.employeeCode),
      verified: new FormControl(this.student.verified, Validators.required),
      race: new FormControl(this.student.race),
      identificationNumber: new FormControl(this.student.identificationNumber),
      disability: new FormControl(this.student.disability),
      ofoCode: new FormControl({ value: this.student.jobTitle?.ofoCode?.code, disabled: true }),
      jobTitle: new FormControl(this.student.jobTitle?.title),
      jobTitleId: new FormControl(this.student.jobTitle?.id),
      companyGroup: new FormControl(this.student.learnerCompanyStructure.companyGroup),
      company: new FormControl(this.student.learnerCompanyStructure.company),
      client: new FormControl(this.client),
      clientSearchString: new FormControl(this.client),
      jobTitleSearch: new FormControl(this.student.jobTitle?.title),
      idType: new FormControl(this.student.idType, Validators.required),
    }, { validators: IdNumberValidator('identificationNumber', 'idType') });

    this.mainForm.controls['clientSearchString']
      .valueChanges
      .pipe(
        debounce(() => interval(200))
      )
      .subscribe((value) => {
        this.searchClient(value);
      });

  }

  public async updateItem() {
    try {
      this.loading = true;
      if (this.mainForm.valid) {
        await this.rest.post(`${environment.API.url}/learner/Update`, {
          "id": this.student.id,
          "title": this.mainForm.get("title").value,
          "initials": this.mainForm.get("initials").value,
          "lastName": this.mainForm.get("lastName").value,
          "employeeCode": null,
          "verified": this.mainForm.get("verified").value,
          "race": this.mainForm.get("race").value,
          "idType": this.mainForm.get("idType").value,
          "identificationNumber": this.mainForm.get("identificationNumber").value,
          learnerCompanyStructureId: this.student.learnerCompanyStructure.id,
          "disability": this.mainForm.get("disability").value,
          "jobTitleId": this.mainForm.get("jobTitleId").value,
          "learnerCompanyStructure": {
            "id": this.student.learnerCompanyStructure.id,
            "clientId": (this.mainForm.get("client").value)?.id
          }
        })

        this.alert.openSnackBar({ duration: 5, message: "Student updated", mode: "success" });
        this.router.navigate(['people/students'])
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
    }
  }


  public async addJobTitle(event) {
    this.mainForm.patchValue(
      {
        jobTitle: event.option.value.title,
        jobTitleId: event.option.value.id,
        ofoCode: event.option.value.ofoCode.code
      });
  }


  private async listeners() {
    this.mainForm.get('jobTitleSearch')?.valueChanges.pipe(debounceTime(300)).subscribe(async data => {
      this._JobTitles.next(null);
      await this.loadJobTitles(data);
    });

    this.fc.idType.valueChanges.subscribe(data => {
      this.mainForm.updateValueAndValidity();
      this.fc.identificationNumber.updateValueAndValidity();
    });
  }

  //this is the filter and doees the filtering in the get request so no need to map/filter the data again
  private async loadJobTitles(keyword?: string) {
    if (keyword != '') {


      this.jobTitlesLoading = true;
      let resp = (await this.rest.get(`${environment.API.url}/JobTitle`, { pageNumber: 1, pageSize: 5, searchTerm: keyword })).data;
      this._JobTitles.next(resp);
      this.jobTitlesLoading = false;
    }
  }

  get jobTitles$() {
    return this._JobTitles.asObservable();
  }

  //BreadCrumb

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'People'
      }, {
        classes: ['routable-crumb'],
        title: 'Students',
        link: ['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
      ],
      heading: this.mainForm.get("initials").value + " " + this.mainForm.get("lastName").value
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

  get fc() {
    return this.mainForm.controls;
  }

}
