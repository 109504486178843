<div class="table-contianer">
  <table mat-table [dataSource]="dataSource" matSort class="full-width">
    <ng-container matColumnDef="initials">
      <th mat-header-cell *matHeaderCellDef> Initials </th>
      <td mat-cell *matCellDef="let element"> {{element.initials}} </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last name </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
    </ng-container>
    <ng-container matColumnDef="identificationNumber">
      <th mat-header-cell *matHeaderCellDef> Identification no. </th>
      <td mat-cell *matCellDef="let element"> {{element.identificationNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="removeLearnerFromBooking(element)" class="delete-user" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </td>
    </ng-container>


    <ng-container *ngIf="!(loading$ | async) as loading">
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            No Records Found Matching Your Filters
          </td>
        </tr>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  </table>

  <ng-container *ngIf="(loading$ | async) as loading">
    <div class='spinner-loader table-loader' *ngIf="loading">
      <mat-spinner class='center'></mat-spinner>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <div class='spinner-loader table-loader'>
    <mat-spinner class='center'></mat-spinner>
  </div>
  <ng-template>