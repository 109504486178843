import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { parseFirebaseError } from '../utillities/firebase/fireErrorParse';

import { auth } from 'firebase';
import { GlobalStateService } from './global-state.service';
import { RestService } from './rest.service';
import { GlobalConfig } from '../configuration/global-config';

import { environment } from "@environment";

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    constructor(
        private fireAuth        : AngularFireAuth,
        private stateService    : GlobalStateService,
        private rest            : RestService
    ) {
        this.listenForAuthStateChanges();
    }

    public async listenForAuthStateChanges() {
        this.fireAuth.onAuthStateChanged(async (fireUser) => {
            if (fireUser) {
                try { 
                    this.stateService.user = await this.rest.get(`${environment.API.url}/User/GetByUid?uid=${fireUser.uid}`);
                } catch (error) {
                    console.error(error);
                }
                
            } else {
                this.stateService.user = null;
                this.stateService.authenticated = false;
            }
        });
    }

    private loadUserProfile(uid : string) : void {

    }

    public async login(credentials: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            await this.fireAuth.signInWithEmailAndPassword(credentials.email, credentials.password)
                .then(async (user: any) => {
                    // this.fireAuth.auth.updateCurrentUser
                    // await this.fireAuth.auth.updateCurrentUser(user.user);
                    resolve(user)
                })
                .catch((error) => {
                    reject(parseFirebaseError(error));
                })
        })
    }

    public async register(credentials: { email: string, password: string }): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
            await this.fireAuth.createUserWithEmailAndPassword(credentials.email, credentials.password)
                .then((user) => {
                    resolve(user)
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }


    public async logout(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            await this.fireAuth.signOut()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => { reject(false) });
        })
    }

    public async facebookLogin(): Promise<boolean> {
        return await this.fireAuth.signInWithRedirect(new auth.FacebookAuthProvider())
            .then(() => {
                return true;
            }).catch((error) => {
                return error;
            });
    }

    public async googleLogin(): Promise<boolean> {
        return await this.fireAuth.signInWithRedirect(new auth.GoogleAuthProvider())
            .then(() => {
                return true;
            }).catch((error) => {
                return error;
            });
    }

    public async getRedirectResult(): Promise<firebase.auth.UserCredential> {
        return await this.fireAuth.getRedirectResult()
            .then(user => {
                return user;
            }).catch(error => {
                return error;
            });
    }

    public getAuthenticationState(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.fireAuth.onAuthStateChanged((user) => {
                if (user) {
                    resolve(true)
                }
                else {
                    resolve(false)
                }
            })
        })
    }

    private generateRoutes(permissions: any, modules: Array<any>): Array<string> {
        let routes = [];
        GlobalConfig.accessRoutes.forEach(route => {
            if (route.requiredPerms.role == null && route.requiredPerms.module == null) { //add all public routes                
                routes.push(route.path);
            }
        });

        modules.forEach((_module) => {
            if (GlobalConfig.modules[_module.moduleId] == "eco" && _module.isAdmin) { //check if there's atleast one global admin module
                routes = GlobalConfig.accessRoutes.map(route => { return route.path }); //user has access to all routes;
                return routes;
            }
        })

        permissions.forEach(permission => {
            permission.modules.forEach(_mod => {
                const _module = GlobalConfig.modules[_mod.id];
                const role = GlobalConfig.roles[_mod.role.id];

                if (_module == GlobalConfig.modules[3] && role == GlobalConfig.roles[1]) { // user of type admin and has role admin
                    routes = GlobalConfig.accessRoutes; //user has access to all routes
                    return routes;
                }

                GlobalConfig.accessRoutes.forEach(route => {
                    if (route.requiredPerms.module == _module && route.requiredPerms.role == role) {
                        routes.push(route.path);
                    }

                });
            });
        });

        return routes
    }
}

