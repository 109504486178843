import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  name : "Development",
  production: false,
  firebaseConfig  : {
    production: false,
    firebase: {
      apiKey: "AIzaSyCeZDgnr8w5K3cyLApKy-4Oxd5RT9y1aFo",
      authDomain: "mto-lms---development.firebaseapp.com",
      databaseURL: "https://mto-lms---development.firebaseio.com",
      projectId: "mto-lms---development",
      storageBucket: "mto-lms---development.appspot.com",
      messagingSenderId: "698638310675",
      appId: "1:698638310675:web:a6e8019dfd68bd01514ee6",
      measurementId: "G-6L4NJQV4BC"
    }
  },
  API : {
    name : "dev api",
    url  : "https://dev-lms.mto.group/api"
}
};
