<div class="container-header">
  <div class="breadcrumb-wrapper">
    <ul class="breadcrumb">
      <ng-container *ngFor="let crumb of breadCrumb.items">
        <li *ngIf="crumb.link"  [ngClass]="crumb.classes">
          <span [routerLink]="crumb.link">{{crumb.title}}</span>
        </li>
        <li *ngIf="!crumb.link" [ngClass]="crumb.classes">
          <span>{{crumb.title}}</span>
        </li>
      </ng-container>
    </ul>
    <h3>{{breadCrumb.heading}}</h3>
  </div>
</div>