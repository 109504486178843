import { FormGroup } from '@angular/forms';
import { Library } from '../../enums/global-enum';
export function IdNumberValidator(idValControl: string, idTypeControl: string) {
    return (formGroup: FormGroup) => {
        //id val
        const control = formGroup.controls[idValControl];
        let val = control.value;

        //type val
        const typeControl = formGroup.controls[idTypeControl];
        let typeVal = typeControl.value;

        if (typeVal == Library.IdType.SouthAfricanId) {

            //za id number validation
            if (val.length != 13) {
                control.setErrors({ idNumberValid: true });
            }
            if (isNaN(val)) {
                control.setErrors({ idNumberValid: true });
            }
            // get first 6 digits as a valid date
            let tempDate = new Date(val.substring(0, 2), val.substring(2, 4) - 1, val.substring(4, 6));

            let id_date = tempDate.getDate();
            let id_month = tempDate.getMonth();
            let id_year = tempDate.getFullYear();
            if (!((tempDate.getFullYear().toString().substr(-2) == val.substring(0, 2)) && (id_month == val.substring(2, 4) - 1) && (id_date == val.substring(4, 6)))) {
                control.setErrors({ idNumberValid: true });
            }

            // apply Luhn formula for check-digits
            let tempTotal = 0;
            let checkSum = 0;
            let multiplier = 1;
            for (let i = 0; i < 13; ++i) {
                tempTotal = parseInt(val.charAt(i)) * multiplier;
                if (tempTotal > 9) {
                    tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
                }
                checkSum = checkSum + tempTotal;
                multiplier = (multiplier % 2 == 0) ? 1 : 2;
            }
            if ((checkSum % 10) != 0) {
                control.setErrors({ idNumberValid: true });
            };
        }
    }
}