import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { RestService } from 'src/app/library/services/rest.service';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { Library } from 'src/app/library/enums/global-enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounce, tap } from 'rxjs/operators';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, AfterViewInit {

  loading: boolean = false;
  submitted: boolean = false;
  reportName: string;
  reportType: string;
  mainForm: FormGroup;
  courses: Array<any>;

  learners: Array<any> = [];
  clients: Array<any> = [];

  _selectedLearners: Array<any> = [];
  selectedResourceCourse: any;

  courseSearch: string;

  public reportTypeEnum = Library.ReportType;
  course;
  client;

  loadingLearners: boolean = false;
  loadingCourses: boolean = false;
  loadingClients: boolean = false;

  public async searchCourse(searchString: string): Promise<void> {
    try {
      if (searchString != '') {


        this.loadingCourses = true;
        this.courses = null;
        this.courses = (await this.restService.get(`${environment.API.url}/course`, {
          pageSize: 5,
          searchTerm: searchString
        } as SearchFilter)
          .finally(() => {
            this.loadingCourses = false;
          })).data;
      }
    } catch (error) {

    }
  }
  public courseBlur() {
    if (!this.course?.id) {

      this.mainForm.controls.course.setValue("", { emitEvent: false });
    } else {
      this.mainForm.controls.course.setValue(this.course, { emitEvent: false });
    }
  }

  public clientBlur() {
    this.mainForm.controls.clientSearchString.setValue("", { emitEvent: false });
  }
  public learnerBlur() {
    this.mainForm.controls.leanerSearchString.setValue("", { emitEvent: false });
  }

  public async searchClient(searchString: string): Promise<void> {
    try {
      if (searchString != '') {


        this.loadingClients = true;
        this.clients = null;
        if (typeof searchString == 'string') {
          this.clients = (await this.restService.get(`${environment.API.url}/client`, {
            pageSize: 5,
            level: 3,
            searchTerm: searchString
          } as SearchFilter).finally(() => {
            this.loadingClients = false;
          })).data;
        }
      }
    } catch (error) {
    }
  }

  public async selectCourse(event) {
    this.course = event.option.value;

  }

  public async searchLearner(searchString: string): Promise<void> {
    try {
      if (searchString != '') {
        this.loadingLearners = true;
        this.learners = null;
        this.learners = (await this.restService.get(`${environment.API.url}/learner`, {
          pageSize: 5,
          searchTerm: searchString
        } as SearchFilter)).data;
        console.log(this.learners);
        this.loadingLearners = false;
      }
    } catch (error) {
      this.loadingLearners = false;
    }
  }


  constructor(
    private restService: RestService,
    private route: ActivatedRoute,
    private alertService: UialertsService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.reportType = this.route.snapshot.params['type'];
    this.reportName = this.route.snapshot.params['name'];
  }

  ngOnInit(): void {
    this.createMainForm();
    // this.loadAllCourses();
    this.createBreadCrumb();
  }


  public displayFn(item: any): string {
    if (item) {
      if (item.initials || item.lastName) {
        return `${item.initials} ${item.lastName}`
      } else {
        return item && item.name ? item.name : '';
      }
    }
  }

  public displayFnClient(item: any): string {
    if (item) {
      return item.title;
    }
  }

  public addAllLeanersToReport() {
    this._selectedLearners = this._selectedLearners.concat(this.learners);
    this.createTable(this._selectedLearners)
  }


  public addLearner(event): void {
    if (event.value) {
      this._selectedLearners.push(event.value);
      this.createTable(this._selectedLearners);
      this.mainForm.get('leanerSearchString').setValue('');
    } else {
      this._selectedLearners.push(event.option.value);
      this.createTable(this._selectedLearners);
      this.mainForm.get('leanerSearchString').setValue('');
    }
  }

  public async selectClient(event) {
    if (event.option.value) {
      this.client = event.option.value;
      this.mainForm.controls.clientSearchString.setValue('');
      let clientLearners = await this.restService.get(`${environment.API.url}/learner/GetByClientId?clientId=${event.option.value.id}`, { pageSize: 100000 } as SearchFilter) as PagedResult<any>;
      clientLearners.data?.forEach(x => {
        if (!this._selectedLearners.some(z => z.identificationNumber == x.identificationNumber)) {
          this._selectedLearners = this._selectedLearners.concat([x]);
        }
      });
      this.createTable(this._selectedLearners);
    } else {
      this.client = null;
    }
  }

  private createMainForm(): void {
    switch (this.reportType) {
      case '1':
        this.mainForm = this.formBuilder.group({
          startDate: new FormControl("", [Validators.required]),
          endDate: new FormControl("", Validators.required),
          course: new FormControl(null, Validators.required),
          exportType: new FormControl(Library.ReportType.PDF, Validators.required)
        });

        this.mainForm.controls['course']
          .valueChanges
          .pipe(debounce(() => interval(300)))
          .subscribe((value) => {
            console.log(value);

            this.searchCourse(value);
          })

        break;

      case '2':
        this.mainForm = this.formBuilder.group({
          startDate: new FormControl("", [Validators.required]),
          endDate: new FormControl("", Validators.required),
          leanerSearchString: new FormControl("", null),
          clientSearchString: new FormControl("", null),
          exportType: new FormControl(Library.ReportType.PDF, Validators.required)
        });

        this.mainForm.controls['clientSearchString']
          .valueChanges
          .pipe(
            debounce(() => interval(300))
          )
          .subscribe((value) => {
            this.searchClient(value);
          });


        this.mainForm.controls['leanerSearchString']
          .valueChanges
          .pipe(
            debounce(() => interval(200))
          )
          .subscribe((value) => {
            this.searchLearner(value);
          });

        break;

      case '3':
        this.mainForm = this.formBuilder.group({
          startDate: new FormControl("", [Validators.required]),
          endDate: new FormControl("", Validators.required)
        });

        break;

      case '4':
        this.mainForm = this.formBuilder.group({
          startDate: new FormControl("", [Validators.required]),
          endDate: new FormControl("", Validators.required),
          course: new FormControl(null, Validators.required),
          leanerSearchString: new FormControl("", null),
        });


        this.mainForm.controls['course']
          .valueChanges
          .pipe(debounce(() => interval(200)))
          .subscribe((value) => {
            console.log(value);

            this.searchCourse(value);
          })

        this.mainForm.controls['leanerSearchString']
          .valueChanges
          .pipe(debounce(() => interval(200)))
          .subscribe((value) => {
            console.log(value);

            this.searchLearner(value);
          });


        break;

      case '6':
        this.mainForm = this.formBuilder.group({
          startDate: new FormControl("", [Validators.required]),
          endDate: new FormControl("", Validators.required)
        });

        break;

      default:
        break;
    }


  }

  public async downloadCourseTrainingRecords() {
    try {
      this.mainForm.markAllAsTouched();
      this.submitted = true;
      if (this.mainForm.valid) {
        window.open(`${environment.API.url}/reports/course/trainingrecords?FromDate=${moment(this.mainForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm')}&ToDate=${moment(this.mainForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm')}&CourseId=${this.course.id}&exportType=${this.mainForm.get("exportType").value}`, '_blank');
        Promise.resolve();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async downloadLearnersTrainingRecords() {
    try {
      this.mainForm.markAllAsTouched();
      this.submitted = true;
      if (this.mainForm.valid) {
        this.alertService.openSnackBar({ duration: 5000, message: "Generating Report, this may take a while...", mode: "info" });
        await this.restService.post(`${environment.API.url}/report/learners/trainingrecords`,
          {
            fromDate: moment(this.mainForm.get("startDate").value),
            toDate: moment(this.mainForm.get("endDate").value),
            learnerIds: this._selectedLearners.map(e => e.id),
            exportType: this.mainForm.get("exportType").value,
          }, { responseType: 'blob' })
          .then(resp => {
            saveAs(resp, `Learner Training Records - ${moment().format('YYYY-MM-DD')}.zip`);
          })
        this.alertService.clear();
        Promise.resolve();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async getCertificates() {
    try {
      this.mainForm.markAllAsTouched();
      this.submitted = true;
      if (this.mainForm.valid && this._selectedLearners?.length > 0) {
        window.open(`${environment.API.url}/report/learners/certificates?FromDate=${moment(this.mainForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm')}&ToDate=${moment(this.mainForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm')}` + this.genLearnerParameters(this._selectedLearners) + `&CourseId=${this.course.id}`, '_blank');
        Promise.resolve();
      }

    } catch (error) {
      console.error(error);
    }
  }


  public genLearnerParameters(learners: Array<any>): string {
    let query = "&LearnerIds=";
    learners?.forEach(learner => query += `${learner.id},`)

    query = query.slice(0, -1);

    return query;
  }

  public async downloadBookingsReport() {
    try {
      this.mainForm.markAllAsTouched();
      this.submitted = true
      if (this.mainForm.valid) {

        window.open(`${environment.API.url}/reports/bookings?FromDate=${moment(this.mainForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm')}&ToDate=${moment(this.mainForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm')}`);
        Promise.resolve();

      }
    } catch (error) {
      console.error(error);
    }
  }

  public removeLearner(learner): void {
    console.log(learner);

    const removeIndex = this._selectedLearners.findIndex(x => x.id == learner.id);
    this._selectedLearners.splice(removeIndex, 1);

    this.createTable(this._selectedLearners);
  }


  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  dataSource = new MatTableDataSource([]);

  displayedColumns: string[] = ['name', 'idno'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private async createTable(source: Array<any>) {
    const mappedSource = source.map((element) => {
      return {
        name: `${element.initials} ${element.lastName}`,
        idno: element.identificationNumber,
        id: element.id
      }
    })

    this.dataSource = new MatTableDataSource(mappedSource);
    //this.dataSource.connect().next(mappedSource);

  }
  ngAfterViewInit() {
    this.loadPaginator();
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }



  private async loadAllCourses() {
    try {
      this.courses = await this.restService.get(`${environment.API.url}/Course`);
      Promise.resolve();
    } catch (error) {
      console.error(error);
    }
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [
        {
          classes: ['base-crumb'],
          title: 'Maintain'
        },
        {
          classes: ['routable-crumb'],
          title: 'Reports',
          link: ['../../../']
        },
        {
          classes: ['active-crumb'],
          title: 'report'
        }
      ],
      heading: `${this.reportName}`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
