import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';
import { BehaviorSubject, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalConfig } from 'src/app/library/configuration/global-config';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { CertificateService } from 'src/app/library/services/certificate.service';
import { PagerService } from 'src/app/library/services/paginator.service';
import { RestService } from 'src/app/library/services/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);

  constructor() { }

  ngOnInit() {
    this.createBreadCrumb();
  }



  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['base-crumb'],
        title: 'Certificates'
      }
      ],
      heading: `CERTIFICATES`
    }
  }



  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }


}
