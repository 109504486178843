<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="spinner-loader" *ngIf="loading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <form [formGroup]="sessionForm" *ngIf="!loading">
        <div class="row full-width">
            <div class="row-inner">
                <mat-form-field class="datepicker" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker" formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error *ngIf="submitted && sessionForm.controls.startDate.errors">
                        <span class="fw-bold small my-2" *ngIf="sessionForm.controls.startDate.errors.required">Start Date is Required</span>
                    </mat-error>
                    <mat-hint *ngIf="submitted && !datesValid">
                        <span class="mat-error fw-bold small my-2" *ngIf="!datesValid">Start date must be before end date</span>
                    </mat-hint>
                </mat-form-field>

                <mat-form-field class="datepicker" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                  <mat-error *ngIf="submitted && sessionForm.controls.endDate.errors">
                    <span class="fw-bold small my-2" *ngIf="sessionForm.controls.endDate.errors.required">End Date is Required</span>
                  </mat-error>
                  <mat-hint *ngIf="submitted && !datesValid">
                    <span class="mat-error fw-bold small my-2" *ngIf="!datesValid">End Date should be before start date</span>
                  </mat-hint>
                </mat-form-field>
            </div>


            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Course</mat-label>
                    <input type="text" (blur)="courseBlur()" matInput [matAutocomplete]="course" [formControl]="searchText">
                    <mat-spinner *ngIf="coursesLoading" class="loader-change" diameter="30"></mat-spinner>
                    <mat-autocomplete [displayWith]="displayFnCourse" (optionSelected)="selectCourse($event)" #course="matAutocomplete">
                        <mat-option *ngFor="let course of courses" [value]="course">
                            {{course.name}}
                        </mat-option>
                        <mat-option [disabled]="true" *ngIf="courses?.length == 0 && !coursesLoading">
                            No Results Found
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint class="mat-error" *ngIf="submitted">
                        <div>Course is Required</div>
                    </mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Duration (Days)</mat-label>
                    <input type="number" matInput type="string" class="form-control" formControlName="sessionDuration">
                    <mat-error *ngIf="submitted && sessionForm.controls.sessionDuration.errors">
                        <span class="fw-bold small my-2" *ngIf="sessionForm.controls.sessionDuration.errors.required">Duration is Required</span>
                    </mat-error>
                </mat-form-field>

            </div>
        </div>
        <div class="row full-width">
            <h4>Session information</h4>
            <div class="dates">
                <div class="row full-width">
                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Availabiliy</mat-label>
                            <mat-select formControlName="internal" class="form-control">
                                <mat-option [value]="false"><small>External</small></mat-option>
                                <mat-option [value]="true"><small>Internal</small></mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted && sessionForm.controls.internal.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.internal.errors.required">Availabiliy is Required</span>
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                            <mat-label>Facilitator</mat-label>
                            <input type="text" (blur)="facilitatorBlur()" matInput [matAutocomplete]="facilitator" [formControl]="searchFacilitator">
                            <mat-spinner *ngIf="facilitatorLoading" class="loader-change" diameter="30"></mat-spinner>
                            <mat-autocomplete [displayWith]="displayFnFacilitator" (optionSelected)="selectFacilitator($event)" #facilitator="matAutocomplete">
                                <mat-option *ngFor="let facilitator of facilitators" [value]="facilitator">
                                    {{facilitator.title}} {{facilitator.initials}} {{facilitator.lastName}}
                                </mat-option>
                                <mat-option [disabled]="true" *ngIf="facilitators?.length == 0 && !facilitatorLoading">
                                    No Results Found
                                </mat-option>
                            </mat-autocomplete>
                            <mat-hint class="mat-error" *ngIf="submitted">
                                <div>Facilitator is Required</div>
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Cost Recovery Number</mat-label>
                            <input matInput type="string" class="form-control" formControlName="costRecoveryNumber">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Session No.</mat-label>
                            <input matInput type="number" class="form-control" formControlName="sessionNumber">
                            <mat-error *ngIf="submitted && sessionForm.controls.sessionNumber.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.sessionNumber.errors.required">Session Number is Required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!--[disabled]="sessionForm?.invalid"-->
                <button class="basic-button" (click)="createSession();" mat-button><small>Create Session</small></button>

            </div>
        </div>
    </form>
</div>