import {environment} from "@environment"

const cloudBaseUrl: string = "https://us-central1-washee-87450.cloudfunctions.net/";


var ENVIRONMENT = "STAGING";

export namespace GlobalConfig {
      export namespace Firebase {
            export const userRef = "users";
            export const profileImageRef = "pictures/profile";
            export const publicDownloadsRef = "publicDownloads/";
            export const firebaseEnvironment = environment.firebaseConfig
      }

      export namespace API {
            export const coreV1: string = ENVIRONMENT == "STAGING" ? "https://q-core.mto.group/" : "https://core.mto.group/";
            export const portalApi: string = ENVIRONMENT == "ST!AGING" ? "https://q-papi.mto.group/" : "https://papi.mto.group/";
            export const restApi: string = ENVIRONMENT == "STAG!ING" ? "http://q-api.mto.group/" : "http://api.mto.group/";
      }

      export namespace Wordpress {
            export const mto = 'https://cms.mto.group/';
      }

      export namespace Payments {
            export namespace Snapscan {
                  export const merchantId: string = "mtoforestryonline";
                  export const apiKey: string = "e0e41b3a-fb31-465c-8cfe-5361831ae8ba";
                  export const baseQRUrl: string = "https://pos.snapscan.io/qr/";
            }
            export namespace Zapper {
                  export const merchantId: number = 30270;
                  export const siteId: number = 32591;
                  export const apiKey: string = "c24cd7d4017140909ac2bc5a33aad2ac";
            }
      }

      // export namespace ecentric {
      //       export const _hpp =  restApi.baseUrl.indexOf('https://api') >= 0 ? hpp : devHpp;
      //       // import { uuidv4 } from './uuidv4';
      //       export const merchantGuid =
      //             restApi.baseUrl.indexOf('https://api') >= 0
      //                   ? '93A3401D-1E3A-469A-9F21-07FD81CF6DB1'
      //                   : '53A7FD99-B076-48E0-80F3-B19976EA63D2';
      //       export const merchantKey =
      //             restApi.baseUrl.indexOf('https://api') >= 0
      //                   ? '81099CF0-1042-4739-A47F-7DDB80831ECA'
      //                   : 'D05C7949-8077-4CA0-A37C-7FA7274D1171';
      //       export const transactionType = 'Payment';
      //       export const currency = 'ZAR';
      //       // export const paymentReference = uuidv4();
      // }


      export namespace cloudAPI {
            export const createEntity = `${cloudBaseUrl}createEntitiy`;
            export const publishMessage = `${cloudBaseUrl}publishPushMessage`;
            export const addNewPackage = `${cloudBaseUrl}addNewPackage`;
      }
      export namespace Google {
            export const googleMapKey = "AIzaSyAB-ZWQf-muYe2sijb3PmwOSEArb6Cpum8";
      }

      export namespace MTO {
            export const CDN = "https://s3-eu-west-1.amazonaws.com/mymto-cdn";
      }

      export const BookingTransactionTypes = [
            { label: "Cash", id: 1 }, { label: 'Credit Card', id: 2 }, { label: 'SnapScan', id: 3 }, { label: 'Zapper', id: 4 }
            , { label: 'Ecentric', id: 5 }, { label: 'EFT', id: 7 }, { label: 'Delayed Payment', id: 8}
      ]

      export const PermitTransactionTypes = [
            { label: "Cash", id: 1 }, { label: 'Credit Card', id: 2 }, { label: 'SnapScan', id: 3 }, { label: 'Zapper', id: 4 }
            , { label: 'Ecentric', id: 5 },{ label: 'EFT', id: 7 }
      ]



      export const accessRoutes = [
            {
                  name: 'Bookings',
                  requiredPerms: {
                        module: 'eco',
                        role: 'front-desk'
                  },
                  path: '/manage/bookings'
            },
            {
                  name: 'sale',
                  requiredPerms: {
                        module: 'eco',
                        role: 'front-desk'
                  },
                  path: '/sales/front-desk'
            },

            {
                  name: 'sale',
                  requiredPerms: {
                        module: 'eco',
                        role: 'front-desk'
                  },
                  path: '/sales/front-desk/permit'
            },
            {
                  name: 'sale',
                  requiredPerms: {
                        module: 'eco',
                        role: 'front-desk'
                  },
                  path: '/sales/front-desk/booking'
            },

            {
                  name: 'customers',
                  requiredPerms: {
                        module: null,
                        role: null
                  },
                  path: '/manage/customers'
            },
            {
                  name: 'customer',
                  requiredPerms: {
                        module: null,
                        role: null
                  },
                  path: '/manage/customer/'
            },

            {
                  name: 'reports',
                  requiredPerms: {
                        module: 'eco',
                        role: 1
                  },
                  path: '/manage/reports'
            }
      ]

      export const modules = {
            "1": "eco",
            "2": "ops",
            "3": "admin"
      }

      export const roles = {
            "1": "admin",
            "2": "manager",
            "3": "front-desk"
      }

}
