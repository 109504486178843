<div class="row full-width">
  <mat-slide-toggle class="expired-setting" [formControl]='expiredCertificates'>
    Expired Certificates
  </mat-slide-toggle>
</div>
<div class="row full-width">
  <div class="row-inner">
    <mat-form-field appearance="outline">
      <mat-label>Search Learners</mat-label>
      <input [formControl]='searchText' matInput class="form-control">
    </mat-form-field>

    <div class="download-report">
      <mat-form-field appearance="outline">
        <mat-label>Select Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangeGroup">
          <input matStartDate formControlName="start" disabled>
          <input matEndDate formControlName="end" disabled>

        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix (click)="clearStartDate()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker disabled="false" #picker startView="year"></mat-date-range-picker>
        <mat-error *ngIf="dateFC.start.errors || dateFC.end.errors">Please Select A Valid Date Range</mat-error>
      </mat-form-field>

      <button class="download-btn" (click)="downloadSummary()" mat-stroked-button><span class="material-icons">
          file_download
        </span></button>
    </div>
  </div>

</div>

<div class="table-contianer">
  <table mat-table [dataSource]="dataSource" matSort class="full-width">
    <ng-container matColumnDef="certificateId">
      <th mat-header-cell *matHeaderCellDef> <strong>Certificate No.</strong> </th>
      <td mat-cell *matCellDef="let element"> <strong>{{element.id}}</strong> </td>
    </ng-container>
    <ng-container matColumnDef="learner">
      <th mat-header-cell *matHeaderCellDef> <strong>Learner</strong> </th>
      <td mat-cell *matCellDef="let element"> <strong>{{element.learner.lastName}} {{element.learner.initials}} -
          {{element.learner.identificationNumber}}</strong> </td>
    </ng-container>
    <ng-container matColumnDef="course">
      <th mat-header-cell *matHeaderCellDef> Course </th>
      <td mat-cell *matCellDef="let element"> {{element.course.name }} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> Client </th>
      <td mat-cell *matCellDef="let element"> {{element.learner.learnerCompanyStructure.level3Client ? element.learner.learnerCompanyStructure.level3Client.title : 'unknown'}} </td>
    </ng-container> -->
    <ng-container matColumnDef="acquired">
      <th mat-header-cell *matHeaderCellDef> Acquired </th>
      <td mat-cell *matCellDef="let element"> {{element.courseResource.endDate | date:"dd/MM/yyyy"}}</td>
    </ng-container>
    <ng-container matColumnDef="expired">
      <th mat-header-cell *matHeaderCellDef> Expired </th>
      <td mat-cell *matCellDef="let element"> {{element.expired | date:"dd/MM/yyyy"}}</td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow [ngClass]="{hidden: loadingPageResults != false}">
      <td class="mat-cell text-center" colspan="999">No Results Found
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <ng-container *ngIf="loadingPageResults">
    <div class='table-loader'>
      <mat-spinner class='center'></mat-spinner>
    </div>
  </ng-container>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="dataResult?.collectionSize"></mat-paginator>
</div>