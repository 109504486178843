import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Generated class for the ActivitynamePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'sanitizeUrl',
})
export class SanitizeUrl implements PipeTransform {

  constructor(private sanitizer : DomSanitizer) {}
  /**
     * Takes unsage url and sanitize them with DOM sanitizer
  */

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }
}
