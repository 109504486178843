<div class="container">

  <div>
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
      <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>



    <h6 style="opacity: 0.6;">Please select an approval to view more details. </h6>
    <mat-spinner *ngIf="updatingChange" class="loader-change" diameter="30"></mat-spinner>
    <button (click)="updateEntries()" mat-icon-button aria-label="Example icon button with a menu icon">
      <mat-icon>refresh</mat-icon>
    </button>

    <div class="table-contianer">

      <table mat-table [dataSource]="dataSource" matSort class="full-width">

        <ng-container matColumnDef="operation">
          <th mat-header-cell *matHeaderCellDef> <strong>Operation</strong> </th>
          <td mat-cell *matCellDef="let element"> <strong><small>{{element.actionType}}</small></strong> </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.createdOn | date: 'dd-MMM-yyyy @ HH:mm' }} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="resource">
          <th mat-header-cell *matHeaderCellDef> Resource </th>
          <td mat-cell *matCellDef="let element"> {{element.entityType}} </td>
        </ng-container>
        <ng-container matColumnDef="-">
          <th mat-header-cell *matHeaderCellDef> - </th>
          <td mat-cell *matCellDef="let element">
            <button (click)="approveChange(element.id)" color="primary" mat-button>Approve</button> / <button
              (click)="declineChange(element.id)" color="accent" mat-button>Decline</button>
          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow [ngClass]="{hidden: loadingPageResults != false}">
          <td class="mat-cell text-center" colspan="999">No Results Found
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <ng-container *ngIf="loadingPageResults">
        <div class='table-loader'>
          <mat-spinner class='center'></mat-spinner>
        </div>
      </ng-container>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="dataResult?.collectionSize"></mat-paginator>
    </div>


  </div>
</div>