import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UialertsService } from '../../../library/services/uialerts.service';
import { AccountService } from '../../../library/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loading  : boolean = false; //form loading variable
  public hide: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private alertService: UialertsService,
    public accountService: AccountService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailAddress: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
    });
  }

  async loginFormSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    let user = await this.accountService.login({
      email: this.loginForm.get('emailAddress').value,
      password: this.loginForm.get('password').value
    })
      .then((user) => {
        return user.user;
      })
      .catch((error) => {
        this.loading = false;
        this.alertService.openSnackBar({ duration: 5, message: error, mode: "danger" })
      });
    if (user) {
      return this.router.navigate(['/'])
        .then(() => {
          this.alertService.openSnackBar({ duration: 5, message: "You are now Logged In", mode: "success" })
      })
    } 
  }

  get loginFormControls() { return this.loginForm.controls; }

}
