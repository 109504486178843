import { HoverClassDirective } from "./directives/hover-class"
import { VarDirective } from "./directives/ngVar"
import { NumberOnly } from "./directives/NumberOnly"

const directives = [
    HoverClassDirective,
    VarDirective,
    NumberOnly
]

const helpers = [

]

export const Utilities = [
    ...directives,
    ...helpers
]
