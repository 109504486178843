export namespace Library {

    export enum IdType {
        SouthAfricanId,
        Passport
    }

    export enum Gender {
        Male = 1,
        Female = 2,
        Other = 3
    }

    export enum ReportType {
        PDF,
        EXCEL
    }

    export function genderDecode(value: number) {
        switch (value) {
            case Gender.Male:
                return "Male";

            case Gender.Female:
                return "Female";

            case Gender.Other:
                return "Other";

            default:
                break;
        }
    }

    export enum CourseType {
        Advanced = 1,
        Assessment = 2,
        Certificate = 3,
        Competency = 4,
        Degree = 5,
        Diploma = 6,
        DriversLicence = 7,
        Entry = 8,
        FormalEducation = 9,
        Intermediate = 10,
        Learnership = 11,
        OnTheJobTraining = 12,
        PostDegree = 13,
        PostDiploma = 14,
        PostTrade = 15,
        ShortCourse = 16,
        StatutoryPrescribedCertification = 17,
        Trade = 18
    }

    export function courseTypeDecode(value: number) {
        switch (value) {
            case CourseType.Advanced:
                return "Advanced";

            case CourseType.Assessment:
                return "Assessment";

            case CourseType.Certificate:
                return "Certificate";

            case CourseType.Competency:
                return "Competency";

            case CourseType.Degree:
                return "Degree";

            case CourseType.Diploma:
                return "Diploma";

            case CourseType.DriversLicence:
                return "DriversLicence";

            case CourseType.Entry:
                return "Male";

            case CourseType.FormalEducation:
                return "Entry";

            case CourseType.Intermediate:
                return "FormalEducation";

            case CourseType.Learnership:
                return "Intermediate";

            case CourseType.OnTheJobTraining:
                return "Learnership";

            case CourseType.PostDegree:
                return "OnTheJobTraining";

            case CourseType.PostDiploma:
                return "PostDegree";

            case CourseType.PostTrade:
                return "PostDiploma";

            case CourseType.ShortCourse:
                return "PostTrade";

            case CourseType.StatutoryPrescribedCertification:
                return "ShortCourse";

            case CourseType.Trade:
                return "StatutoryPrescribedCertification";

            default:
                break;
        }
    }

    export enum ChangeTrackerEntityType {
        Facilitator = 1,
        Client = 2,
        Course = 3,
        CourseResource = 4,
        CourseResourceSession = 5
    }

    export function ChangeTrackerEntityTypeDecode(value: number) {
        switch (value) {
            case ChangeTrackerEntityType.Facilitator:
                return "Facilitator";

            case ChangeTrackerEntityType.Client:
                return "Client";

            case ChangeTrackerEntityType.Course:
                return "Course";

            case ChangeTrackerEntityType.CourseResource:
                return "CourseResource";

            case ChangeTrackerEntityType.CourseResourceSession:
                return "CourseResourceSession";

            default:
                break;
        }
    }

    export enum ChangeTrackerActionType {
        Create = 1,
        Update = 2,
        Delete = 3
    }

    export function ChangeTrackerActionTypeDecode(value: number) {
        switch (value) {
            case ChangeTrackerActionType.Create:
                return "Create";

            case ChangeTrackerActionType.Update:
                return "Update";

            case ChangeTrackerActionType.Delete:
                return "Delete";

            default:
                break;
        }
    }

    export enum Status {
        Pending = 1,
        Active = 2,
        Deleted = 3,
        Complete = 4
    }

    export enum SortDirectionType {
        ASC,
        DESC
    }

    export enum CategoryType {
        Agri = 1,
        Driver,
        Environmental,
        Fire,
        Forestry,
        IT,
        Safety,
        Sawmill,
        SoftSkills,
        Unknown
    }


    export function StatusDecode(value: number) {
        switch (value) {
            case Status.Pending:
                return "Pending";

            case Status.Active:
                return "Active";

            case Status.Deleted:
                return "Deleted";

            case Status.Complete:
                return "Complete";

            default:
                break;
        }
    }


}