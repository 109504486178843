<div class="container">
  <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
    <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
  </ng-container>

  <div class="spinner-loader" *ngIf="loading">
    <mat-spinner class="center"></mat-spinner>
  </div>

  <form *ngIf="!loading" [formGroup]="mainForm" class="row full-width">
    <div class="row-inner">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput class="form-control" formControlName="name">
        <mat-error *ngIf="!this.loading && mainForm.controls.name.errors">
          <span class="fw-bold small my-2" *ngIf="mainForm.controls.name.errors.required">Name is required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput class="form-control" formControlName="description">
      </mat-form-field>
    </div>
    <div class="row-inner">
      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" class="form-control">
          <mat-option [value]="type.id" *ngFor="let type of courseType">
            <small>{{type.name}}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput class="form-control" formControlName="code">
      </mat-form-field>
    </div>
    <div class="row-inner">
      <mat-form-field appearance="outline">
        <mat-label>Reference</mat-label>
        <mat-select formControlName="referenceNo" class="form-control">
          <mat-option [value]="reference.name" *ngFor="let reference of courseReferenceNo">
            <small>{{reference.name}}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Pass Symbol</mat-label>
        <mat-select formControlName="passSymbol" class="form-control">
          <mat-option [value]="symbol.id" *ngFor="let symbol of coursePassSymbol">
            <small>{{symbol.name}}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row-inner">
      <mat-form-field appearance="outline">
        <mat-label>Mode of Training</mat-label>
        <mat-select formControlName="modeOfTraining" class="form-control">
          <mat-option [value]="modeOfTraining.id" *ngFor="let modeOfTraining of categoryModeOfTraining">
            <small>{{modeOfTraining.name}}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select formControlName="type" class="form-control">
          <mat-option [value]="category.id" *ngFor="let category of categoryType">
            <small>{{category.name}}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row-inner">
      <mat-form-field appearance="outline">
        <mat-label>Pass Mark</mat-label>
        <input matInput type="number" class="form-control" formControlName="passMark">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Years Valid</mat-label>
        <input matInput type="number" class="form-control" formControlName="validFor">
      </mat-form-field>
    </div>
    <div class="row-inner">
      <mat-form-field appearance="outline">
        
        <mat-label>Unit Standards</mat-label>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let i of fc.unitStandards.value" [selectable]="true" [removable]="true"
            (removed)="removeUnitStandard(i)">
            {{i.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          
          <input formControlName='unitStandardInput'(blur)="unitStandardBlur()" placeholder="Select Unit Standards..." #unitStandardInput
            [matAutocomplete]="auto" [matChipInputFor]="chipList">
        </mat-chip-list>
        <mat-spinner *ngIf="unitStandardsLoading" class="loader-change" diameter="30"></mat-spinner>
      </mat-form-field>
     
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addUnitStandard($event)">
      
        <ng-container *ngIf="unitStandards$ | async as unitStandards">
          <ng-container *ngIf="!unitStandardsLoading">
            <mat-option *ngFor="let unitStandard of unitStandards" [value]="unitStandard">
              {{unitStandard.code}} - {{unitStandard.name}}
            </mat-option>
            <mat-option [disabled]="true" *ngIf="unitStandards?.length == 0 && !unitStandardsLoading">
              No Results Found
          </mat-option>
          </ng-container>
        </ng-container>
      </mat-autocomplete>

    </div>
    <div class="row-inner">
      <div class="mat-form-field checkbox">
        <mat-checkbox class="form-control" color="primary" formControlName="tetaAccredited">TETA Accredited
        </mat-checkbox>
      </div>
    </div>
    <div class="row-inner" *ngIf="fc.tetaAccredited.value">
      <mat-form-field appearance="outline">
        <mat-label>Restrictions</mat-label>
        <input matInput class="form-control" formControlName="restrictions">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Capacity</mat-label>
        <input matInput class="form-control" formControlName="capacity">
      </mat-form-field>
    </div>

    <button class="basic-button" (click)="updateItem();" mat-button><small>Update
        course</small></button>
  </form>

</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>