<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="spinner-loader" *ngIf="loading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <form *ngIf="!loading" [formGroup]="mainForm" class="row full-width">


        <div class="row-inner">
            <div class="id-radio mat-form-field">
                <mat-radio-group formControlName="idType">
                    <mat-radio-button [checked]="fc.idType.value == idType.SouthAfricanId"
                        [value]="idType.SouthAfricanId">
                        South African Id
                    </mat-radio-button>
                    <mat-radio-button [checked]="fc.idType.value == idType.Passport" [value]="idType.Passport">
                        Passport
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label *ngIf="fc.idType.value == idType.SouthAfricanId">ID No.</mat-label>
                <mat-label *ngIf="fc.idType.value == idType.Passport">Passport No.</mat-label>
                <input [NumberOnly]="true" matInput class="form-control" formControlName="identificationNumber">
                <mat-error *ngIf="fc.identificationNumber.errors">
                    <span *ngIf="fc.identificationNumber.errors.idNumberValid">Identification no. is not valid</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput class="form-control" formControlName="title">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Race</mat-label>
                <mat-select formControlName="race" class="form-control">
                    <mat-option [value]="race.id" *ngFor="let race of races">
                        <small>{{race.name}}</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Initials</mat-label>
                <input matInput class="form-control" formControlName="initials">
                <mat-error *ngIf="!this.loading && mainForm.controls.initials.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.initials.errors.required">Initials is
                        Required</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput class="form-control" formControlName="lastName">
                <mat-error *ngIf="!this.loading && mainForm.controls.lastName.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.lastName.errors.required">LastName is
                        required</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row-inner">

            <!--new way to get jobTitle-->
            <mat-form-field appearance="outline">
                <mat-label>Job Title</mat-label>
                <input matInput (blur)="jobTitleBlur()" matInput formControlName='jobTitleSearch' placeholder="Select Job Titles..." #jobTitleInput
                    [matAutocomplete]="auto" />
                <mat-autocomplete [displayWith]="displayTitleFn" #auto="matAutocomplete" (optionSelected)="addJobTitle($event)">
                    <ng-container *ngIf="jobTitles$ | async as jobTitles">
                        <mat-option [disabled]="true" *ngIf="jobTitles?.length == 0">
                            No Results Found
                        </mat-option>
                        <mat-option *ngFor="let i of jobTitles" [value]="i">
                            {{i.title}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>OFO Code</mat-label>
                <input matInput class="form-control" formControlName="ofoCode">
            </mat-form-field>
        </div>

        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Disability</mat-label>
                <mat-select formControlName="disability" class="form-control">
                    <mat-option [value]="disability.id" *ngFor="let disability of disabilities">
                        <small>{{disability.name}}</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Verified</mat-label>
                <mat-select formControlName="verified" class="form-control">
                    <mat-option [value]="false"><small>No</small></mat-option>
                    <mat-option [value]="true"><small>Yes</small></mat-option>
                </mat-select>
                <mat-error *ngIf="!this.loading && mainForm.controls.verified.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.verified.errors.required">Verified is
                        Required</span>
                </mat-error>
            </mat-form-field>

        </div>

        <h3>Company Structure</h3>
        <hr>

        <br>
        <div class="row-inner">

        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Client</mat-label>
                <input type="text" matInput (blur)="clientBlur()" [matAutocomplete]="client"
                    formControlName="clientSearchString">
                <mat-spinner *ngIf="clientsLoading" class="loader-change" diameter="30"></mat-spinner>
                <mat-autocomplete (optionSelected)="selectClient($event)" [displayWith]="displayClient"
                    #client="matAutocomplete">
                    <mat-option *ngFor="let client of allClients" [value]="client">
                        {{client?.title}}
                    </mat-option>
                    <mat-option [disabled]="true" *ngIf="allClients?.length == 0 && !clientsLoading">
                        No Results Found
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>


        <button class="basic-button" (click)="updateItem();" mat-button><small>Update
                student</small></button>
    </form>

</div>