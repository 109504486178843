<app-toolbar></app-toolbar>
<mat-sidenav-container>
  
  <mat-sidenav #sidenav [opened]="sideNavService.open" [mode]="sideNavService.mode">
    <mat-nav-list>
      <div *ngFor="let sideNavitem of sideNavItems">
        <h5 class="category-title">{{sideNavitem.categoryName}}</h5>
        <span *ngFor="let item of sideNavitem.items">
          <button type="button" mat-button full routerLink="{{item.link}}" [routerLinkActive]="['sidebar-active']">
            <!-- <mat-icon>{{item?.icon}}</mat-icon> -->
            <mat-icon *ngIf="!item.iconMaterial" svgIcon="{{item.icon}}" aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
            <mat-icon *ngIf="item.iconMaterial" style="color: white;"  aria-hidden="false" aria-label="Example thumbs up SVG icon">{{item.icon}}</mat-icon>
            <span class="name">{{item?.name}}</span>
          </button>
        </span>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
      <app-loader></app-loader>
    <div class="main-router-outlet" style="min-height: calc(100vh - 64px);">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>