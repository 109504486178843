import { Injectable} from '@angular/core';
import { RestService } from "./rest.service";

@Injectable({
  providedIn: 'root'
})

export class PagerService {

    pageSize : number = 5;
    pages    : number = 2;

    constructor(private restService : RestService) {}

    public async get<T>(url: string, filter: T){
        
    }

    public async search(url : string, searchTerm : string) : Promise<Array<any>> {
        return new Promise<Array<any>>(async (resolve,reject)=>{
            var results = await this.restService.get(`${url}?SearchTerm=${searchTerm}`);
            resolve(results)       
        })
    }

    public async getResults(url : string, pageNumber : number, searchTerm?: string, pageSize?:number) : Promise<Array<any>> {
        if (pageSize== undefined) {
            pageSize = this.pageSize
        }
        return new Promise<Array<any>>(async (resolve,reject)=>{
            var results = await this.restService.get(`${url}?PageSize=${pageSize}&PageNumber=${pageNumber}&Pages=${this.pages}${searchTerm ? '&SearchTerm=' + searchTerm : ''}`);
            resolve(results)            
        })
    }
    
    public async getAllResults(url : string, pageNumber : number, pageSize?:number,searchTerm?: string ) : Promise<Array<any>> {
        if (pageSize== undefined) {
            pageSize = this.pageSize
        }
        return new Promise<Array<any>>(async (resolve,reject)=>{
            var results = await this.restService.get(`${url}?PageSize=${pageSize}&PageNumber=${pageNumber}&Pages=${this.pages}${searchTerm ? '&SearchTerm=' + searchTerm : ''}`);
            resolve(results)            
        })
    }

}
