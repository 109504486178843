import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { FireUserService } from "./FireUser.service";
import { map } from "rxjs/operators";
import { SearchFilter} from "../interfaces/mto/search-filter";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  params: new HttpParams(),
  responseType: 'json' as any,
};



@Injectable({
  providedIn: "root"
})
export class RestService {
  constructor(private httpClient: HttpClient,
    private fireUserService: FireUserService) { }

  /**
   * @param data
   *
   */
  public async post(path: string, data: any, options?: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    httpOptions.responseType = options ? options.responseType : httpOptions.responseType;
    let url = this.addQueryParams(path, options);
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(`${path}`, data, httpOptions)
        .pipe(
          map( res =>{
            resolve(res)
          })
        )
        .subscribe(
          value => resolve(value),
          error => reject(error),
          () => resolve(true)
        )
    })
  }


  public async get(path: string, queryParams?: Object): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      let url = this.addQueryParams(path, queryParams);
      this.httpClient.get(url, httpOptions)
        .subscribe(
          results => {
            resolve(results)

          }
          , error => {
            reject(error);
          })
    })
  }

  public async getFile(path: string, params?: any): Promise<any> {

    httpOptions['responseType'] = null;
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(`${path}`)
        .subscribe(
          results => {
            resolve(results)

          }
          , error => {
            reject(error);
          })
    })
  }

  public async get$(path: string, queryParams?: Object) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    let url = this.addQueryParams(path, queryParams);
    return this.httpClient.get(url, httpOptions);
  }

  public async delete(path: string): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.delete(`${path}`, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`get error occured - ${error}`);
          })
    })
  }

  public async getExternal(path: string): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.get(`${path}`, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`get error occured - ${error}`);
          })
    })
  }

  public async put(path: string, body?: any): Promise<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + await this.fireUserService.getToken());
    return new Promise<any>(async (resolve, reject) => {
      this.httpClient.put(`${path}`, body, httpOptions)
        .subscribe(
          results => resolve(results)
          , error => {
            reject(`put error occured - ${error}`);
          })
    })
  }

  
  private addQueryParams(url: string, obj?: object) {
    if (obj) {
      let firstParam = !url.includes('?') ? '?' : '&';
      let params = Object.entries(obj).map(([key, val]) => { return val ? `${key}=${val}` : '' });
      let realParams = params.filter(x => x != '');
      let filterString = realParams.join('&')
      return url.concat(firstParam, filterString);
    } else {
      return url;
    }
  }
}
