import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the ActivitynamePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'activityname',
})
export class ActivitynamePipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(reference: string, ...args) {
      
      switch (reference) {
        case "Hurt Me Plenty":
            return `Medium`;

        case "I Can Win":
            return 'Very easy';

        case "Hardcore":
          return `Difficult`;

        case "Nightmare":
          return `Extreme`;
      
        case "Bring It On":
          return `Easy`;

        default:
          return reference
      }
   }
}
