import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[ngVar]'
})
export class VarDirective {
    @Input()
    set ngVar(context: any) {
        this.context.$implicit = this.context.ngVar = context;
        this.updateView();
    }

    context: any = {};

    constructor(private readonly vcRef: ViewContainerRef, private readonly templateRef: TemplateRef<any>) { }

    updateView(): void {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}