import { Component } from '@angular/core';
import { IconRegistry } from './library/utillities/icon-registry';

import { environment } from '@environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mto-training';

  env = null;

  constructor(private iconRegistry : IconRegistry){
    this.env = environment.name
    iconRegistry.registerIcons(); // register all custom icons
  }
}
