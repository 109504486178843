<mat-toolbar>
  <mat-toolbar-row class="base-toolbar-row">
    <div  class="full-width toolbar-container">
      <div class="toolbar-logo-container">
        <img src="../../../../assets/images/logo/logo.png" class="center" />
      </div>
      <div *ngIf="user" class="toolbar-right-content">
        <mat-icon>person</mat-icon>
        <span class="user-toolbar-info">{{user?.emailAddress}}</span>
        <mat-icon (click)="accountService.logout()">exit_to_app</mat-icon>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>