import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { GlobalConfig } from '../configuration/global-config';
import { User } from '../interfaces/mto/user';
import { RestService } from './rest.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { MyResponse } from '../interfaces/adventurer/myResponse';
import { GlobalStateService } from './global-state.service';

@Injectable({
  providedIn: 'root'
})
export class FireUserService {

  constructor(private fireDB: AngularFireDatabase,
    private fireAuth: AngularFireAuth) { }

  public async writeUser(uid: string, user: User) {
    this.fireDB.object(GlobalConfig.Firebase.userRef + `/${uid}`).set(user)
      .then(() => {
        //success  
      }).catch(error => {
        throw error;
      });
  }

  public async readUser(uid: string): Promise<User> {
    const userSnapshot = await this.fireDB.object(GlobalConfig.Firebase.userRef + `/${uid}`).query.once("value");
    return userSnapshot ? userSnapshot.val() : null;
  }

  public async updateAdventurer(adventurer: any) { //include only properties you want to update in object
    if (adventurer.uid) {
      await this.fireDB.object(GlobalConfig.Firebase.userRef + `/${adventurer.uid}`).update(adventurer)
        .then(() => {
          //success  
        }).catch(error => {
          throw error;
        });
    }
  }

  public async updateLoginEmailAddress(newEmail: string) {
    return this.fireAuth.currentUser.then(user => {
      user.updateEmail(newEmail).then(() => { })
        .catch(error => {
          throw error;
        })
    });
  }

  public async getToken() {
    let token: string = await this.fireAuth.currentUser.then(async user => {
      return await user.getIdToken(false);
    });
    return token;
  }
}
