import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Library } from 'src/app/library/enums/global-enum';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { IAllocationFilter } from 'src/app/library/interfaces/filters/allocation-filter';
import { RestService } from 'src/app/library/services/rest.service';
import { CertificateService } from 'src/app/library/services/certificate.service';

@Component({
  selector: 'certificates-table',
  templateUrl: './certificates-table.component.html',
  styleUrls: ['./certificates-table.component.scss']
})
export class CertificatesTableComponent implements OnInit, AfterViewInit {



  @Output() pageingEvent = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ["certificateId", "learner", "course", "acquired", "expired"];
  public dataSource: any;
  public loadingPageResults = false;
  public readonly pageSizeOptions: number[] = [10, 20, 50];
  public dataResult: PagedResult<any[]>;

  public searchText = new FormControl();
  public dateRangeGroup = new FormGroup({ start: new FormControl(null, [Validators.required]), end: new FormControl(null, [Validators.required]) });
  public expiredCertificates = new FormControl(true);

  constructor(private restService: RestService, private certificateService: CertificateService) { }

  async ngOnInit() {
    await this.loadTable();
    this.listeners();
  }

  ngAfterViewInit() {
    this.loadPaginator();
  }

  public pageChange(obj) {
    this.dataSource = new MatTableDataSource(null);
    this.pageingEvent.emit(obj)
  }

  public async downloadSummary() {
    this.dateRangeGroup.get('start').markAsDirty();
    this.dateRangeGroup.get('end').markAsDirty();
    this.dateRangeGroup.markAllAsTouched();
    if (this.dateRangeGroup.value.start && this.dateRangeGroup.value.end) {
      this.certificateService.getCertificatesSummary(moment(this.dateRangeGroup.value.start).format('YYYY/MM/DD'), moment(this.dateRangeGroup.value.end).format('YYYY/MM/DD'), this.expiredCertificates.value, Library.ReportType.EXCEL);
    }
  }

  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });

    this.expiredCertificates.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });
    this.dateRangeGroup.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(async obj => {
      if (obj.start && obj.end) {
        await this.loadTable();
      }
    });
  }

  clearStartDate() {
    this.dateRangeGroup.reset();
    this.dateRangeGroup.markAsUntouched();
    this.loadTable();
  }


  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let start = moment(this.dateRangeGroup.value.start).isValid() ? moment(this.dateRangeGroup.value.start).format('YYYY/MM/DD') : null;
    let end = moment(this.dateRangeGroup.value.end).isValid() ? moment(this.dateRangeGroup.value.end).format('YYYY/MM/DD') : null;
    let response = (await this.restService.get(`${environment.API.url}/CourseResourceAllocation/Certificates`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0],
      expired: this.expiredCertificates.value,
      fromDate: start,
      toDate: end
    } as IAllocationFilter)) as PagedResult<any[]>;
    if (response.data) {

      response.data = response.data.map(e => {
        e.expired = `${moment(e.courseResource.endDate).add(e.course.courseDetail.validFor, 'd').toDate()}`
        return e;
      });
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }
  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  get dateFC() {
    return this.dateRangeGroup.controls;
  }

}
