import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './library/modules/routing/app-routing.module';
import { AppComponent } from './app.component';
import { Components } from './library/modules/routing/app-routes';
import { Services } from './library/services/services.export';
import { Utilities } from './library/utillities/utilities.export';
import { FirebaseModule } from './library/modules/firebase/firebase.module';
import { GlobalConfig } from './library/configuration/global-config';
import { AngularFireModule } from '@angular/fire';
import { PipesModule } from './library/modules/pipes/pipes.module';
import { MaterialModule } from './library/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { HttpErrorInterceptor } from './library/services/http-error-interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import { IconRegistry } from './library/utillities/icon-registry';
import { UpdateBookingComponent } from './components/pages/admin/bookings/update-booking/update-booking.component';
import { ReportComponent } from './components/pages/admin/reports-approvals/report/report.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarCommonModule, CalendarMonthModule } from 'angular-calendar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    AppComponent,
    ...Components,
    ...Utilities
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(GlobalConfig.Firebase.firebaseEnvironment.firebase),
    FirebaseModule,
    PipesModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterModule
  ],
  exports: [RouterModule],
  
  providers: [
    ...Services,
    IconRegistry,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
