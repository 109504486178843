<div class="container">
  <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
    <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
  </ng-container>

  <div class="spinner-loader" *ngIf="loading">
    <mat-spinner class="center"></mat-spinner>
  </div>

  <div *ngIf="!loading">

    <!-- TrainingRecords -->
    <section *ngIf="reportType == '1'">
      <form [formGroup]="mainForm" class="row full-width" >
        <div class="dates">
          <div class="row full-width export-type">
              <mat-radio-group formControlName="exportType">
                <mat-radio-button [value]="reportTypeEnum.PDF" >PDF</mat-radio-button>
                <mat-radio-button [value]="reportTypeEnum.EXCEL" >Excel</mat-radio-button>
              </mat-radio-group>
          </div>
          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker"
                  formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.startDate.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.startDate?.errors?.required">Start date is
                    Required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>End Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker"
                  formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.endDate.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.endDate?.errors?.required">End date is
                    Required</span>
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <mat-form-field appearance="outline">
            <mat-label>Course</mat-label>

            <input type="text" (blur)="courseBlur()" matInput [matAutocomplete]="course" formControlName="course">
            <mat-spinner *ngIf="loadingCourses" class="loader-change" diameter="30"></mat-spinner>
            <mat-autocomplete (optionSelected)="selectCourse($event)" [displayWith]="displayFn"
              #course="matAutocomplete">
              <mat-option *ngFor="let course of courses" [value]="course">
                {{course.name}}
              </mat-option>
              <mat-option [disabled]="true" *ngIf="courses?.length == 0 && !loadingCourses">
                No Results Found
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="submitted && mainForm.controls.course?.errors">
              <span class="fw-bold small my-2 mat-error" *ngIf="mainForm.controls.course?.errors?.required">Course is
                Required</span>
            </mat-hint>
          </mat-form-field>


        </div>
      </form>
      <button class="basic-button" (click)="downloadCourseTrainingRecords()" mat-button><small>Download
          report</small></button>
    </section>

    <!-- Learner TrainingRecords -->
    <section *ngIf="reportType == '2'">
      <form [formGroup]="mainForm" class="row full-width">
        <div class="row full-width export-type">
          <mat-radio-group formControlName="exportType">
            <mat-radio-button [value]="reportTypeEnum.PDF" >PDF</mat-radio-button>
            <mat-radio-button [value]="reportTypeEnum.EXCEL" >Excel</mat-radio-button>
          </mat-radio-group>
      </div>
        <div class="dates">
          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker"
                  formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.startDate?.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.startDate?.errors?.required">Start date is
                    Required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>End Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker"
                  formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.endDate?.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.endDate?.errors?.required">End date is
                    Required</span>
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field appearance="outline">
                <mat-label>Add Client Learners</mat-label>
                <input type="text" (blur)="clientBlur()" matInput [matAutocomplete]="client"
                  formControlName="clientSearchString">
                <mat-spinner *ngIf="loadingClients" class="loader-change" diameter="30"></mat-spinner>
                <mat-autocomplete (optionSelected)="selectClient($event)" [displayWith]="displayFnClient"
                  #client="matAutocomplete">
                  <mat-option *ngFor="let client of clients" [value]="client">
                    {{client?.title}}
                  </mat-option>
                  <mat-option [disabled]="true" *ngIf="clients?.length == 0 && !loadingClients">
                    No Results Found
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Add learner</mat-label>
                <input type="text" (blur)="learnerBlur()" matInput [matAutocomplete]="course"
                  formControlName="leanerSearchString">
                <mat-spinner *ngIf="loadingLearners" class="loader-change" diameter="30"></mat-spinner>

                <mat-autocomplete (optionSelected)="addLearner($event)" [displayWith]="displayFn"
                  #course="matAutocomplete">
                  <mat-option *ngFor="let learner of learners" [value]="learner">
                    {{learner?.initials}} {{learner?.lastName}} -
                    {{learner?.identificationNumber}}
                  </mat-option>
                  <mat-option [disabled]="true" *ngIf="learners?.length == 0 && !loadingLearners">
                    No Results Found
                  </mat-option>
                </mat-autocomplete>

              </mat-form-field>
            </div>
          </div>


        </div>
      </form>
      <button class="basic-button" (click)="downloadLearnersTrainingRecords()" mat-button><small>Download
          report</small></button>
    </section>

    <!-- Bookings -->
    <section *ngIf="reportType == '3'">
      <form [formGroup]="mainForm" class="row full-width">
        <div class="dates">
          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker"
                  formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.startDate.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.startDate?.errors?.required">Start date is
                    Required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>End Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker"
                  formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls.endDate.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.endDate?.errors?.required">End date is
                    Required</span>
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
      </form>
      <button class="basic-button" (click)="downloadBookingsReport()" mat-button><small>Download report</small></button>

    </section>

    <!-- Certificates -->
    <section *ngIf="reportType == '4'">
      <form [formGroup]="mainForm" class="row full-width">
        <div class="dates">
          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker"
                  formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls?.startDate?.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.startDate?.errors?.required">Start date is
                    Required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="datepicker" appearance="outline">
                <mat-label>End Date</mat-label>
                <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker"
                  formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <mat-error *ngIf="submitted && mainForm.controls?.endDate?.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls.endDate?.errors?.required">End date is
                    Required</span>
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row full-width">
            <div class="row-inner">
              <mat-form-field appearance="outline">
                <mat-label>Course</mat-label>
                <input type="text" (blur)="courseBlur()" matInput [matAutocomplete]="course" formControlName="course">
                <mat-spinner *ngIf="loadingCourses" class="loader-change" diameter="30"></mat-spinner>
                <mat-autocomplete (optionSelected)="selectCourse($event)" [displayWith]="displayFn"
                  #course="matAutocomplete">
                  <mat-option *ngFor="let course of courses" [value]="course">
                    {{course.name}}
                  </mat-option>
                  <mat-option [disabled]="true" *ngIf="courses?.length == 0 && !loadingCourses">
                    No Results Found
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="submitted && mainForm.controls.course?.errors">
                  <span class="fw-bold small my-2" *ngIf="mainForm.controls?.course.errors?.required">Course is
                    Required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Search learner</mat-label>
                <input type="text" (blur)="learnerBlur()" matInput [matAutocomplete]="learner"
                  formControlName="leanerSearchString">
                <mat-spinner *ngIf="loadingLearners" class="loader-change" diameter="30"></mat-spinner>
                <mat-autocomplete (optionSelected)="addLearner($event)" [displayWith]="displayFn"
                  #learner="matAutocomplete">
                  <mat-option *ngFor="let learner of learners" [value]="learner">
                    {{learner?.initials}} {{learner?.firstName}} {{learner?.lastName}} -
                    {{learner?.identificationNumber}}
                  </mat-option>
                  <mat-option [disabled]="true" *ngIf="learners?.length == 0 && !loadingLearners">
                    No Results Found
                  </mat-option>
                </mat-autocomplete>

                <mat-hint *ngIf="submitted">
                  <span class="fw-bold small my-2 mat-error" *ngIf="!(this. _selectedLearners?.length > 0)">At least one
                    learner is Required</span>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <button class="basic-button" (click)="getCertificates()" mat-button><small>Download report</small></button>

    </section>

    <section *ngIf="reportType == '2' || reportType == '4'">
      <p *ngIf="dataSource?.data?.length > 0"><small>Click learner to remove them from the report</small></p>
      <p class="table-empty" *ngIf="dataSource?.data?.length == 0" align="center"><small>No learners added to the
          report</small></p>
      <ng-container *ngIf="(dataSource?.data?.length > 0)">
        <table mat-table [dataSource]="dataSource" *ngIf="dataSource" matSort class="full-width">
          <ng-container matColumnDef="noRecord">
          </ng-container>
          <ng-template [ngIf]="dataSource.data.length === 0">
            <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
          </ng-template>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> <strong>Name</strong> </th>
            <td mat-cell *matCellDef="let element"> <strong><small>{{element.name}}</small></strong> </td>
          </ng-container>
          <ng-container matColumnDef="idno">
            <th mat-header-cell *matHeaderCellDef> ID.no </th>
            <td mat-cell *matCellDef="let element"> {{element.idno}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="removeLearner(row)"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10]" [length]="dataSource?.data?.length"></mat-paginator>
      </ng-container>
    </section>

  </div>


</div>