<div class="container">
  <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
    <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
  </ng-container>

  <h4 class="standard-title">Statistics</h4>
  <h6 style="font-weight: 500;margin-top: -5px;">Overview of system resources, please click to view more information.</h6>
  <section class="standard-section">
    <div *ngFor="let item of dashItems" class="standard-card">
      <mat-icon svgIcon="{{item.icon}}" aria-hidden="false"  class="card-icon" aria-label="Example thumbs up SVG icon"></mat-icon>
      <h1 class="counter"><strong>{{item?.count}}</strong></h1>
      <h5 class="item-name"><strong>{{item?.name}}</strong></h5>
    </div>
  </section>

  <h4 class="standard-title">Sessions</h4>
  <h6 style="font-weight: 500;margin-top: -5px;">Current sessions in the system, please quick create to adda new session.-</h6>

  <button routerLink="/manage/users/create" mat-button class="create-button standard-button m-t-5">
    <mat-icon>add</mat-icon> 
    <span class="title"><strong><small>Create session</small></strong></span>
  </button>

  <table mat-table [dataSource]="dataSource" *ngIf="dataSource" matSort class="full-width">
    <ng-container matColumnDef="noRecord">
      <!-- <td [attr.colspan]="dataTable.columns.length" mat-footer-cell *matFooterCellDef>No records found.</td>  -->
    </ng-container>
    <ng-template [ngIf]="dataSource.data.length === 0">
      <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>
    <ng-container matColumnDef="uid">
      <th mat-header-cell *matHeaderCellDef> <strong>Uid</strong> </th>
      <td mat-cell *matCellDef="let element"> <strong><small>{{element.uid}}</small></strong> </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef> Modified </th>
        <td mat-cell *matCellDef="let element"> {{element.modified}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openUser(row)"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>

</div>