import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/library/services/rest.service';
import { environment } from "@environment";
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { BehaviorSubject, interval } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { PagerService } from 'src/app/library/services/paginator.service';
import { debounce, debounceTime } from 'rxjs/operators';
import { ICourseSessionCalendarFilter, IMonthYear } from "src/app/library/interfaces/filters/course-session-calendar-filter";
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
  parse,
  nextDay,
} from 'date-fns';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';
import { Library } from 'src/app/library/enums/global-enum';
import * as moment from 'moment';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})


export class SessionsComponent implements OnInit {

  //Calendar
  calendar: CalendarEvent[];
  view: CalendarView = CalendarView.Month;

  viewDate: moment.Moment = moment();
  dateHeader = moment().format("LL");
  events: CalendarEvent[] = [];
  public calendarLoading = false;
  activeDayIsOpen: boolean = false;

  cal = new BehaviorSubject<CalendarEvent[]>(null)
  //sessions
  data: Array<any>;

  //BreadCrumb
  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);

  //Components
  loading: boolean = false;

  //Data table
  public readonly pageSizeOptions: number[] = [10];
  public dataResult: PagedResult<any[]>;
  dataSource;
  displayedColumns: string[] = ['name', 'startDate', 'endDate'];
  public calendarData: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageNumber: number = 1;
  resultsLength: number
  loadingPageResults: boolean = true;
  loadingCalendar: boolean = true;
  searchText = new FormControl();
  filter: string;



  constructor(
    private restService: RestService,
    private router: Router,
    private pagerService: PagerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.createBreadCrumb();
    await this.loadTable();
    await this.loadCalendar();
    this.listeners();
  }

  public openElement(session: any) {
    this.router.navigate([`admin/sessions/edit/${session.courseResourceSessions[0].id}`]);
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['base-crumb'],
        title: 'Sessions'
      }
      ],
      heading: `SESSIONS`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }



  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/CourseResource`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0]
    } as SearchFilter)) as PagedResult<any[]>;
    if (response.data) {
      console.log(response.data);
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }

  private async loadCalendar() {
    this.calendarLoading = true;
    let response = (await this.restService.post(`${environment.API.url}/CourseResource/GetCalendar`, {
      monthYears: [{ month: this.viewDate.month() + 1, year: this.viewDate.year() }]
    } as ICourseSessionCalendarFilter));
    if (response) {
      this.calendarData = response.map(e => {
          e.start = moment(e.startDate).toDate();
          e.end = moment(e.endDate).toDate();
          e.title = `${e.name} - ${e.costRecoveryNumber ? e.costRecoveryNumber : 'KV Unknown'}`;
          return e;
      });
      this.calendarData = response;
    }
    this.calendarLoading = false;
  };




  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    if (isSameMonth(date, this.viewDate.toDate())) {
      if (
        (isSameDay(this.viewDate.toDate(), date) && this.activeDayIsOpen == true) ||
        events.length == 0
      ) {
        this.activeDayIsOpen = false;
        this.viewDate = moment(date);
        this.dateHeader = moment(date).format("LL");
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = moment(date);
        this.dateHeader = moment(date).format("LL");
      }
    }
  }


  eventClicked({ event }: { event: any }): void {
    console.log(event);
   this.router.navigate([`admin/sessions/edit/${event.courseResourceSessions[0].id}`]);
  }

  async MonthSwitch(NextorNot) {
    if (NextorNot == 1) {
      this.viewDate = this.viewDate.add(1, 'months');// new Date(this.viewDate.setMonth(this.viewDate.getMonth() + 1));
     
      this.dateHeader = this.viewDate.format("LL");
    } else if (NextorNot == 0) {
      this.viewDate =  moment(this.viewDate).subtract(1, 'months');
      this.dateHeader = this.viewDate.format("LL");
    }
 
    await this.loadCalendar();
  }

  set calget(data: CalendarEvent[]) {
    this.cal.next(data);
  }
  get calset$() {
    return this.cal.asObservable();
  }
}
