<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <h4 class="standard-title">Reports</h4>
    <section class="standard-section">
        <div *ngFor="let report of reports" [routerLink]="['/admin/reports-approvals/report',report.name,report.id]" class="standard-card">
            <mat-icon class="report-icon">{{report?.icon}}</mat-icon>
            <h4>{{report?.name}}</h4>
        </div>
    </section>

    <h4 class="standard-title">Clients</h4>
    <h6 style="font-weight: 500;margin-top: -5px;">Please open the relevant level to add clients or make changes </h6>
    <section class="standard-section">
        <div routerLink="{{'/admin/reports-approvals/clients/'+level.level}}" *ngFor="let level of inst" class="standard-card">
            <img src="../../../../../assets/images/common/university.svg" class="card-icon" alt="">
            <h4><strong>{{level.name}}</strong></h4>
        </div>
        <div class="standard-card" routerLink="/admin/reports-approvals/clients/create">
            <img src="../../../../../assets/images/common/add.svg" class="card-icon add-icon" alt="">
            <h5><strong>Add new client</strong></h5>
        </div>
    </section>
</div>