import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'learners-table',
  templateUrl: './learners-table.component.html',
  styleUrls: ['./learners-table.component.scss']
})
export class LearnersTableComponent implements OnInit {

  @Input() data$: Observable<any>;
  @Input() loading$: Observable<boolean>;
  //@Output() pageingEvent = new EventEmitter();
  @Output() removeLearnerFromBookings = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ["initials", "lastName", "identificationNumber", "remove"];
  public dataSource: any;

  public searchText = new FormControl();
  constructor() { }

  ngOnInit() {
    this.load();
  }

  /*public pageChange(obj) {
    this.dataSource = new MatTableDataSource(null);
    this.pageingEvent.emit(obj)
  }*/
  removeLearnerFromBooking(learner) {
    this.removeLearnerFromBookings.emit(learner)
  }
  private load() {
    this.data$.subscribe(data => {
      console.log(data);
      if (data) {
        this.dataSource = new MatTableDataSource(data.map(e => {
          return {
            title: e.title,
            initials: e.initials,
            lastName: e.lastName ?? "N/A",
            identificationNumber: e.identificationNumber ?? "N/A",

            //Not displayed
            createdOn: e.createdOn,
            disability: e.disability,
            employeeCode: e.employeeCode,
            gender: e.gender,
            id: e.id,
            jobTitle: e.jobTitle,
            learnerCompanyStructure: e.learnerCompanyStructure,
            learnerCompanyStructureId: e.learnerCompanyStructureId,
            race: e.race,
            status: e.status,
            verified: e.verified
          }
        }));
        /*this.dataSource = new MatTableDataSource(data.map(e=> {
          return{ 
            title: e.title,
            initials: e.initials,
            //firstName: e.FirstName??"N/A",  
            lastName: e.LastName??"N/A",  
            identificationNumber: e.identificationNumber??"N/A"
          }  
        }));*/
      } else {
        new MatTableDataSource([]);
      }
    });
  }
}
