export const USERS = [
    {
        id      : 1,
        name    : "Neo Hlapho",
        uid     : "Xvjjsus3324GGsfjjsdki2",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },
    {
        id      : 1,
        name    : "Derick Lyd",
        uid     : "UISkkasd123sdjklicujxxmcv",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },
    {
        id      : 1,
        name    : "Donio Texira",
        uid     : "gTigpMfgxrGyMi34a3PR",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },
    {
        id      : 1,
        name    : "Stephen van Romburg",
        uid     : "ZjtUrDDpYMzalGnEnnVj",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },
    {
        id      : 1,
        name    : "Peter Davis",
        uid     : "2vfs3lbTzWCupUsoSSsU",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },
    {
        id      : 1,
        name    : "Neo Hlapho",
        uid     : "Xvjjsus3324GGsfjjsdki2",
        email   : "hlaphogiven@gmail.com",
        modified: "2009-06-15T13:45:30"
    },

    {
        id      : 1,
        name    : "Peter Davis",
        uid     : "2vfs3lbTzWCupUsoSSsU",
        email   : "hlaphogiven@gmail.com",
        modified: "2010-06-15T13:45:30"
    }
]