import { AccountService } from "./account.service";
import { AuthenticationService } from "./authentication.service";
import { DatabaseService } from "./database.service";
import { GlobalStateService } from "./global-state.service";
import { FireUserService } from "./FireUser.service";
import { UialertsService } from "./uialerts.service";
import { SideNavService } from "./helpers/side-nav.service";
import { APP_INITIALIZER } from '@angular/core';
import { RestService } from './rest.service';
import { LoadingService } from './loader/loading.service';
import { LoaderService } from './loader/loader.service copy';
import { PagerService } from './paginator.service';
import { CertificateService } from './certificate.service';


export const Services = [
    { provide: APP_INITIALIZER, multi: true, deps: [AuthenticationService], useFactory: (AuthenticationService) => () => null },
    AccountService,
    AuthenticationService,
    DatabaseService,
    UialertsService,
    GlobalStateService,
    FireUserService,
    SideNavService,
    RestService,
    LoadingService,
    LoaderService,
    PagerService,
    CertificateService
]