import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

import { USERS } from "src/test/static-data/users";
import { PagedResult} from "src/app/library/interfaces/filters/paged-result";
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { Library } from 'src/app/library/enums/global-enum';
import { FormControl } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';
import { PagerService } from 'src/app/library/services/paginator.service';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly pageSizeOptions: number[] = [10, 20, 50];
  public dataResult: PagedResult<any[]>;
  displayedColumns: string[] = ['title', 'name', 'gender', 'identificationNumber', 'verified'];
  loading: boolean = false;
  dataSource;
  

  pageNumber: number = 1;
  resultsLength: number
  loadingPageResults: boolean = false;
  searchText = new FormControl();

  constructor(
    private router: Router,
    private restService: RestService,
    private pagerService: PagerService
  ) {

  }

  async ngOnInit() {
    this.createBreadCrumb();
    await this.loadTable();
    this.listeners();
  }

  ngAfterViewInit() {
    this.loadPaginator();
  }


  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/Learner`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0]
    } as SearchFilter)) as PagedResult<any[]>;
    if (response.data) {
      response.data = response.data.map(e => { e.gender = Library.genderDecode(e.gender); return e;});
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'People'
      }, {
        classes: ['base-crumb'],
        title: 'Students'
      }
      ],
      heading: `STUDENTS`
    }
  }

  public openUser(student: any) {
    this.router.navigate([`people/students/edit/${student.id}`])
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }
}
