<div class="container">
  <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
      <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
  </ng-container>

  <div class="spinner-loader" *ngIf="loading">
    <mat-spinner class="center" ></mat-spinner>
  </div>

  <form *ngIf="!loading" [formGroup]="roleForm" class="row full-width">
      <div class="row-inner">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput class="form-control" formControlName="name">
          <mat-error *ngIf="!this.loading && roleForm.controls.name.errors">
            <span class="fw-bold small my-2" *ngIf="roleForm.controls.name.errors.required">Name is required</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Permissions</mat-label>
          <mat-select formControlName="permissions" class="form-control" multiple>
            <mat-option [value]="permission.id" *ngFor="let permission of permissions">
              <small>{{permission.name}}</small>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!this.loading && roleForm.controls.permissions.errors">
            <span class="fw-bold small my-2" *ngIf="roleForm.controls.permissions.errors.required">Permissions is required</span>
        </mat-error>
        </mat-form-field>
        
      </div>
      <button class="basic-button" (click)="createItem();" mat-button><small>Create Role</small></button>
  </form>    


</div>
