import { Component, OnInit } from "@angular/core";
import { RestService } from "src/app/library/services/rest.service";
import { environment } from "@environment";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BreadCrumb } from "src/app/library/interfaces/components/bread-crumb/bread-crumb";
import { BehaviorSubject, interval } from "rxjs";
import { GLOBAL_CONSTS } from "src/app/library/global-constants/global-constants";
import { UialertsService } from "src/app/library/services/uialerts.service";
import { debounce } from "rxjs/operators";
import { PagerService } from "src/app/library/services/paginator.service";
import * as moment from "moment";
import { SearchFilter } from "src/app/library/interfaces/mto/search-filter";


@Component({
  selector: 'app-update-booking',
  templateUrl: './update-booking.component.html',
  styleUrls: ['./update-booking.component.scss']
})
export class UpdateBookingComponent implements OnInit {

  private _learnerAllocations = new BehaviorSubject(null);
  private _learnerAllocationsLoading = new BehaviorSubject<boolean>(true);

  //constants
  locations: Array<any> = GLOBAL_CONSTS.Locations;
  coursePassSymbol: any = GLOBAL_CONSTS.CoursePassSymbol;
  loading: boolean = false;

  //bookings
  bookingForm: FormGroup;
  booking: any;
  SearchFilter: SearchFilter;
  learnersLoading: boolean = false;


  //New Booking Learner information 
  learner: any;
  outcomeMark: any;
  symbolMark: any;

  //booking learners

  componentLoading: boolean = false;

  displayBookingLearners: Array<any> = [];

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alert: UialertsService,
    private rest: RestService,
    private pagerService: PagerService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.componentLoading = true;

    await this.loadbooking();
    this.loadAllLearners();
    this.createBookingUpdateForm();
    this.listenForSearchChange();
    this.createBreadCrumb();

    this.componentLoading = false;
  }

  learners: Array<any>;
  selectedLearners: Array<any> = [];
  selectedLearnerOption: any;
  filteredLeaners: Array<any>;


  private async loadAllLearners() {
    try {
      this.learners = await this.restService.get(`${environment.API.url}/Learner`);
      this.filteredLeaners = this.learners;
      Promise.resolve();
    } catch (error) {
      console.error(error);
    }
  }

  removeLearnerFromBookings(learner): void {
    const deleteIndex = (this.booking.bookingLearners as Array<any>).findIndex(l => l.learnerIdentificationNumber == learner.identificationNumber);

    this.booking.bookingLearners[deleteIndex].status = 3;
    (this.booking.bookingLearners as Array<any>).splice((this.booking.bookingLearners as Array<any>).findIndex(l => l.learnerIdentificationNumber == learner.identificationNumber), 1);

    this._learnerAllocations.next(this.booking);

  }

  public removeLearner(learner): void {
    const removeIndex = this.selectedLearners.findIndex(x => x.id == learner.id);
    this.selectedLearners.splice(removeIndex, 1);
  }

  public filterLearners(event: string): void {
    if (event && typeof event !== 'object') {
      this.filteredLeaners = this.learners.filter(x => (((x.lastName + x.firstName + x.identificationNumber) as string).toUpperCase()).indexOf(event.toUpperCase()) >= 0);
    } else {
      this.filteredLeaners = this.learners;
    }
  }

  newLearnerPassSymbol;
  newLearnerPassMark;

  public addLeaners(learner: any): void {
    if (!this.selectedLearners.find(selected => selected.id == learner.option.value.id)) {
      this.selectedLearners = [learner.option.value];
    }

  }

  public addLearner(): void {

    const newLearner = {
      "learnerFirstName": this.selectedLearners[0].firstName,
      "learnerLastName": this.selectedLearners[0].lastName,
      "learnerIdentificationNumber": this.selectedLearners[0].identificationNumber,
      "learnerId": this.selectedLearners[0].id,
      "bookingId": this.booking.id,
      "courseResourceAllocations": [
        {
          "courseResourceId": this.booking.bookingLearners[0].courseResourceAllocations[0].courseResourceId,
          "outcomeSymbol": this.newLearnerPassSymbol,
          "outcomeMark": this.newLearnerPassMark
        }
      ]
    };


    this.booking.bookingLearners.push(newLearner);
    this._learnerAllocations.next(this.booking);

    this.selectedLearners = [];
    this.newLearnerPassSymbol = null;
    this.newLearnerPassMark = null;


    this.searchTextLearner.setValue(null);
  }

  updateMarkSymbol(learner) {
    this.booking.bookingLearners[(this.booking.bookingLearners as Array<any>).findIndex(_learner => _learner.id == learner.id)] = learner;
    this._learnerAllocations = new BehaviorSubject(this.booking);
    let test;
    this._learnerAllocations.subscribe(data => test = data);
  }

  public changeMark(data, learner): void {
    learner['courseResourceAllocations'][0]['outcomeMark'] = Number(data);
    this.booking.bookingLearners[(this.booking.bookingLearners as Array<any>).findIndex(_learner => _learner.id == learner.id)] = learner;
  }

  public changeSymbol(data, learner): void {
    learner['courseResourceAllocations'][0]['outcomeSymbol'] = data.value;
    this.booking.bookingLearners[(this.booking.bookingLearners as Array<any>).findIndex(_learner => _learner.id == learner.id)] = learner;
  }

  public removeLearnerFromBooking(learner: any): void {
    const deleteIndex = (this.booking.bookingLearners as Array<any>).findIndex(l => l.id == learner.id);

    this.booking.bookingLearners[deleteIndex].status = 3;
    (this.booking.bookingLearners as Array<any>).splice((this.booking.bookingLearners as Array<any>).findIndex(l => l.id == learner.id), 1);
  }

  private async loadbooking(): Promise<void> {
    try {

      this._learnerAllocations.next(null);
      this._learnerAllocationsLoading.next(true);
      this.booking = await this.restService.get(`${environment.API.url}/booking/getById?id=${this.route.snapshot.paramMap.get('id')}`);
      this._learnerAllocations.next(this.booking);
      this._learnerAllocationsLoading.next(false);
      this.displayBookingLearners = this.booking.bookingLearners;
    } catch (error) {
      console.error(error);
    }
  }

  private createBookingUpdateForm(): void {
    this.bookingForm = this.formBuilder.group({
      location: new FormControl(this.booking.location, [Validators.required]),
      requestor: new FormControl(this.booking.requestor),
      requestorEmail: new FormControl(this.booking.requestorEmail),
      contactName: new FormControl(this.booking.contactName),
      contactNumber: new FormControl(this.booking.contactNumber),
      contactEmail: new FormControl(this.booking.contactEmail),
      bookingName: new FormControl(this.booking.bookingName, [Validators.required]),
      courseName: new FormControl({ value: this.booking.bookingCourses[0]?.name, disabled: true }),
    });
  }

  public async downloadCertificates() {
    window.open(`${environment.API.url}/report/booking/${this.booking.id}/certificates`, '_blank');
  }

  public async downloadTrainingRecords() {
    window.open(`${environment.API.url}/report/booking/${this.booking.id}/trainingrecords`, '_blank');
  }


  public displayFn(item: any): string {
    if (item) {
      return `${item.title ?? "" } ${item.initials ?? "" } ${item.lastName ?? ""}`
    }
  }

  public async updateBooking() {
    try {
      this.loading = true;
      if (this.bookingForm.valid) {
        this.componentLoading = true;
        await this.restService.post(`${environment.API.url}/Booking/Update`, {
          id: this.booking.id,
          "location": this.bookingForm.get("location").value,
          "requestor": this.bookingForm.get("requestor").value,
          "requestorEmail": this.bookingForm.get("requestorEmail").value,
          "contactName": this.bookingForm.get("contactName").value,
          "contactNumber": this.bookingForm.get("contactNumber").value,
          "contactEmail": this.bookingForm.get("contactEmail").value,
          "bookingName": this.bookingForm.get("bookingName").value,
          "bookingLearners": this.booking.bookingLearners
        });

        this.componentLoading = false;
        this.router.navigate(['admin/bookings/edit/' + this.booking.id]);
        this.alert.openSnackBar({ duration: 5, message: "Booking updated successfully", mode: "success" });
      }
    } catch (error) {
      this.componentLoading = false;
      console.log(error);
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
    }
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['routable-crumb'],
        title: 'Booking',
        link: ['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Update'
      },
      {
        classes: ['active-crumb'],
        title: this.booking.bookingName
      }
      ],
      heading: "BOOKING UPDATE - " + this.booking.bookingName
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

  searchTextLearner = new FormControl();

  private listenForSearchChange(): void {
    this.searchTextLearner.valueChanges.pipe(
      debounce(() => interval(100)),
    ).subscribe(async searchText => {
      this.filteredLeaners = [];
      this.learnersLoading = true;
      this.filteredLeaners = (await this.rest.get(`${environment.API.url}/Learner`, {
        searchTerm: searchText,
        pageNumber: 1,
        pageSize: 5
      } as SearchFilter).finally(() => {
        this.learnersLoading = false;
      })).data;

    });
  }

  public learnerBlur() {
    if(!this.searchTextLearner.value?.id){
      this.searchTextLearner.setValue("", { emitEvent: false });
      this.selectedLearners = [];
    }
  }

  //LEARNERTABLE

  get data$() {
    return this._learnerAllocations.asObservable();
  }

  get learnerAllocationsLoading$() {
    return this._learnerAllocationsLoading.asObservable();
  }
}