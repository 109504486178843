import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

import { USERS } from "src/test/static-data/users";

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {


  displayedColumns: string[] = ['name', 'permissions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = true;
  dataSource;
  
  constructor(
    private router : Router,
    private restService : RestService
    ) {
 
   }

  ngOnInit(): void {
    this.createBreadCrumb();
    this.loadUsers();

    

      //this.createUpdateTable(USERS);
  }
  
  private async loadUsers() { 
    try {
      const roles = await this.restService.get(`${environment.API.url}/Role`);
      this.createUpdateTable(roles);

      this.loading = false;
    } catch (error) {
      
    }
  }


  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['base-crumb'],
        title: 'Roles'
      }
    ],
      heading: `ROLES`
    }
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openElement(role : any) {
    this.router.navigate([`manage/roles/edit/${role.id}`])
  }

  private createUpdateTable(source : Array<any>) : void {

    const mappedSource = source.map((element) =>{
      return {
        id          : element.id,
        name        : element.name,
        permissions : (element.rolePermissions as Array<any>).length,
      }
    })

    this.dataSource = new MatTableDataSource(mappedSource);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
