import { Injectable, Component, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class IconRegistry {

    icons = [
       {
        name : 'dashboard',
        url  : 'assets/images/common/dashboard.svg'
       },
       {
        name : 'bookings',
        url  : 'assets/images/common/bookings.svg'
       },
       {
        name : 'courses',
        url  : 'assets/images/common/courses.svg'
       },
       {
        name : 'facilitators',
        url  : 'assets/images/common/facilitators.svg'
       },
       {
        name : 'requests',
        url  : 'assets/images/common/requests.svg'
       },
       {
        name : 'students',
        url  : 'assets/images/common/students.svg'
       },
       {
        name : 'checklist',
        url  : 'assets/images/common/check-list.svg'
       },
       {
        name : 'group',
        url  : 'assets/images/common/group.svg'
       } 
    ]

    constructor(private sanitizer : DomSanitizer, private iconRegistry : MatIconRegistry ){ 
        console.log("WE ARE HERE");
        
    }

    public registerIcons() : void { 
        for (const icon of this.icons) {
            this.iconRegistry.addSvgIcon(
                icon.name,
                this.sanitizer.bypassSecurityTrustResourceUrl(icon.url));
        }

        console.log("icons registered");
        
    }
}
