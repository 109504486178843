import { Component, NgZone, OnInit } from '@angular/core';
import { AccountService } from '../../../library/services/account.service';
import { GlobalStateService } from '../../../library/services/global-state.service';
import { SideNavService } from '../../../library/services/helpers/side-nav.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  user;

  constructor(public sideNavService: SideNavService, public stateService: GlobalStateService, public accountService: AccountService, private state : GlobalStateService, private ngZone : NgZone) { }

  ngOnInit() {
    this.state.user$
    .subscribe((user)=>{
      this.ngZone.run(()=>{
        this.user = user;
      })
    })
  }

}
