import { NgModule } from '@angular/core';
import { IconsTransformPipe } from './icons-transform/icons-transform';
import { CommonModule, DatePipe } from "@angular/common";
import { ActivitynamePipe } from './activityname/activityname';
import { StandardDatePipe } from './standard-date/standard-date';
import { StripHtmlPipe } from './stripHtml';
import { SanitizeUrl } from './sanitizeUrl/sanitizeUrl';
import { SeoFriendlyStringPipe } from './seo-friendly-string.pipe';
import { CourseCategoryFriendlyPipe} from './enum-to-friendly/course-category-friendly.pipe';

@NgModule({
   declarations: [
      ActivitynamePipe,
      StandardDatePipe,
      SanitizeUrl,
      StripHtmlPipe,
      SeoFriendlyStringPipe,
      CourseCategoryFriendlyPipe
   ],
   imports: [
      CommonModule
   ],
   providers: [
      StandardDatePipe,
      SeoFriendlyStringPipe,
      DatePipe
   ],
   exports: [
      ActivitynamePipe,
      StandardDatePipe,
      SanitizeUrl,
      StripHtmlPipe,
      SeoFriendlyStringPipe,
      CourseCategoryFriendlyPipe
   ]
})
export class PipesModule { }
