<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div>
        <div class="row full-width">
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Search facilitators</mat-label>
                    <input [formControl]='searchText' matInput class="form-control">
                </mat-form-field>
                <button routerLink="/people/facilitators/create" mat-button
                    class="create-button standard-button full-width m-t-5">
                    <mat-icon>add</mat-icon>
                    <span class="title"><strong><small>Create facilitator</small></strong></span>
                </button>
            </div>
        </div>


        <div class="table-contianer">
            <h6 style="opacity: 0.6;">Please select a facilitator to modify their details. </h6>
            <table mat-table [dataSource]="dataSource" matSort class="full-width">
                <ng-container matColumnDef="noRecord">
                    <!-- <td [attr.colspan]="dataTable.columns.length" mat-footer-cell *matFooterCellDef>No records found.</td>  -->
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> <strong>Initias</strong> </th>
                    <td mat-cell *matCellDef="let element"> <strong><small>{{element.title}}</small></strong> </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openItem(row)"></tr>

                <tr class="mat-row" *matNoDataRow [ngClass]="{hidden: loadingPageResults != false}">
                    <td class="mat-cell text-center" colspan="999">No Results Found
                    </td>
                </tr>
            </table>
            <ng-container *ngIf="loadingPageResults">
                <div class='table-loader'>
                    <mat-spinner class='center'></mat-spinner>
                </div>
            </ng-container>
            <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="dataResult?.collectionSize"></mat-paginator>
        </div>



    </div>
</div>