<div class="container">
  <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
      <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
  </ng-container>

  <div class="spinner-loader" *ngIf="loading">
    <mat-spinner class="center" ></mat-spinner>
  </div>

  <form *ngIf="!loading" [formGroup]="userForm" class="row full-width">
      <div class="row-inner">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput class="form-control" formControlName="firstName">
          <mat-error *ngIf="!loading && userForm.controls.firstName.errors">
            <span class="fw-bold small my-2" *ngIf="userForm.controls.firstName.errors.required">Firstname is Required</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput class="form-control" formControlName="lastName">
          <mat-error *ngIf="!loading && userForm.controls.lastName.errors">
            <span class="fw-bold small my-2" *ngIf="userForm.controls.lastName.errors.required">Lastname is Required</span>
          </mat-error>
        </mat-form-field>

      </div>
      <div class="row-inner">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input  matInput class="form-control" formControlName="emailAddress">
          <mat-error *ngIf="!loading && userForm.controls.emailAddress.errors">
            <span class="fw-bold small my-2" *ngIf="userForm.controls.emailAddress.errors.required">Email address is Required</span>
          </mat-error>
        </mat-form-field>

          <mat-form-field appearance="outline">
              <mat-label>Role</mat-label>
              <mat-select formControlName="roles" class="form-control" multiple>
                <mat-option [value]="role.id" *ngFor="let role of roles">
                  <small>{{role.name}}</small>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!loading && userForm.controls.roles.errors">
                <span class="fw-bold small my-2" *ngIf="userForm.controls.roles.errors.required">Role is required</span>
              </mat-error>
            </mat-form-field>
      </div>
      <button class="basic-button" (click)="updateUser();" mat-button><small>Update User</small></button>

  </form>    

</div>
