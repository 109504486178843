import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';

// import { DeleteDialogComponent } from "./delete-dialog/dialog.component";
import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { listChanges } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';


@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {

  role : any;
  permissions : Array<any>;


  constructor(
    public dialog       : MatDialog,
    private rest        : RestService,
    private route       : ActivatedRoute,
    private formBuilder : FormBuilder,
    private alert       : UialertsService,
    private router      : Router
    ) { }

  roleForm   : FormGroup;



  loading : boolean = true;

  async ngOnInit() {

    let uid = this.route.snapshot.paramMap.get('id');

    await this.loadRole(uid);
    this.loadPermissions();
    this.createroleForm();
    this.createBreadCrumb()

    this.loading = false;
  }

  private async loadPermissions() { 
    try {
      this.permissions = await this.rest.get(`${environment.API.url}/Permission`);     
    } catch (error) { 
      console.error(error);
    }
  }

  private createroleForm() : void {
    this.roleForm = this.formBuilder.group({
      name: new FormControl(this.role.name, [Validators.required]),
      permissions: new FormControl((this.role.rolePermissions as Array<any>).reduce((p,c) => { 
        p.push(c.permission.id);
        return p;
      },[]), Validators.required),
    });
  }

  public async updateItem() { 

    try {
      this.loading = true;
      if(this.roleForm.valid){
      await this.rest.post(`${environment.API.url}/Role/Update`,{
        "id"          : this.role.id,
        "name"        : this.roleForm.get("name").value,
        "permissionIds"   : this.roleForm.get("permissions").value,
      })

      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: "Role updated", mode: "success" });

      this.router.navigate(['manage/roles']);
    }
    this.loading = false;
    } catch (error) {
      this.loading = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  private async loadRole(id : string) {
    return new Promise<any>(async (resolve,reject)=>{
      this.role = await this.rest.get(`${environment.API.url}/Role/GetById?id=${id}`);
      resolve(this.role);
    })
  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['routable-crumb'],
        title: 'Roles',
        link:['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
    ],
      heading: "ROLE - " + this.role.name
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
