<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="spinner-loader" *ngIf="componentLoading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <div *ngIf="!componentLoading">
        <form [formGroup]="bookingForm" class="row full-width">
            <h4 class="add-new" align="center">Change booking name</h4>
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Booking Name</mat-label>
                    <input matInput class="form-control" formControlName="bookingName">
                    <mat-error *ngIf="loading && bookingForm.controls.bookingName.errors">
                        <span class="fw-bold small my-2"
                            *ngIf="bookingForm.controls.bookingName.errors.required">Booking name is required</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Course</mat-label>
                    <input matInput class="form-control" formControlName="courseName">
                </mat-form-field>
            </div>

            <h4 class="add-new" align="center">Add New Learner</h4>

            <mat-list class="booked_list" role="list">
                <mat-list-item role="listitem">
                    <div class="row-inner learner-name">
                        <mat-form-field appearance="outline" class="learners_input">
                            <mat-label>Search Learner</mat-label>
                            <input type="text" (blur)="learnerBlur()" [formControl]="searchTextLearner" matInput
                                [matAutocomplete]="auto">
                            <mat-spinner *ngIf="learnersLoading" class="loader-change" diameter="30"></mat-spinner>
                            <mat-autocomplete  [displayWith]="displayFn" #auto="matAutocomplete"
                                (optionSelected)="addLeaners($event)">
                                <mat-option *ngFor="let learner of filteredLeaners" [value]="learner">
                                    {{learner.initials}} {{learner.lastName}}
                                    - {{learner.identificationNumber}}
                                </mat-option>
                                <mat-option [disabled]="true" *ngIf="filteredLeaners?.length == 0 && !learnersLoading">
                                    No Results Found
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>Pass Mark</mat-label>
                        <input matInput placeholder="0" type="number" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="newLearnerPassMark">
                    </mat-form-field>


                    <mat-form-field appearance="outline">
                        <mat-label>Pass Symbol</mat-label>
                        <mat-select class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="newLearnerPassSymbol">
                            <mat-option [value]="symbol.id" *ngFor="let symbol of coursePassSymbol">
                                <small>{{symbol.name}}</small>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div (click)="addLearner();" class="delete-user" mat-icon-button
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon style="color: green;">add</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>

            <h4 align="center">Learners in booking</h4>

            <learner-table [data$]="data$" [loading$]=learnerAllocationsLoading$
                (removeLearnerFromBookings)=removeLearnerFromBookings($event)
                (setLearnerSymbol)=updateMarkSymbol($event) (setLearnerMark)=updateMarkSymbol($event)>
            </learner-table>

            <button class="basic-button" (click)="updateBooking();" mat-button><small>Update booking</small></button>
            <button class="basic-button " (click)="downloadCertificates();" type="button" mat-button>Download
                Certificates</button>
            <button class="basic-button " (click)="downloadTrainingRecords();" type="button" mat-button>Download
                Training records</button>

        </form>
    </div>


</div>