import { User } from '../../interfaces/mto/user';


export function ParseFireUser(fireUser: firebase.User): User {
    let name = splitNames(fireUser.displayName);
    return {
        firstName: name.firstName,
        lastName: name.lastName,
        emailAddress: fireUser.email,
        uid: fireUser.uid,
        photoURL: fireUser.photoURL,
        cellphoneNumber: fireUser.phoneNumber,
    };
}

function splitNames(displayName: string): { firstName: string, lastName?: string } {
    if (!displayName) {
        return { firstName: "", lastName: "" };
    }
    let splitNames = displayName.split(" ");
    let userNames = { firstName: null, lastName: null };

    if (splitNames.length == 1) {
        return { firstName: displayName }
    } else {
        userNames.firstName = splitNames[0];
        userNames.lastName = splitNames[splitNames.length - 1];
    }

    return userNames;
}