import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';

import * as moment from 'moment';
import { debounce } from 'rxjs/operators';
import { PagerService } from 'src/app/library/services/paginator.service';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';


@Component({
  selector: 'app-create-session',
  templateUrl: './create-session.component.html',
  styleUrls: ['./create-session.component.scss']
})
export class CreateSessionComponent implements OnInit {

  student: any;
  roles: Array<any>;
  mainForm: FormGroup;
  sessionForm: FormGroup;
  loading: boolean = true;
  submitted: boolean = false;
  datesValid: boolean = false;

  searchText = new FormControl();
  searchFacilitator = new FormControl();
  public coursesLoading: boolean;
  public facilitatorLoading: boolean;



  races: any = GLOBAL_CONSTS.races;
  companyGroups: any = GLOBAL_CONSTS.CompanyGroup;
  companies: any = GLOBAL_CONSTS.Company;
  disabilities: any = GLOBAL_CONSTS.Disabilities;

  courses: Array<any>;

  allCourseResourceAllocation: any;
  facilitators: any;

  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: UialertsService,
    private pagerService: PagerService
  ) { }

  async ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');
    // await this.loadAllCourses();
    this.listenForSearchChange();
    this.createSessionForm();
    this.createBreadCrumb();

    this.loading = false;
  }

  private listenForSearchChange(): void {
    this.searchText.valueChanges.pipe(
      debounce(() => interval(200)),
    ).subscribe(async searchText => {
      if (searchText != '') {
        this.coursesLoading = true;
        this.courses = [];
        this.courses = (await this.rest.get(`${environment.API.url}/Course`, { searchTerm: searchText, pageSize: 5 } as SearchFilter)
          .finally(() => {
            this.coursesLoading = false;
          })).data;
      }
    });

    this.searchFacilitator.valueChanges.pipe(
      debounce(() => interval(200)),
    ).subscribe(async searchText => {
      if (searchText != '') {
        this.facilitatorLoading = true;
        this.facilitators = [];
        this.facilitators = (await this.rest.get(`${environment.API.url}/facilitator`, { searchTerm: searchText, pageSize: 5 } as SearchFilter)
          .finally(() => {
            this.facilitatorLoading = false;
          })).data;
      }
    });
  }

  public courseBlur() {
    if (!this.searchText.value.id) {
      this.searchText.setValue("", { emitEvent: false });
    }

  }
  public facilitatorBlur() {
    if (!this.searchFacilitator.value.id) {
      this.searchFacilitator.setValue("", { emitEvent: false });
    }

  }

  private createSessionForm(): void {
    this.sessionForm = this.formBuilder.group({
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", Validators.required),
      internal: new FormControl("", Validators.required),
      facilitatorId: new FormControl("", Validators.required),
      costRecoveryNumber: new FormControl(""),
      sessionNumber: new FormControl("", Validators.required),
      sessionDuration: new FormControl({value: 0, disabled: true}, Validators.required),
      course: new FormControl("", Validators.required)
    });

    this.sessionForm.controls.startDate.valueChanges.subscribe(data => {
      let temp = moment(this.sessionForm.controls.endDate.value).diff(moment(data), 'days');
      if(temp < 0 || isNaN(temp)){
        this.sessionForm.controls.sessionDuration.setValue(0);
      }else{
        this.sessionForm.controls.sessionDuration.setValue(temp + 1);
      }
    });

    this.sessionForm.controls.endDate.valueChanges.subscribe(data => {
      let temp = moment(data).diff(moment(this.sessionForm.controls.startDate.value), 'days');
      if(temp < 0 || isNaN(temp)){
        this.sessionForm.controls.sessionDuration.setValue(0);
      }else{
        this.sessionForm.controls.sessionDuration.setValue(temp + 1);
      }
    });

  }

  public displayFnCourse(item: any): string {
    if (item) {
      if (item.firstName || item.initials || item.lastName) {
        return `${item.initials} ${item.firstName} ${item.lastName}`
      } else {
        return item && item.name ? item.name : '';
      }
    }
  }

  public displayFnFacilitator(item: any): string {
    if (item) {
      return `${item.title ?? ""} ${item.initials ?? ""} ${item.lastName ?? ""}`;
    }
  }

  public async createSession() {
    try {
      this.sessionForm.markAllAsTouched();
      this.submitted = true;
      if (this.sessionForm.controls["startDate"].value <= this.sessionForm.controls["endDate"].value) {
        this.datesValid = true;
      }
      if (this.sessionForm.valid && this.datesValid) {
        this.loading = true;
        let courseResource = await this.saveCourseResource();

        await this.rest.post(`${environment.API.url}/CourseResourceSession/Create`, {
          "startDate": moment(this.sessionForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "endDate": moment(this.sessionForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "internal": this.sessionForm.get("internal").value,
          "courseResourceId": courseResource.id,
          "facilitatorId": this.sessionForm.get("facilitatorId").value,
          sessionNumber: this.sessionForm.get('sessionNumber').value,
          sessionDuration: this.sessionForm.get('sessionDuration').value
        })

        this.loading = false;
        this.router.navigate(['admin/sessions']);
        this.alert.openSnackBar({ duration: 5, message: "Session created", mode: "success" });
      }
    } catch (error) {
      this.loading = false;
      this.submitted = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }

  public selectCourse(event: any) {
    this.sessionForm.controls['course'].setValue(event.option.value);
  }

  public selectFacilitator(event: any) {
    this.sessionForm.controls['facilitatorId'].setValue((event.option.value).id);
  }

  public async saveCourseResource(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let courseResource = await this.rest.post(`${environment.API.url}/CourseResource/Create`, {
          "startDate": moment(this.sessionForm.get("startDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "endDate": moment(this.sessionForm.get("endDate").value).format('YYYY-MM-DD[T]HH:mm'),
          "maxCapacity": -1,
          "costRecoveryNumber": this.sessionForm.get("costRecoveryNumber").value,
          "courseId": (this.sessionForm.get("course").value).id,
          "status": 2,
          "duration": this.sessionForm.get("sessionDuration").value
        });

        resolve(courseResource);


      } catch (error) {
        this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
        console.log(error);
        reject(error);
      }
    });
  }

  createResource: boolean = false;
  selectedCourseResource: any;

  public createNewResource(): void {
    this.createResource = !this.createResource;

    if (this.createResource) {
      this.selectedCourseResource = null;
    }
  }
  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['routable-crumb'],
        title: 'Session',
        link: ['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
      ],
      heading: "CREATE SESSION"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}