<div class="container">
    <div class="spinner-loader" *ngIf="loading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <div *ngIf="!loading">
        <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
            <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
        </ng-container>
        <form [formGroup]="sessionForm" class="row full-width">
            <div class="dates">
                <div class="row full-width">
                    <div class="row-inner">
                        <mat-form-field class="datepicker" appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="fromDatePicker" formControlName="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromDatePicker></mat-datepicker>
                            <mat-error *ngIf="!loading && sessionForm.controls.startDate.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.startDate.errors.required">Start Date is Required</span>
                              </mat-error>
                        </mat-form-field>

                        <mat-form-field class="datepicker" appearance="outline">
                            <mat-label>End Date</mat-label>
                            <input placeholder="From Date" readonly class="form-control" matInput [matDatepicker]="toDatePicker" formControlName="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #toDatePicker></mat-datepicker>
                            <mat-error *ngIf="!loading && sessionForm.controls.endDate.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.endDate.errors.required">End Date is Required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Availabiliy</mat-label>
                            <mat-select formControlName="internal" class="form-control">
                                <mat-option [value]="false"><small>External</small></mat-option>
                                <mat-option [value]="true"><small>Internal</small></mat-option>
                            </mat-select>
                            <mat-error *ngIf="!loading && sessionForm.controls.internal.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.internal.errors.required">Availabiliy is Required</span>
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                            <mat-label>Facilitator</mat-label>
                            <mat-spinner *ngIf="facilitatorLoading" class="loader-change" diameter="30"></mat-spinner>
                            <input type="text" matInput [matAutocomplete]="facilitator" (blur)="facilitatorBlur()"  [formControl]="searchFacilitator">
                            <mat-autocomplete [displayWith]="displayFnFacilitator"  (optionSelected)="selectFacilitator($event)" #facilitator="matAutocomplete">
                                <mat-option *ngFor="let facilitator of facilitators" [value]="facilitator">
                                    {{facilitator.title}} {{facilitator.initials}} {{facilitator.lastName}}
                                </mat-option>
                                <mat-option [disabled]="true" *ngIf="facilitators?.length == 0 && !facilitatorLoading">
                                    No Results Found
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="!loading && sessionForm.controls.facilitatorId.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.facilitatorId.errors.required">Facilitator is Required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Cost Recovery Number</mat-label>
                            <input matInput class="form-control" formControlName="costRecoveryNumber">
                            <mat-error *ngIf="!loading && sessionForm.controls.costRecoveryNumber.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.costRecoveryNumber.errors.required">Cost Recovery Number is Required</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Duration (Days)</mat-label>
                            <input type="number" matInput type="string" class="form-control" formControlName="sessionDuration">
                        </mat-form-field>
                    </div>
                    <div class="row-inner">
                        <mat-form-field appearance="outline">
                            <mat-label>Session No.</mat-label>
                            <input type="number" matInput type="string" class="form-control" formControlName="sessionNumber">
                            <mat-error *ngIf="!loading && sessionForm.controls.sessionNumber.errors">
                                <span class="fw-bold small my-2" *ngIf="sessionForm.controls.sessionNumber.errors.required">Session No. is Required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <button class="basic-button" (click)="updateSession();" mat-button><small>Update Session</small></button>

            </div>
        </form>
    </div>


</div>