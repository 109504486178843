<div class="container component-wrapper full-height">
  <div class="logo-container">
    <img src="../../../../assets/images/logo/mto-logo-text.svg" alt="">
  </div>
  <div class="form-container">

    <form  [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <div [hidden]="loading">
        <h1 class="form-title" align="center">ACCOUNT LOGIN</h1>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Email Address</mat-label>
          <input  matInput class="form-control" formControlName="emailAddress">
          <mat-error *ngIf="submitted && loginFormControls.emailAddress.errors">
            <span *ngIf="loginFormControls.emailAddress.errors.required">Email is required</span>
          </mat-error>
        </mat-form-field>
          <mat-form-field appearance="outline" class="password full-width">
            <mat-label>Password</mat-label>
            <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="submitted && loginFormControls.password.errors">
              <span *ngIf="loginFormControls.password.errors.required">Password is required</span>
            </mat-error>
          </mat-form-field>
        <button mat-button type="submit" class="loginSubmitButton standard-button full-width m-t-5">Login</button>
        <h6 align="center" class="help-text">Please login using your sytem account or contact MTO IT support if you are experiencing issuies logging into your account</h6>
      </div>

    </form>
  </div>

</div>