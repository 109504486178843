import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';

import { GLOBAL_CONSTS } from "src/app/library/global-constants/global-constants";

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {

  client       : any;
  allClients   : Array<any>;
  selectedClients : Array<any>;
  clientForm   : FormGroup;
  loading      : boolean = true;
  levels       : Array<number> =  GLOBAL_CONSTS.Levels;

  parentRequired : any;

  constructor(
    public dialog       : MatDialog,
    private rest        : RestService,
    private route       : ActivatedRoute,
    private router      : Router,
    private formBuilder : FormBuilder,
    private alert       : UialertsService
    ) {  }

  async ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');

    this.loadAllClients();
    this.createUserForm();
    this.createBreadCrumb()

    this.loading = false;
  }

  private createUserForm() : void {

    this.parentRequired = false;

    this.clientForm = this.formBuilder.group({
      code      : new FormControl("", Validators.required),
      title     : new FormControl("", Validators.required),
      level     : new FormControl("", Validators.required),
      parentId  : new FormControl("") //only required on all levels other than 1
    });
  }

  private async loadAllClients() { 
    try {
      this.allClients = await this.rest.get(`${environment.API.url}/Client`);     
    } catch (error) { 
      console.error(error);
    }
  }

  public async createClient() { 

    try {
      this.loading = true;
      if(this.clientForm.valid){
      await this.rest.post(`${environment.API.url}/Client/Create`,{
        "code"      : this.clientForm.get("code").value,
        "title"     : this.clientForm.get("title").value,
        "level"     : this.clientForm.get("level").value,
        "parentId"  : this.clientForm.get("parentId").value
      })

      this.alert.openSnackBar({ duration: 5, message: "Client updated", mode: "success" });
      this.router.navigate(['admin/reports-approvals']);
      }
      this.loading=false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })

    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
    });
  }

  public changeClient() : void {
      
    if(this.clientForm.get('level').value == 1) {
      this.parentRequired = false;
      this.clientForm.get('parentId').setValidators([]);
      this.clientForm.get('parentId').updateValueAndValidity();
    } else {
      this.clientForm.get('parentId').setValidators(Validators.required);
      this.clientForm.get('parentId').updateValueAndValidity();
      this.parentRequired = true;
    }

    this.clientForm.get('parentId').setValue(null);
    this.selectedClients = this.allClients.filter( client => client.level + 1 == this.clientForm.get('level').value );

  }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      },
      {
        classes: ['routable-crumb'],
        title: 'Reports',
        link:['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
    ],
      heading: "Create Client"
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
