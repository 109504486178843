import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { RestService } from 'src/app/library/services/rest.service';

import { environment } from "@environment";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  roles: Array<any>;
  loading : boolean = false;

  constructor(
    private restService : RestService, 
    private formBuilder : FormBuilder, 
    private alert       : UialertsService, 
    private router      : Router) { }

  public async createUser() { 
    try {
      this.userForm.markAllAsTouched();
      this.loading = true;
      if(this.userForm.valid){
      
      const status = await this.restService.post(`${environment.API.url}/User/Create`,{
        "firstName": this.userForm.get("firstName").value,
        "lastName": this.userForm.get("lastName").value,
        "emailAddress": this.userForm.get("emailAddress").value,
        "roleIds": this.userForm.get("roles").value
      })

      this.alert.openSnackBar({ duration: 5, message: "User create, confirmation email sent", mode: "success" });
      this.router.navigate(['manage/users']);
    }
    this.loading = false;
    } catch (error) {
      this.loading = false;      
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.log(error);
    }
  }


  async ngOnInit() {
    this.loadRoles();
    this.createBreadCrumb();
    this.createUserForm();
  }



  private async loadRoles() { 
    try {
      this.roles = await this.restService.get(`${environment.API.url}/Role`);     
    } catch (error) { 
      console.error(error);
      
    }
  }

  userForm : FormGroup;

  private createUserForm() : void {
    this.userForm = this.formBuilder.group({
      emailAddress: new FormControl('', [Validators.required]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      roles: new FormControl('', Validators.required),
    });
  }

  // {
  //   "firstName": "string",
  //   "lastName": "string",
  //   "emailAddress": "string",
  //   "roleIds": [
  //     0
  //   ]
  // }

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['routable-crumb'],
        title: 'Users',
        link:['../']
      },
      {
        classes: ['active-crumb'],
        title: 'Create'
      }
    ],
      heading: `CREATE ACCOUNT`
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
