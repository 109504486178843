import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SideNavService } from 'src/app/library/services/helpers/side-nav.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements AfterViewInit {

  @ViewChild("sidenav") sidenav: MatSidenav;

  sideNavItems: Array<{
    categoryName: string,
    items: Array<any>
  }>;

  constructor(public sideNavService: SideNavService) {
    try {
      this.createSideNavItems()
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit() {
    this.sideNavService.setSidenav(this.sidenav);
  }

  private createSideNavItems(): void {
    this.sideNavItems = [
      {
        categoryName: "ADMIN",
        items: [
          {
            name: "Bookings",
            icon: "requests",
            link: "admin/bookings"
          },
          {
            name: "Courses",
            icon: "courses",
            link: "admin/courses"
          },
          {
            name: "Sessions",
            icon: "bookings",
            link: "admin/sessions"
          },
          {
            name: "Maintain",
            icon: "checklist",
            link: "admin/reports-approvals"
          }, {
            name: "Certificates",
            icon: "text_snippet",
            iconMaterial: true,
            link: "admin/certificates"
          },
        ]
      },
      {
        categoryName: "People",
        items: [
          {
            name: "Learners",
            icon: "students",
            link: "people/students"
          },
          {
            name: "Facilitators",
            icon: "facilitators",
            link: "people/facilitators"
          }
        ]
      },
      {
        categoryName: "Manage",
        items: [
          {
            name: "System users",
            icon: "group",
            link: "manage/users"
          },
          {
            name: "Roles",
            icon: "donut_large",
            iconMaterial: true,
            link: "manage/roles"
          },
          {
            name: "Approvals",
            icon: "history_toggle_off",
            iconMaterial: true,
            link: "manage/approvals"
          }
        ]
      }
    ]
  }
}
