import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seoFriendly'
})
export class SeoFriendlyStringPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.replace(/\W+/g, '-');
  }

}
