import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { GlobalStateService } from "../services/global-state.service";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private stateService: GlobalStateService,
        private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(async (resolve) => {
            if (await this.authService.getAuthenticationState()) {
                resolve(true)
            } else {
                this.router.navigate(['account/login'], { queryParams: { returnUrl: state.url } })
                .then(() => resolve(false));
            }
        });
    }
}