<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="spinner-loader" *ngIf="loading">
        <mat-spinner class="center"></mat-spinner>
    </div>

    <form [hidden]="loading" [formGroup]="mainForm" class="row full-width">
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput class="form-control" formControlName="firstName">
                <mat-error *ngIf="!loading && mainForm.controls.firstName.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.firstName.errors.required">First name is required</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput class="form-control" formControlName="lastName">
                <mat-error *ngIf="!loading && mainForm.controls.lastName.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.lastName.errors.required">Last name is required</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Employee Code</mat-label>
                <input matInput class="form-control" formControlName="employeeCode">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Code</mat-label>
                <input matInput class="form-control" formControlName="code">
            </mat-form-field>
        </div>
        <div class="row-inner">
            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput class="form-control" formControlName="title">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Availabiliy</mat-label>
                <mat-select formControlName="external" class="form-control">
                    <mat-option [value]="true"><small>External</small></mat-option>
                    <mat-option [value]="false"><small>Internal</small></mat-option>
                </mat-select>
                <mat-error *ngIf="!loading && mainForm.controls.external.errors">
                    <span class="fw-bold small my-2" *ngIf="mainForm.controls.external.errors.required">Availabiliy is Required</span>
                </mat-error>
            </mat-form-field>
        </div>
        <button class="basic-button" (click)="createItem();" mat-button><small>Create Facilitator</small></button>
    </form>

</div>