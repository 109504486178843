import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';


import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';

import { Library } from "src/app/library/enums/global-enum";
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { PagerService } from 'src/app/library/services/paginator.service';
import { FormControl } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { IClientFilter } from 'src/app/library/interfaces/filters/client-filter';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['code', 'title', 'level'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = true;
  dataSource;
  changes: Array<any>;
  updatingChange: boolean = false;
  levelId = null;
  client;
  loadingPageResults: boolean;
  searchText = new FormControl();

  public readonly pageSizeOptions: number[] = [10];
  public dataResult: PagedResult<any[]>;

  resultsLength;

  constructor(
    private router: Router,
    private restService: RestService,
    private alert: UialertsService,
    private route: ActivatedRoute,
    private pagerService: PagerService
  ) {

    this.levelId = this.route.snapshot.paramMap.get('level');

  }

  async ngOnInit() {
    this.createBreadCrumb();
    await this.loadTable();
    this.listeners();
  }


  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      },
      {
        classes: ['routable-crumb'],
        title: 'Reports',
        link: ['../../']
      },
      {
        classes: ['base-crumb'],
        title: 'Clients'
      }
      ],
      heading: `Clients - Level ` + this.levelId
    }
  }


  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openItem(client: any) {
    this.router.navigate([`admin/reports-approvals/clients/edit/${client.id}`])
  }




  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }



  ngAfterViewInit() {
    this.loadPaginator();
  }


  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/Client`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0],
      level: this.levelId
    } as IClientFilter)) as PagedResult<any[]>;
    if (response.data) {
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }


}
