import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/dialog.component';

import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';
import { UnitStandard } from 'src/app/library/interfaces/course/unit-standard';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-update-course',
  templateUrl: './update-course.component.html',
  styleUrls: ['./update-course.component.scss']
})
export class UpdateCourseComponent implements OnInit {

  @ViewChild('unitStandardInput') unitStandardInput: ElementRef<HTMLInputElement>;

  course: any;
  roles: Array<any>;
  mainForm: FormGroup;
  loading: boolean = true;

  races: any = GLOBAL_CONSTS.races;

  courseType: any = GLOBAL_CONSTS.CourseType;
  courseReferenceNo: any = GLOBAL_CONSTS.CourseReferenceNo;
  coursePassSymbol: any = GLOBAL_CONSTS.CoursePassSymbol;
  categoryType: any = GLOBAL_CONSTS.CategoryType;
  categoryModeOfTraining: any = GLOBAL_CONSTS.CategoryModeOfTraining;

  private _unitStandards = new BehaviorSubject<UnitStandard[]>(null);
  public unitStandardsLoading = false;

  constructor(
    public dialog: MatDialog,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: UialertsService,
  ) { }

  async ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');
    await this.loadEntry(id);
    this.createUserForm();
    this.createBreadCrumb();
    await this.loadUnitStandards();
    this.listeners();
    this.loading = false;
  }

  private createUserForm(): void {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(this.course.name, [Validators.required]),
      description: new FormControl(this.course.description),

      type: new FormControl(this.course.courseDetail.type),
      code: new FormControl(this.course.courseDetail.code),
      referenceNo: new FormControl(this.course.courseDetail.referenceNo),
      passMark: new FormControl(this.course.courseDetail.passMark),
      passSymbol: new FormControl(this.course.courseDetail.passSymbol),
      validFor: new FormControl(this.course.courseDetail.validFor),
      restrictions: new FormControl(this.course.courseDetail.reestrictions),
      capacity: new FormControl(this.course.courseDetail.capacity),

      modeOfTraining: new FormControl(this.course.courseCategoryDetail.modeOfTraining),
      category: new FormControl(this.course.courseCategoryDetail.category),
      tetaAccredited: new FormControl(this.course.courseDetail.tetaAccredited),
      unitStandards: new FormControl(this.course.courseUnitStandards.map(e => e.unitStandard)),
      unitStandardInput: new FormControl(),
    });
  }

  public async updateItem() {

    try {
      this.loading = true;
      if(this.mainForm.valid){
      await this.rest.post(`${environment.API.url}/Course/${this.course.id}`, {
        "id": this.course.id,
        "name": this.mainForm.get('name').value,
        "description": this.mainForm.get('name').value,
        "courseDetailId": this.course.courseDetailId,
        "courseCategoryDetailId": this.course.courseCategoryDetailId,
        "unitStandardIds": this.mainForm.get('unitStandards').value.map(e => e.id),
        "courseDetail": {
          "id": this.course.courseCategoryDetailId,
          "type": this.mainForm.get('type').value,
          "code": this.mainForm.get('code').value,
          "referenceNo": this.mainForm.get('referenceNo').value,
          "passMark": this.mainForm.get('passMark').value,
          "passSymbol": this.mainForm.get('passSymbol').value,
          "validFor": this.mainForm.get('validFor').value,
          "tetaAccredited": this.mainForm.get('tetaAccredited').value,
          "restrictions": this.mainForm.get('restrictions').value,
          "capacity": this.mainForm.get('capacity').value,
        },
        "courseCategoryDetail": {
          "id": this.course.courseCategoryDetailId,
          "modeOfTraining": this.mainForm.get('modeOfTraining').value,
          "category": this.mainForm.get('category').value
        }
      })

      this.alert.openSnackBar({ duration: 5, message: "Course updated", mode: "success" });
      this.router.navigate(['admin/courses'])
      }
      this.loading=false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
    }
  }

  private async loadEntry(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      this.course = await this.rest.get(`${environment.API.url}/Course/GetById?id=${id}`);
      console.log(this.course);
      resolve(this.course);
    })
  }

  public deleteUser(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

    });
  }

  public unitStandardBlur() {
    this.mainForm.controls.unitStandardInput.setValue(null, { emitEvent: false });
    this.unitStandardInput.nativeElement.value = "";
  }


  public async addUnitStandard(event) {
    let currentSet = this.mainForm.get('unitStandards').value;
   
    if(currentSet.findIndex(x => x.id == event.option.value.id) > -1){
      this.alert.openSnackBar({ duration: 5, message: "Unit Standard Already Added", mode: "danger" });
      return;
    }
    this.mainForm.get('unitStandards').value.push(event.option.value);
    this.unitStandardInput.nativeElement.value = '';
    await this.rest.post(`${environment.API.url}/Course/${this.course.id}/UnitStandard/${event.option.value.id}`, null)
      .then(d => {
        this.alert.openSnackBar({ duration: 5, message: "Successfully Added Unit Standard", mode: "success" });
      
      }).catch(() => {
        this.alert.openSnackBar({ duration: 5, message: "Error", mode: "danger" });
        let index = this.mainForm.get('unitStandards').value.indexOf(event.option.value);
        this.mainForm.get('unitStandards').value.splice(index, 1);
        this.unitStandardInput.nativeElement.value = '';
      });


  }
  public async removeUnitStandard(event) {
    let index = this.mainForm.get('unitStandards').value.indexOf(event);
    this.mainForm.get('unitStandards').value.splice(index, 1);
    this.unitStandardInput.nativeElement.value = '';
    await this.rest.delete(`${environment.API.url}/Course/${this.course.id}/UnitStandard/${event.id}`)
    .then(() => { 
      this.alert.openSnackBar({ duration: 5, message: "Successfully Removed Unit Standard", mode: "success" });
    }).catch(() => {
      this.alert.openSnackBar({ duration: 5, message: "Error", mode: "danger" });
      this.mainForm.get('unitStandards').value.push(event);
      this.unitStandardInput.nativeElement.value = '';
    });
  }

  private async listeners() {
    this.mainForm.get('unitStandardInput').valueChanges.pipe(debounceTime(100)).subscribe(async data => {
      this._unitStandards.next(null);
      await this.loadUnitStandards(data);
    });

    this.mainForm.get('tetaAccredited').valueChanges.subscribe(async bool => {
      if (!bool) {
        this.mainForm.get('restrictions').setValue("");
        this.mainForm.get('capacity').setValue("");
      }
    });
  }

  private async loadUnitStandards(keyword?: string) {
    this.unitStandardsLoading = true;
    let resp = await this.rest.get(`${environment.API.url}/UnitStandards`, { pageNumber: 1, pageSize: 5, searchTerm: keyword });
    let currentSet = this.mainForm.get('unitStandards').value;
    //let filteredSet = resp.filter(e => currentSet.map(e => e.id).indexOf(e.id) < 0);
    this._unitStandards.next(resp.data);
    this.unitStandardsLoading = false;
  }

  get unitStandards$() {
    return this._unitStandards.asObservable();
  }
  get fc() {
    return this.mainForm.controls;
  }


  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'People'
      }, {
        classes: ['routable-crumb'],
        title: 'Courses',
        link:['../../']
      },
      {
        classes: ['active-crumb'],
        title: 'Edit'
      }
      ],
      heading: this.mainForm.get('name').value
    }
  }

  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }

}
