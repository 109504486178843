import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';


import { environment } from "@environment";
import { RestService } from 'src/app/library/services/rest.service';

import { Library } from "src/app/library/enums/global-enum";
import { UialertsService } from 'src/app/library/services/uialerts.service';
import { FormControl } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';
import { PagerService } from 'src/app/library/services/paginator.service';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss']
})
export class TrackerComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['operation', 'date', 'status', 'resource', '-'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly pageSizeOptions: number[] = [10];
  public dataResult: PagedResult<any[]>;

  loading: boolean = true;
  dataSource;
  changes: Array<any>;
  updatingChange: boolean = false;

  pageNumber: number = 1;
  resultsLength: number
  loadingPageResults: boolean = false;
  searchText = new FormControl();

  constructor(
    private router: Router,
    private restService: RestService,
    private alert: UialertsService,
    private pagerService: PagerService
  ) {


  }

  async ngOnInit() {
    this.createBreadCrumb();
    await this.loadTable();
    this.listeners();
  }


  public async updateEntries() {

    this.updatingChange = true;
    await this.loadTable();
    this.updatingChange = false;
  }

 


  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Manage'
      }, {
        classes: ['base-crumb'],
        title: 'Approvals'
      }
      ],
      heading: `APPROVALS`
    }
  }

  public async approveChange(id: any) {

    try {
      this.updatingChange = true;
      await this.restService.get(`${environment.API.url}/SystemChangeTracker/Approve?id=${id}`);
      this.updatingChange = false;
      this.loadTable();
    } catch (error) {
      this.updatingChange = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.error(error);
    }

  }

  public async declineChange(id: any) {

    try {
      this.updatingChange = true;
      await this.restService.get(`${environment.API.url}/SystemChangeTracker/Decline?id=${id}`);
      this.updatingChange = false;
      this.loadTable();
    } catch (error) {
      this.updatingChange = false;
      this.alert.openSnackBar({ duration: 5, message: error.error.Message, mode: "danger" })
      console.error(error);

    }

  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openUser(user: any) {
    this.router.navigate([`manage/users/edit/${user.uid}`])
  }

  



  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }



  ngAfterViewInit() {
    this.loadPaginator();
  }


  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/SystemChangeTracker`, {
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0]
    } as SearchFilter)) as PagedResult<any[]>;
    if (response.data) {
      this.dataResult = response;
      response.data = response.data.map(c => {
        c.entityType = Library.ChangeTrackerEntityTypeDecode(c.entityType);
        c.actionType = Library.ChangeTrackerActionTypeDecode(c.actionType);
        c['status'] = Library.StatusDecode(c.status);
        return c;
      });
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }


}
