import { Pipe, PipeTransform } from '@angular/core';
import { Library } from 'src/app/library/enums/global-enum';

@Pipe({
  name: 'courseCategoryFriendly'
})
export class CourseCategoryFriendlyPipe implements PipeTransform {

  transform(value: Library.CategoryType, args?: any): string {
    console.log(value);
    switch (value) {
      case Library.CategoryType.Agri:
        return 'Agriculture';
      case Library.CategoryType.Driver:
        return 'Driver';
      case Library.CategoryType.Environmental:
        return 'Environmental';
      case Library.CategoryType.Fire:
        return 'Fire';
      case Library.CategoryType.Forestry:
        return 'Forestry';
      case Library.CategoryType.IT:
        return 'IT';
      case Library.CategoryType.Safety:
        return 'Health & Safety';
      case Library.CategoryType.Sawmill:
        return 'Sawmill';
      case Library.CategoryType.SoftSkills:
        return 'Soft Skills';
      case Library.CategoryType.Unknown:
        return 'Unknown';
    }
  }

}
