<div class="table-contianer">
  <table mat-table [dataSource]="dataSource" matSort class="full-width">
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last name </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>
    <ng-container matColumnDef="identificationNumber">
      <th mat-header-cell *matHeaderCellDef> Identification no. </th>
      <td mat-cell *matCellDef="let element"> {{element.identificationNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="outcomeMark">
      <th mat-header-cell *matHeaderCellDef> Outcome mark </th>
      <td mat-cell *matCellDef="let element">
        <input matInput (input)="changeMark($event.target.value,element)" [value]='element.outcomeMark' placeholder="0" type="number">
      </td>
    </ng-container>
    <ng-container matColumnDef="outcomeSymbol">
      <th mat-header-cell *matHeaderCellDef> Outcome symbol </th>
      <td mat-cell *matCellDef="let element">
        <mat-select [value]="element.outcomeSymbol"  (selectionChange)="changeSymbol($event,element)">
          <mat-option [value]="symbol.id" *ngFor="let symbol of coursePassSymbol">
            <small>{{symbol.name}}</small>
          </mat-option>
        </mat-select>
      </td>
    </ng-container>
    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"  (click)="removeLearnerFromBooking(element)" class="delete-user" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </td>
    </ng-container>


    <ng-container *ngIf="!(loading$ | async) as loading">
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            No Records Found Matching Your Filters
          </td>
        </tr>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  </table>

  <ng-container *ngIf="(loading$ | async) as loading">
    <div class='spinner-loader table-loader' *ngIf="loading">
      <mat-spinner class='center'></mat-spinner>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <div class='spinner-loader table-loader'>
    <mat-spinner class='center'></mat-spinner>
  </div>
  <ng-template>