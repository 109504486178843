import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe implements PipeTransform {

  transform(date: any, args?: any): any {
    let d = new Date(date)
    return moment(d).format('YYYY-MM')

  }
}
