import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/library/services/rest.service';
import { environment } from "@environment";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, interval } from 'rxjs';
import { BreadCrumb } from 'src/app/library/interfaces/components/bread-crumb/bread-crumb';
import { GLOBAL_CONSTS } from 'src/app/library/global-constants/global-constants';
import { Router } from '@angular/router';
import { PagerService } from 'src/app/library/services/paginator.service';
import { debounce, debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { analytics } from 'firebase';
import { PagedResult } from 'src/app/library/interfaces/filters/paged-result';
import { SearchFilter } from 'src/app/library/interfaces/mto/search-filter';


@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //LOADERS
  componentLoading: boolean = true;

  //DATA TABLE 

  dataSource: any;
  displayedColumns: string[] = ['createdOn', 'bookingName', 'resources'];

  pageNumber: number = 1;
  resultsLength: number
  loadingPageResults: boolean = false;
  searchText = new FormControl();

  public readonly pageSizeOptions: number[] = [10];
  public dataResult: PagedResult<any[]>;

  @ViewChild(MatPaginator) dataTablePaginator: MatPaginator;
  @ViewChild(MatSort) dataTableSort: MatSort;

  constructor(
    private restService: RestService,
    private router: Router,
    private pagerService: PagerService
  ) { }

  async ngOnInit(): Promise<void> {

    this.createBreadCrumb();
    await this.loadTable();
    this.listeners();


  }
  //DATATABLE 
  private mapSrouce(source: Array<any>) {
    return source.map((element) => {
      return {
        bookingName: element.bookingName,
        resources: this.filternames(element.bookingCourses),
        createdOn: element.createdOn,
        email: element.requestorEmail,
        location: GLOBAL_CONSTS.Locations.filter(location => element.location === location.id)[0] ? GLOBAL_CONSTS.Locations.filter(location => element.location === location.id)[0].name : '',
        contactNumber: element.contactNumber,
        id: element.id
      }
    })
  }

  private filternames(array: Array<any>) {
    var names: Array<any> = [];
    array.forEach(element => {
      if (!names.find(x => x.name == element.name)) {
        names.push(element);
      }
    });
    return names;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openBooking(booking: any): void {
    this.router.navigate([`admin/bookings/edit/${booking.id}`])
  }

  //BREAD CRUMB

  private async createBreadCrumb() {
    this.breadCrumb = {
      items: [{
        classes: ['base-crumb'],
        title: 'Admin'
      }, {
        classes: ['base-crumb'],
        title: 'Bookings'
      }
      ],
      heading: `BOOKINGS`
    }
  }

  private _breadCrumb = new BehaviorSubject<BreadCrumb>(null);
  set breadCrumb(data: BreadCrumb) {
    this._breadCrumb.next(data);
  }
  get breadCrumb$() {
    return this._breadCrumb.asObservable();
  }



  ngAfterViewInit() {
    this.loadPaginator();
  }


  private async listeners() {
    this.searchText.valueChanges.pipe(debounceTime(200)).subscribe(async data => {
      await this.loadTable();
    });

    this.paginator.page.subscribe(async data => {
      await this.loadTable();
    });

    this.sort.sortChange.subscribe(async i => {
      await this.loadTable();
    });
  }

  private async loadPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private async loadTable() {
    this.loadingPageResults = true;
    this.dataSource = new MatTableDataSource();
    let response = (await this.restService.get(`${environment.API.url}/Booking`, {
      searchTerm: this.searchText.value,
      pageNumber: (this.paginator?.pageIndex + 1) ?? 1,
      pageSize: (this.paginator?.pageSize) ?? this.pageSizeOptions[0]
    } as SearchFilter)) as PagedResult<any[]>;
    if (response.data) {
      response.data = response.data.map(x => {
        x.resources = this.filternames(x.bookingCourses)
        return x;
      });
      this.dataResult = response;
      this.dataSource = new MatTableDataSource(response.data);
      this.loadingPageResults = false;
    }
  }


  private async paginatorChanges() {
    this.dataTablePaginator.page
      .subscribe(async (value) => {
        this.loadingPageResults = true;

        let results = await this.pagerService.getResults(`${environment.API.url}/Booking`, value.pageIndex + 1);
        this.loadingPageResults = false;
        this.resultsLength = 2000;
        this.dataSource = this.mapSrouce(results);
        this.componentLoading = false;
      })
  }
}
