<div class="container">
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
        <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>



    <div>
        <div class="row full-width">
            <div class="row-inner">
                <mat-form-field appearance="outline">
                    <mat-label>Search bookings</mat-label>
                    <input [formControl]='searchText' matInput class="form-control">
                </mat-form-field>
                <button routerLink="/admin/bookings/create" mat-button
                    class="create-button standard-button full-width m-t-5">
                    <mat-icon>add</mat-icon>
                    <span class="title"><strong><small>Create booking</small></strong></span>
                </button>
            </div>
        </div>

        <div class="table-contianer">
            <table mat-table [dataSource]="dataSource" matSort class="full-width">
                <ng-container matColumnDef="createdOn">
                    <th mat-header-cell *matHeaderCellDef> <strong>Date</strong> </th>
                    <td mat-cell *matCellDef="let element"> <small>{{element.createdOn | date: 'dd/MM/yyyy' }}</small>
                    </td>
                </ng-container>
                <ng-container matColumnDef="bookingName">
                    <th mat-header-cell *matHeaderCellDef>Booking name</th>
                    <td mat-cell *matCellDef="let element"><small>{{element.bookingName}}</small></td>
                </ng-container>
                <ng-container matColumnDef="resources">
                    <th mat-header-cell *matHeaderCellDef> Course </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let resource of element.resources"><small>{{resource.name}}</small></span>
                      
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                </ng-container>
                <ng-container matColumnDef="contactNumber">
                    <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let rowElement; columns: displayedColumns" (click)="openBooking(rowElement)">
                </tr>

                <tr class="mat-row" *matNoDataRow [ngClass]="{hidden: loadingPageResults != false}">
                    <td class="mat-cell text-center" colspan="999">No Results Found
                    </td>
                </tr>

            </table>
            <ng-container *ngIf="loadingPageResults">
                <div class='table-loader'>
                    <mat-spinner class='center'></mat-spinner>
                </div>
            </ng-container>
            <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="dataResult?.collectionSize"></mat-paginator>
        </div>

    </div>

</div>