<div class="container">
  <div>
    <ng-container *ngIf="breadCrumb$ | async as breadCrumb">
      <bread-crumb [breadCrumb]="breadCrumb"></bread-crumb>
    </ng-container>

    <div class="row full-width">
      <div class="row-inner">
        <mat-form-field appearance="outline">
          <mat-label>Search sessions</mat-label>
          <input matInput [formControl]="searchText" matInput class="form-control">
        </mat-form-field>
        <button routerLink="/admin/sessions/create" mat-button class="create-button standard-button full-width m-t-5">
          <mat-icon>add</mat-icon>
          <span class="title"><strong><small>Create session</small></strong></span>
        </button>
      </div>
    </div>

    <div class="table-contianer">
      <h6 style="opacity: 0.6;">Please select an approval to view more details. </h6>

      <table mat-table [dataSource]="dataSource" matSort class="full-width">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Course Name</th>
          <td mat-cell *matCellDef="let element"><small>{{element.name}} - {{element.costRecoveryNumber ?
              element.costRecoveryNumber : 'KV Unknown'}}</small></td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'dd-MMM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'dd-MMM-yyyy'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openElement(row)"></tr>

        <tr class="mat-row" *matNoDataRow [ngClass]="{hidden: loadingPageResults != false}">
          <td class="mat-cell text-center" colspan="999">No Results Found
          </td>
        </tr>
        
      </table>
      <ng-container *ngIf="loadingPageResults">
        <div class='table-loader'>
          <mat-spinner class='center'></mat-spinner>
        </div>
      </ng-container>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="dataResult?.collectionSize"></mat-paginator>
    </div>
  </div>


  <div>
    <div class="row full-width">
      <div class="row-inner">
        <button (click)="MonthSwitch(0)" mat-button
          class="previousButton create-button standard-button full-width m-t-5">
          <span class="title"><strong><small>Previous Month</small></strong></span>
        </button>
        <button (click)="MonthSwitch(1)" mat-button class="nextButton create-button standard-button full-width m-t-5">
          <span class="title"><strong><small>Next Month</small></strong></span>
        </button>
      </div>
    </div>
    <div>
      <h1 class="Calendarheader">{{this.dateHeader}}</h1>
    </div>
    <ng-container *ngIf="!calendarLoading;else loader">
      <mwl-calendar-month-view [viewDate]="viewDate.toDate()" [events]="calendarData"
        [activeDayIsOpen]="activeDayIsOpen" (eventClicked)="eventClicked($event)" (dayClicked)="dayClicked($event.day)">
      </mwl-calendar-month-view>
    </ng-container>
  </div>



</div>

<ng-template #loader>
  <div class='spinner-loader table-loader'>
    <mat-spinner class='center'></mat-spinner>
  </div>
</ng-template>